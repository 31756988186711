.thankyou{
  max-width:100%;
  height:300px;
}

@media (max-width:480px){
  .thankyou{
    max-width:100%;
    height:250px;
  }
  
}