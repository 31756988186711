/*
Theme Name: Organtic
Author:  ThemesGround
Description:  Organtic HTML Template
Version: 1.4
License: GNU General Public License v2 or later
Tags: two-columns, left-sidebar, fixed-layout, responsive-layout, custom-background, custom-colors, custom-header, custom-menu
Text Domain:  Organtic
*/

@media only screen and (min-width: 320px) and (max-width: 479px) {
.fl-links {
	display: none
}
.mini-cart {
	border: none
}
.header-container .fl-nav-menu {
	display: none;
	border-radius: 3px;
	clear: both;
}
#thmg-slider-slideshow {
	margin: 0px
}
#thmg-slider-slideshow:before {
	left: -500px;
}
#top {
	margin-top: 0px;
	margin-bottom: 20px;
	padding-top: 30px;
    padding-bottom: 0px;
}

#top > div a img {margin-bottom:20px}

#top ul li {
	width: 100%
}
.products-grid .item .item-inner .item-info .info-inner .item-title {
	font-size: 14px
}
.tp-caption.ExtraLargeTitle span {
	border: none;
	font-size: 18px!important;
	line-height:17px!important;
}
.tp-caption.LargeTitle {
	font-size: 34px!important;
	margin-top: 5px!important;
	line-height:40px!important
	
}
.tp-caption.LargeTitle span {
	font-size: 34px!important;
	line-height:40px!important
}
.tp-caption.Title {
	display: none
}
.buy-btn {
	font-size: 12px!important;
	padding: 4px 15px!important;
	line-height: 49px!important;
}
.top-cate {
	margin-top: 0px
}
.top-cate .new_title h2 {
	min-width: auto
}
.featured-pro {
	padding-bottom:10px;
}
.top-cate .slider-items-products .owl-buttons .owl-prev {
	margin-top: 60px;
	left: 16px
}
.top-cate .slider-items-products .owl-buttons .owl-prev a {
  display:none
}
.top-cate .slider-items-products .owl-buttons .owl-next {
	margin-top: 60px;
	right: -1px
}
.top-cate .slider-items-products .owl-buttons .owl-next a {
  display:none
}
.best-pro.slider-items-products .owl-buttons .owl-prev a {
	margin: 0px 0 0 -63px;
}
.best-pro.slider-items-products .owl-buttons .owl-next a {
	margin: 0px 0 0 0px;
}

.best-pro.slider-items-products .owl-buttons .owl-next {
    position: absolute;
    right:50px;
	margin-top:-110px;
	top:0

}

.best-pro.slider-items-products .owl-buttons .owl-prev {
    position: absolute;
    left:60px;
	margin-top:-110px;
	top:0
}

.best-pro .new_title h2 {
	font-size: 36px
}
.hot-section h2 {
	font-size: 25px;
	font-family: 'Oswald', sans-serif;
}
.hot-section h3 {
	font-size: 40px
}
.hot-section h4 {
	padding: 0px 30px 30px 30px;
	line-height: 21px;
	font-size: 18px;
}
.hot-deal {
	padding: 75px 20px 40px 20px;
}
.hot-deal .item {
	margin-bottom: 20px
}
.box-timer {
	width: 95%;
	margin-left: 15px;
	left: 0px
}
.timer-grid .box-time-date {
	margin-right: 8px;
	border-radius: 25px;
}
.timer-grid .box-time-date span {
	font-size: 22px
}
.mid-section {
	background: #f5f5f5 url(../static/img/mid-img.jpg) no-repeat 0 0px;;
	margin-top:0;
	padding-top:40px
}

.cart-collaterals {padding:0px 15px}

.spl-pro:after { border:none}

.mid-section .container {
	padding: 0px 15px
}
.mid-section h3 {
	font-size: 30px;
	margin: 0px 30px;
}
.mid-section h2 {
	font-size: 36px;
}
.mid-section .block1 {
	padding: 20px;
	margin-right: 0px;
	text-align: center;
	margin-top: 30px
}
.mid-section .block2 {
	padding: 20px;
	margin-right: 0px;
	text-align: center;
	margin-top: 20px
}
.mid-section .block3 {
	padding: 20px;
	margin-left: 0px;
	text-align: center;
	margin-top: 30px
}
.mid-section .block4 {
	padding: 20px;
	margin-left: 0px;
	text-align: center;
	margin-top: 20px
}

.blog_entry-title {padding:0px 15px}
.blog_entry .btn {margin-left:15px}
.blog-wrapper .entry-content {padding:0px 15px}
.comment-content {padding:0px 15px}

footer .newsletter-row {
	padding: 40px 0px;
}
.newsletter-wrap h4 {
	font-size: 32px
}
footer h4 {
	padding-top: 20px
}
.social {
	float: none;
	text-align: center;
}
.social ul {
	float: none
}
footer .coppyright {
	float: none;
	text-align: center;
	margin: 20px 0px
}
.payment-accept {
	float: none !important;
	width: 100%;
	text-align: center
}
#top .container {
	padding: 0 15px;
}
.best-pro .new_title h2 {
	min-width: auto
}
.cate-banner-img {
	display: none
}
.our-features-box .feature-box {
	margin-right: 0px;
	border-right: none;
	border-bottom: 1px #eaeaea solid;
}
.our-features-box {
	padding: 0px 0px;
	margin: 50px -15px
}
.latest-blog h2 {
	margin-bottom: 0px!important
}
.latest-blog .blog_inner {
	margin: 0px;
	margin-bottom:30px
}
.latest-blog h3 {
	margin-top: 0px;
	letter-spacing: 1px;
	line-height: 4rem
}
.testimonials-section {
	min-height: auto;
	padding: 0px 0px 30px 0px
}
.testimonials {
	font-size: 18px
}
.testimonials-section .bx-viewport {
	height: auto!important
}
.logo-brand img {
	max-width: 100%
}
.logo-brand .slider-items-products .item {
	margin: 0px 15px
}
.logo-brand .owl-pagination {
	text-align: left;
}
.best-pro {
	padding-bottom: 15px;
	margin-bottom: 30px;
	    padding: 0px 15px;
}
#best-seller .owl-item .item {
	margin: 15px 8px
}
#right_toolbar {
	display: none !important;
}
.brand-logo .new_title.center {
	height: 40px;
}
.new_title {
	margin-bottom: 2px;
}
.new_title.center {
	height: auto;
	border-bottom: none;
}
.new_title h2 {
	margin-bottom: 70px;
}
.my-wishlist .buttons-set2 .btn-share, .my-wishlist .buttons-set2 .btn-add, .my-wishlist .buttons-set2 .btn-update {
	float: none;
	margin-left: 0;
	margin-bottom: 8px;
}
.page-title_multi h2 {
	font-size: 18px;
}
.multiple_addresses .title-buttons.pull-right {
	float: left !important;
}
#multiship-addresses-table {
	margin-top: auto;
}

.product-essential {padding:0px 15px}

.product-view .flexslider-thumb {
	padding: 0 32px;
}
.related-pro .new_title.center {
	height: inherit;
}
.upsell-pro .new_title.center {
	height: inherit;
}
.product-tabs {
	height: auto;
}
.product-view .email-friend a span {
	display: none;
}
.product-view .add-to-links span {
	display: none;
}
.email-addto-box {
	margin-top: 10px;
	margin-left: 0px;
	width: 100%;
}
.product-shop .social {
	margin-top: 7px;
}
.product-collateral {
	margin-top: 4px;
	margin-bottom: 25px;
	padding:0px 15px
}
.product-view .email-friend {
	margin: auto;
}
.product-view .social ul li a {
	width: 35px;
	height: 35px;
	line-height: 32px;
}

.related-pro {padding:0px 15px}

.product-shop .social ul li {
	margin: auto;
}
.multiple_addresses .title-buttons {
	text-align: left;
}
.footer-inner .container {
	padding-left: 15px;
	padding-right: 15px
}
.footer-column {
	padding-top: 20px;
	margin-bottom: 20px
}
.footer-bottom .company-links li {
	margin-left: 0;
	float: none;
	margin: 0 10px 5px 0;
}
.footer-bottom .company-links ul {
	text-align: center;
}
.social ul li a {
	margin-bottom: 5px
}
.payment-accept img {
	margin-right: 6px;
}
footer address {
	width: 82%;
}
.header-banner.mobile-show .our-features-box {
	display: block !important;
}
.offer-slider h1 {
	font-size: 34px;
	padding: 30px 0px;
	padding-bottom: 15px;
}
.offer-slider p {
	padding: 0 2% 1%;
	font-size: 14px;
}
.bx-wrapper {
	margin: auto;
}
.bx-viewport {
	height: 340px !important;
	width: 95% !important;
}
.bxslider li {
	margin-right: 30px !important;
}
.bx-wrapper .bx-pager {
	padding-top: 8px;
}
.owl-pagination {
	top: -20px;
	right: auto;
	width: 100%;
}
.owl-theme .owl-controls .owl-page span {
	width: 14px;
	height: 14px;
}
#toTop {
	width: 30px;
	height: 30px;
	line-height: 38px;
}
.cms-index-index .our-features-box {
	display: none;
}
.header-banner .assetBlock {
	width: 100%;
	padding: 8px 0px;
	font-size: 10px;
}
.fl-mini-cart-content .actions {
	padding: 0 8px 15px 8px;
}
.mini-products-list .product-image {
	margin-left: 8px;
}
.mini-cart .actions .btn-checkout {
	padding: 8px 20px;
}
.mini-cart .view-cart {
	padding: 10px 20px;
}
.col-xs-12.right_menu {
	padding-left: 4px;
}
.product-img-box. col-xs-12 {
width:300px;
}
.product-next-prev {
	display: none;
}
.product-view .previews-list li {
	margin-right: 13px;
}
.product-view .product-shop .add-to-box .pull-right {
	float: left !important;
}
#cloud-zoom-big {
	display: none !important;
}
.category-description .slider-items-products .owl-buttons a {
	width: 25px;
	height: 25px;
}
.products-list .product-image {
	width: 100%;
}
.language-currency {
	margin-left: 0;
}
.fl-currency {
	margin-right: 2px;
}
.fl-language {
	padding-left: 8px;
}
.logo {
	text-align: center;
	padding-bottom: 0px;
	margin-top: 0px;
	/* width: 100%; */
    /* border-bottom: 1px solid #e5e5e5; */
	height: 70px;
	line-height: normal;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 0px;
	padding-right: 0px;
	float: none;
	margin-right: 0;
	width: 100%;
}
.logo img {/* width: 100%; */
}
.header-container .right_menu {
	background: none repeat scroll 0 0 #f5f5f5;
	margin-top: 0;
	padding-bottom: 5px;
	position: absolute;
	text-align: center;
	right: -2px;
}
.toplinks .links div {
	padding-right: 10px;
}
.toplinks {
	float: none;
	text-align: center;
}
.toplinks div.links div a:hover {
	color: #f65f73;
}
.mm-toggle-wrap {
	display: block;
	width: 100%;
}
.toplinks div.links div a {
	padding: 3px 5px;
}
.toplinks div.links div a {
	margin-left: 0px;
}
.toplinks>div>div.myaccount>a:before {
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.myaccount>a:before {
	content: '\f007';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.wishlist>a:before {
	content: '\f004';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.check>a:before {
	content: '\f00c';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.demo>a:before {
	content: '\f09e';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks .links div .fl-links ul .clicker:before {
	content: '\f15b';
	font-family: FontAwesome;
	font-size: 13px;
	padding-left: 4px;
	padding-top: 2px;
	float: left;
	margin-right: 0px;
	margin-top: -2px;
}
.toplinks>div>div.login>a:before {
	content: '\f13e';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.logout>a:before {
	content: '\f09c';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks {
	margin-left: 0px;
	position: absolute;
	right: -15px;
	top: 35px;
}
.caret {
	margin-left: -3px;
}
.search-box {
	width: 190px;
	margin-top: -34px;
}
#search {
	padding: 3px 6px;
	width: 170px;
	height: 38px;
}
.search-btn-bg {
	position: absolute;
	padding: 6px 5px;
	height: 26px;
	right: 10px
}
.search-icon, .search-bar-submit {
	height: 38px;
	width: 50px;
}
.search-bar.search-bar-open, .no-js .search-bar {
	height: 38px;
	width: 220px;
}
.search-bar {
	height: 38px;
	min-width: 50px;
}
.search-icon:before {
	font-size: 16px;
}
.fl-links .clicker {
	padding: 0px 10px;
	height: 40px;
	line-height: 40px;
}
.fl-links .clicker:before {
	font-size: 20px;
}
.fl-links > .no-js:hover > .clicker {
	padding: 0px 10px;
	line-height: 38px;
	height: 38px;
}
.mini-cart .fl-mini-cart-content {
	right: 0px;
	left: inherit;
}

.mini-cart .btn-remove1 {right: 14px!important;}

.mini-cart .basket a:first-child:before {
	font-size: 20px;
}
nav {
	width: 100%;
	height: 3px;
	margin: 0px auto auto;
}
.nav-inner {
	height: auto;
	width: 100%;
	margin: auto;
}
ul.navmenu {
	padding: 0px;
	margin: auto;
}
.mini-cart .basket a span {
	display: none;
}
.mini-cart .basket a {
	margin: 0px;
	font-size: 14px;
	letter-spacing: normal;
	background: none;
	font-weight: normal;
	min-width: 40px !important;
	height: 40px;
	padding: 0px 10px;
	line-height: 40px;
}
.fl-cart-contain {
	right: 0px;
	top: 0px;
}
.header-container .fl-header-right {
	top: 0px;
	z-index: 500;
	right: 15px;
	margin-top: -36px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	
}
ul#cart-sidebar {
	padding: 10px 0 0;
}
.fl-mini-cart-content li.item {
	width: 290px;
}
.the-slideshow .slideshow .slide img {
	width: 100%;
}
.caption.light1 {
	display: none;
}
.slideshow-wrapper .backward {
	top: 25% !important;
	width: 15px !important;
	height: 38px !important;
}
.slideshow-wrapper .forward {
	right: 25px !important;
	top: 25% !important;
	background: url(../static/img/slideshow-arrows.png) -85px 0 no-repeat !important;
	width: 15px !important;
	height: 38px !important;
}
.caption.top-center {
	display: none;
}
ul.slideshow {
	height: 122px !important;
}
.the-slideshow .tab-pager {
	left: 40%;
}
.service-section .services {
	padding: 20px 0px;
	width: 100%;
	border-bottom: 1px #e5e5e5 solid;
	border-left: 1px #fff solid;
	border-right: none;
}
.offer-banner-section .row {
	padding-top: 30px;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li {
	padding: 0px 4px;
	font-size: 14px;
	width: 100%;
	margin: 0px;
	border-bottom: none;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li.tab-nav-actived, .thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li:hover {
	border-bottom: none;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li:last-child {
	border-bottom: 1px solid #666;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content .products-grid-home .item {
	margin-left: 0px;
	width: 100%;
}
.thmg-slider-producttabs ul.pdt-list li.item {
	width: 75%;
	margin: 0px auto 0px 13%;
}
.bag-product-slider .col-xs-4 {
	padding: 0 3px;
}
.col-item .item-title {
	white-space: normal;
}
.ratings .rating-links {
	display: none;
}
.col-item a.thumb span.quick-view {
	display: none;
}
.thmg-slider-producttabs ul.pdt-list li.item a.thumb span.quick-view {
	display: none;
}
.promo-banner-section {
	margin: 0px auto;
}
.promo-banner-section .col {
	float: none;
	padding: 20px 0px;
	width: 95%;
	margin: auto;
}
.promo-banner-section .col img {
	width: 100%;
}
.newsletter {
	width: auto;
}
.newsletter-wrap input[type="text"] {
	width: 100% !important;
	margin: 5px 0px 15px 0px;
}
.newsletter-wrap button.subscribe {
	margin-left: 0px !important;
	width: 100%
}
.social ul li {
	margin-right: 4px;
}
.page-title h2 {
	font-size: 36px;
}
.cat-img-title.cat-box {
	font-size: 14px;
	top:0;
	width: 100%;
	margin-top:8%;
	padding-bottom:10px;
	padding-top:10px;
	padding-left:20px
}
.carousel-inner>.item>img, .carousel-inner>.item>a>img {
	width: 100%
}
#carousel-example-generic .carousel-caption {

}
.category-description .slider-items-products .owl-buttons a {
	left: 5px;
	padding: 2px 3px 10px 0px;
	height: 25px;
	width: 25px;
}
.category-description .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 20px;
	padding: 0px 8px;
	line-height: 20px;
}
.category-description .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 16px;
	padding: 0px 8px;
	line-height: 20px;
}
.category-description .small-tag {
	font-size: 18px;
}
.cat-img-title .cat-heading {
	font-size: 24px;

}
.cat-img-title.cat-bg p {
	font-size: 14px;
}
.toolbar .pager {
	width: 70%;
	margin-top:3px;
	margin-right: 0;
}
.limiter {
	display: none
}
.pager .limiter label, .toolbar label {
	display: none;
}
.category-products ul.products-grid li.item {
	width: 100%;
}
.category-products ul.products-grid li.item a.thumb span.quick-view {
	display: none;
}
.products-list .product-shop {
	width: 100%;
	margin: 10px 0;
}
.col-left .block-cart .block-content ul li .product-details {
	width: 62%;
}
.side-nav-categories {
	margin-bottom: 10px;
}
.block {
	margin: 0px 0px 15px 0px;
}
.category-products ul.products-grid li.item a.thumb span.quick-view {
	display: none;
}
.products-grid .actions button.button.btn-cart span {
	font-size: 10px;
}
.nav-tabs > li {
	width: 100%;
	text-align: center;
	margin-bottom: 1px;

}
.tab-content {
	display: inline-block;
}
.form-add-tags input.input-text, select, textarea {
}

.product-shop .product-next-prev .product-prev {
	margin-top: 5px;
}
.product-shop .product-next-prev .product-prev {
	width: 30px;
	height: 28px;
	right: 49px;
	margin: auto;
	padding: inherit;
}
.product-shop .product-next-prev .product-next {
	width: 30px;
	height: 28px;
	right: 15px;
	margin: auto;
	padding: inherit;
}
.product-view .product-name h1 {
	font-size: 30px;
}
select#billing-address-select {
	width: 100%;
}
.sidebar .block dl {
	padding: 0px 10px;
}
.group-select li .input-box input.input-text, .group-select li .input-box textarea {
	width: 100%;
}
.group-select li .input-box {
	width: 100%;
}
.group-select li input.input-text, .group-select li textarea {
	width: 100% !important;
}
.group-select li select {
	width: 100% !important;
}
label {
	font-weight: normal;
}


.crosssel h2 {
	font-size: 13px;
	font-weight: normal;
}
.cart-collaterals h3 {
	font-size: 14px;
}
.col2-set .col-1 {
	width: 100%;
}
.col2-set .col-1 h2 {
	font-size: 20px;
}
.col2-set .col-2 {
	width: 100%;
	margin-top: 20px;
}
.col2-set .col-2 h2 {
	font-size: 20px;
}
.back-link {
	float: none;
	display: block;
	padding: 7px 0px;
}
.state_bar li {
	width: 100%;
	margin-top: 8px;
	margin-right: 0px;
}
.account-login .col2-set .col-1 {
	width: 100%;
	padding:30px;
	min-height: inherit;

}
.account-login .col2-set .col-2 {
	width: 100%;
	padding: 30px;

}
.popup1 {
	display: none !important;
}
#overlay {
	display: none !important;
}
.footer-column {
	width: 96%;
	margin-left: 2%
}
.products-list .actions .add-to-links a.link-wishlist {margin-bottom:5px}
.products-list .add-to-links {
	margin-top: 5px;
	display: inline-block;
}
.review1, .review2 {
	width: 100%;
}
.box-reviews .review {
	border-left: 0 solid #dfdfdf;
	float: left;
	padding-left: 0;
	width: 95%;
}
.related-slider {
	display: inline-block;
	width: 100%;
}
.service-section .services {
	border-left: none;
}
footer .footer-inner {
	margin-bottom: 10px;
	padding-top: 0px;
	padding-bottom: 15px;
}
.cloud-zoom-big {
	left: 0 !important;
}
.top-banner-section .col-xs-12 img {
	margin-bottom: 15px;
}
.discount, .totals {
	min-height: inherit;
}
.cross-sell-pro .new_title.center {
	height: auto;
}
.cross-sell-pro .new_title h2 {
	font-size: 14px;
}
.top-offer-banner {
	margin: 15px 0px 0px 0px;
}
.offer-inner .left {
	width: 100%;
}
.offer-inner .left .col.mid {
	padding: 0px 0px 20px 0px;
	width: 100%;
}
.offer-inner .left .col.last {
	padding: 0px 0px 15px 0px;
	width: 100%;
}
.offer-inner .right {
	width: 100%;
}
.offer-inner .inner-text h3 {
	font-size: 18px;
	margin: 6px 0;
}
.offer-inner .right .inner-text {
	top: 40%;
}
.offer-inner .right .inner-text h3 {
	font-size: 40px;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav {
	display: inline-block;
	width: 100%;
}
.latest-blog {
    padding: 0px 15px;
	margin-top: 0px;
	margin-bottom:20px
}
.brand-logo {
	padding: 0px 0px;
}
footer .co-info {
	width: 100%;
}
footer address {
	width: 100%;
}
.payment-accept img {
	margin-left: 3px;
	width: 50px;
}
.producttabs {
	margin-top: 0px;
}
footer address span {
	font-size: 13px;
	width: 85%;
}
.forcefullwidth_wrapper_tp_banner, .fullwidthbanner-container {
	height: 180px !important;
}
.tp-caption.ExtraLargeTitle {
	left: 0px!important
}
.tp-caption.LargeTitle {
	left: 0px!important
}
.tp-caption.Title {
	left: 0px!important
}
.tp-simpleresponsive .caption, .tp-simpleresponsive .tp-caption {
	left: 0px!important;
	
}



#thmg-slider-slideshow .container {
	padding: 0px
}
.catalog-category-view .page-heading {
	padding: 15px 0;
}
.catalog-category-view .page-heading .page-title h1, .catalog-category-view .page-heading .page-title h2 {
	font-size: 26px!important;
}

.our-features-box li {
	width: 100%;
}
.our-features-box li.last {
	width: 100%;
}
.sidebar .hot-banner img {
	margin-bottom: 15px;
}
.product-view .previews-list li {
	width: 70px !important;
}
.product-view .product-shop .add-to-box .btn-cart {
	margin-top:05px;
	margin-left:0px
}

.product-view .product-shop .add-to-box .custom {margin-top:5px}

.nav-tabs.product-tabs > li > a {
	width: 100%;
}
.tab-content {
	width: 100%;
}
.box-reviews .form-list input.input-text {
	width: 100%;
}
.box-reviews textarea {
	width: 100% !important;
}
.product-collateral .tabs {
	border-bottom: none;
}
.product-view .product-name {
	margin-bottom: 0px;
}
.table-responsive {
	margin-bottom: 0px;
	margin-top: 0px;
	padding: 0px;
	border: none
}

.buttons-set .login {
	float: none;

}
.col-main {
	margin-top: 10px;
}

.col2-right-layout .col-main {
    padding: 0;
}

.col-right.sidebar {
	 padding: 0;
	 
}

.sidebar {

	padding-right: 0px;

}
.product-grid {
	padding:0px;
	padding-top: 20px
}

.product-list {
	padding:0px;
	padding-top: 20px
}

.sort-by {display: none;}
.sorter .view-mode {
	margin-right: 0px
}
#container_form_news {
	margin-top: 15px
}
.account-login {
	margin-top: 0px;
}
.toolbar {
	padding: 15px
}
#fade {
	display: none !important;
}
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
#fade {
	display: none !important;
}
.logo {
	text-align: center;
	padding-bottom: 0px;
	margin-top: 0px;
	/* width: 100%; */
	height: 100px;
	line-height: normal;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 15px;
	padding-right: 15px;
	float: none;
	margin: 0px
}
.header-container .fl-header-right {
	top: 20px;
	z-index: 500;
	right: 15px;
}
.header-container .fl-nav-menu {
	display: block;
	border-radius: 0px;
	/* overflow: hidden; */
    /* top: 150px; */
	clear: both;
	float: left;
	margin-top: 15px;
	margin-left: 10px;
}
#thmg-slider-slideshow {
	margin: 0px
}
.tp-caption.ExtraLargeTitle span {
	border: none;
	font-size: 30px!important
}
.tp-caption.LargeTitle {
	font-size: 46px!important;
	margin-top: 20px
}
.tp-caption.LargeTitle span {
	font-size: 46px!important
}
.tp-caption.Title {
	display: none
}
.buy-btn {
	font-size: 16px!important;
	padding: 6px 25px!important
}

.best-pro.slider-items-products .owl-buttons .owl-next {
    position: absolute;
    right: 10px;
    margin-top: -110px;
    top: 0;
}

.best-pro.slider-items-products .owl-buttons .owl-prev {
    position: absolute;
    left: 20px;
    margin-top: -110px;
    top: 0;
}

.blog_entry-title {padding:0px 15px}
.blog_entry .btn {margin-left:15px}
.blog-wrapper .entry-content {padding:0px 15px}
.comment-content {padding:0px 15px}


#top .container {
    padding: 0 15px;
}

#top > div a img {
    margin-bottom: 20px;
}
#top {
    margin-top: 0px;
    margin-bottom: 40px;
    padding-top: 30px;
    padding-bottom: 10px;
}
#top ul li {
	width: 100%
}
.top-cate {
	margin-top: 0px;
	padding-bottom:15px;
}
.top-cate .new_title h2 {
	min-width: auto
}
.top-cate .slider-items-products .owl-buttons .owl-prev {
	margin-top: 60px;
	left: 16px
}
.top-cate .slider-items-products .owl-buttons .owl-prev a {
 display:none
}
.top-cate .slider-items-products .owl-buttons .owl-next {
	margin-top: 60px;
	right: -1px
}
.top-cate .slider-items-products .owl-buttons .owl-next a {
 display:none
}

.best-pro {margin-bottom:0px; padding-bottom:0}

.best-pro .new_title h2 {
	min-width: auto
}
.cate-banner-img {
	display: none
}

.hot-deal ul.products-grid .item .item-inner {
	margin-bottom:30px
}

.hot-deal {
    padding: 75px 20px 40px 20px;
}

.hot-section h3 {
	font-size: 56px
}
.hot-section h4 {
	font-size: 18px
}
.testimonials-section {
	padding: 0px 10% 10px 10%;
}
.logo-brand .owl-pagination {
	text-align: left
}

.cart-collaterals {padding:0px 15px}

.mid-section {
	padding: 50px 0px;
	background: #f5f5f5 url(../static/img/mid-img.jpg) no-repeat 0 0px;
	margin-top: 0px;
}
.mid-section .container {
	padding: 0px 15px
}

.mid-section h2 {font-size: 50px;}
.spl-pro:after {border:none}

.mid-section h3 {
	font-size: 36px;
	margin: 0px 30px;
}
.mid-section .block1 {
	padding: 20px;
	margin-right: 0px;
	text-align: center;
	margin-top: 30px
}
.mid-section .block2 {
	padding: 20px;
	margin-right: 0px;
	text-align: center;
	margin-top: 20px
}
.mid-section .block3 {
	padding: 20px;
	margin-left: 0px;
	text-align: center;
	margin-top: 30px
}
.mid-section .block4 {
	padding: 20px;
	margin-left: 0px;
	text-align: center;
	margin-top: 20px
}
.latest-blog h2 {
	margin-bottom: 0px!important
}
.latest-blog .blog_inner {
	margin: 0px
}
.latest-blog h3 {
	margin-top: 10px;
}
.latest-blog .blog_inner {
	margin-bottom: 40px;
}
.navbar-collapse.collapse {
	display: block!important;
	height: auto!important;
	padding-bottom: 0;
	overflow: visible!important;
	visibility: visible!important;
	position: absolute;
	right: 0px;
	border: none;
	left: 80px;
	margin-top: 10px;
	box-shadow: none
}
.navbar-form {
	border: none;
	box-shadow: none;
	display: none
}
.fl-links {
	display: none
}
.mini-cart {
	border: none
}
.mini-cart .basket a span {
	margin-top: -8px;
	color: #fff;
	right: 5px;
}
.navbar-form .search-btn {
	height: 50px;
	line-height: 50px;
	width: 55px
}
.navbar-collapse form[role="search"].active input {
	height: 50px;
	line-height: 50px;
	width: 100%
}
#brand-logo-slider {
	margin: 20px 0 !important;
}
#right_toolbar {
	display: none !important;
}
.brand-logo .new_title.center {
	height: 40px;
}
.our-features-box .feature-box {
	margin-right: 0px;
	border-right: none;
	border-bottom: 1px #eaeaea solid;
}
.new_title {
	margin-bottom: 2px;
}

.new_title h2 {
	margin-bottom: 40px;
}
.my-wishlist .buttons-set2 .btn-share {
	margin-left: 5px;
}
.multiple_addresses .title-buttons.pull-right {
	float: left !important;
}
#multiship-addresses-table {
	margin-top: auto;
}
.cross-sell-pro .new_title.center {
	height: auto;
}
.cross-sell-pro .new_title h2 {
	font-size: 14px;
}
.discount, .totals {
	min-height: inherit;
}

.upsell-pro .new_title.center {
	height: inherit;
}
.top-banner-section .col-xs-12 img {
	margin-bottom: 15px;
}
.product-view .flexslider-thumb {
	padding: 0 35px;
}
.email-addto-box {
	margin-left: 0;
	margin-top: 15px;
}

.product-essential {padding:0px 15px}

.footer-column {
	margin-left: 10px;
	margin-bottom: 15px;
	padding-top: 20px
}
.footer-top {
	text-align: center
}
.footer-top .social {
	float: none;
	text-align: center;
	margin-bottom: 15px
}
.footer-top .social ul {
	float: none
}
.footer-bottom .company-links li {
	margin-left: 0;
	float: none;
	margin: 0 10px 5px 0;
}
.footer-bottom .company-links ul {
	text-align: center;
}
footer .coppyright {
	float: none;
	text-align: center;
	margin-bottom: 15px;
}
.social ul li a {
	width: 35px;
	height: 35px;
	line-height: 32px;
}
.payment-accept img {
	margin-right: 6px;
}
footer .footer-inner {
	margin-bottom: 15px;
	padding-top: 0px;
}
.header-banner.mobile-show .our-features-box {
	display: block !important;
}
.offer-slider h1 {
	font-size: 40px;
}
.offer-slider p {
	padding: 0 2% 1%;
	font-size: 14px;
}
.bx-wrapper {
	margin: auto;
}
.bx-viewport {
	width: 95% !important;
}
.bxslider li {
	margin-right: 30px !important;
}
.bx-wrapper .bx-pager {
	padding-top: 8px;
}
.owl-pagination {
	top: -20px;
	right: auto;
	width: 100%;
}
.owl-theme .owl-controls .owl-page span {
	width: 15px;
	height: 15px;
}
.cat-img-title.cat-box {
	font-size: 14px;
	top:0;
	width:70%;
	margin-top:6%
}
.carousel-inner>.item>img, .carousel-inner>.item>a>img {
	width: 100%
}
#carousel-example-generic .carousel-caption {

}
#toTop {
	width: 30px;
	height: 30px;
	line-height: 38px;
}
.cms-index-index .our-features-box {
	display: none;
}
.header-banner p {
	margin: 0px;
}
.product-next-prev {
	display: none;
}
.related-slider {
	display: inline-block;
	width: 100%;
}
.service-section .services {
	border-left: none !important;
}
.box-reviews .review {
	border-left: 0 solid #dfdfdf;
	float: left;
	padding-left: 0;
	width: 95%;
}
.product-view .previews-list li {
	margin-right: 5px;
}
.products-list .product-image {
	width: 30%;
}
.footer-bottom .company-links li {
	float: left;
	margin: 8px 15px 0px 0;
}
.language-currency {
	margin-left: -5px;
}
.fl-currency {
	margin-right: 2px;
}
.fl-language {
	padding-left: 8px;
}
.header-container .right_menu {
	background: none repeat scroll 0 0 #f5f5f5;
	margin-top: 0;
	padding-bottom: 5px;
	position: absolute;
	text-align: center;
	right: -2px;
	top: 0;
}
.toplinks .links div {
	padding-right: 10px;
}
.toplinks {
	float: none;
	position: absolute;
	top: 35px;
}
.toplinks div.links div a:hover {
	color: #f65f73;
}
.mm-toggle-wrap {
	display: block;
	width: 100%;
}
.toplinks div.links div a {
	padding: 3px 10px;
}
.toplinks div.links div a {
	margin-left: 0px;
}
.toplinks>div>div.myaccount>a:before {
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.myaccount>a:before {
	content: '\f007';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.wishlist>a:before {
	content: '\f004';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.check>a:before {
	content: '\f00c';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.demo>a:before {
	content: '\f09e';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks .links div .fl-links ul .clicker:before {
	content: '\f15b';
	font-family: FontAwesome;
	font-size: 13px;
	padding-left: 4px;
	padding-top: 2px;
	float: left;
	margin-right: 0px;
	margin-top: -2px;
}
.toplinks>div>div.login>a:before {
	content: '\f13e';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.toplinks>div>div.logout>a:before {
	content: '\f09c';
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 0px
}
.search-box {
	margin-top: -33px;
	width: 200px;
}
#search {
	padding: 3px 6px;
	width: 175px;
}
.search-btn-bg {
	position: absolute;
	padding: 6px 5px;
	right: 10px;
	height: 26px;
}
nav {
	width: 100%;
	height: 44px;
	margin: auto;
}
.nav-inner {
	height: auto;
	width: 100%;
	margin: auto;
}
ul.navmenu {
	padding: 0px;
	margin: auto;
}
.mini-cart .basket a {
	margin: 0px;
	font-size: 14px;
	letter-spacing: normal;
	background: none;
	font-weight: normal;
	min-width: 40px !important;
	height: 50px;
	padding: 0px 10px;
	line-height: 50px;
}
.the-slideshow .slideshow .slide img {
	width: 100%;
}
.caption.light1 {
	display: none;
}
.slideshow-wrapper .backward {
	top: 30% !important;
	width: 17px !important;
	height: 45px !important;
}
.slideshow-wrapper .forward {
	right: 10px !important;
	top: 30% !important;
	background: url(../static/img/slideshow-arrows.png) -84px 0 no-repeat !important;
	width: 17px !important;
	height: 45px !important;
}
.caption.top-center {
	display: none;
}
ul.slideshow {
	height: 157px !important;
}
.the-slideshow .tab-pager {
	left: 46%;
}
.service-section .services {
	padding: 20px 0px;
	width: 100%;
	border-bottom: 1px #e5e5e5 solid;
	border-left: 1px #fff solid;
	border-right: none;
}
.col {
	width: 370px;
	margin: auto;
}
.offer-inner .right .inner-text {
	top: 65%;
	width: 80%;
}
.thmg-slider-producttabs .thmg-slider-pdt-content ul.pdt-list li {
	width: 45%;
	margin-left: 10px;
	margin-right: 2%;
}
.newsletter {
	width: auto;
}
.newsletter-wrap input[type="text"] {
	width: 60% !important;
	margin: 5px 0px 15px 0px;
}
.newsletter-wrap button.subscribe {
	margin-left: 0px !important;
}
.payment-accept {
	float: none !important;
}
.footer-bottom .inner .bottom_links a {
	margin-left: 9px;
	float: left !important;
	font-size: 11px;
}
.breadcrumbs ul {
	padding: 0px 15px;
}
.page-title h2 {
	font-size: 36px;
}
.category-description .slider-items-products .owl-buttons a {
	left: 5px;
	padding: 2px 3px 10px 0px;
	height: 30px;
	width: 30px;
}
.category-description .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 20px;
	padding: 0px 10px;
	line-height: 24px;
}
.cat-img-title .cat-heading {
	font-size: 36px;
}
.cat-img-title p {
	font-size: 18px;
}
.category-description .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 20px;
	padding: 0px 10px;
	line-height: 24px;
}

.sort-by {display:none}
.limiter {display:none}

.toolbar .pager {
	float: left;
	margin: 3px 0px;
	width: 65%;
}
.col-left .block-cart .block-content ul li .product-details {
	width: 80%;
}
.products-list .product-shop {
	width: 65%;
	margin: 0px 0;
}
.col-left .block-cart .block-content ul li .product-details {
	width: 78%;
}
.products-grid .actions button.button.btn-cart span {
	font-size: 10px;
}

.nav-tabs > li {
	width: 100%;
	text-align: center;
	margin-bottom: 1px;

}
.tab-content {
	display: inline-block;
}
.form-add-tags input.input-text, select, textarea {
	width: 70% !important;
}

.product-view .product-shop {
    padding-left: 15px;
}

select#billing-address-select {
	width: 100%;
}
.sidebar .block dl {
	padding: 0px 10px;
}
select#billing-address-select {
	width: 100%;
}
.sidebar .block dl {
	padding: 0px 10px;
}
.group-select li .input-box input.input-text, .group-select li .input-box textarea {
	width: 100%;
}
.group-select li .input-box {
	width: 100%;
}
.group-select li input.input-text, .group-select li textarea {
	width: 100%;
}
.group-select li select {
	width: 100% !important;
}
#shopping-cart-table {
	margin-top: 0px;
}
label {
	font-weight: normal;
}

.col2-set .col-1 {
	width: 100%;
}
.col2-set .col-1 h2 {
	font-size: 20px;
}
.col2-set .col-2 {
	width: 100%;
}
.col2-set .col-2 h2 {
	font-size: 20px;
}
.back-link {
	float: none;
	display: block;
	padding: 7px 0px;
}
.state_bar li {
	width: 100%;
	margin-top: 8px;
	margin-right: 0px;
}
.account-login .col2-set .col-1 {
	width: 100%;
	padding: 30px;
	min-height: inherit;

}
.account-login .col2-set .col-2 {
	width: 100%;
	padding: 30px;
}
.popup1 {
	display: none !important;
}
#overlay {
	display: none !important;
}
#cloud-zoom-big {
	display: none !important;
}
.header-banner .assetBlock {
	width: 100%;
}
.search-box {
	width: 190px;
	margin-top: -34px;
}
#search {
	padding: 3px 6px;
	width: 215px;
	height: 38px;
}
.search-btn-bg {
	position: absolute;
	padding: 6px 5px;
	height: 26px;
	right: 10px
}
.search-icon, .search-bar-submit {
	height: 38px;
	width: 50px;
}
.search-bar.search-bar-open, .no-js .search-bar {
	height: 38px;
	width: 260px;
}
.search-bar {
	height: 38px;
	min-width: 50px;
}
.search-icon:before {
	font-size: 16px;
}
.fl-links {
	margin-top: 10px
}
.fl-links .clicker {
	padding: 0px 10px;
	height: 50px;
	line-height: 50px;
}
.fl-links .clicker:before {
	font-size: 20px;
}
.fl-links > .no-js:hover > .clicker {
	padding: 0px 10px;
	line-height: 50px;
	height: 50px;
}

.mini-cart .fl-mini-cart-content {
	right: 0px;
	left: inherit;
}

.mini-cart .btn-remove1 {right:0px!important}

.mini-cart .basket a:first-child:before {
	font-size: 20px;
}
.fl-cart-contain {
	right: 8px;
	top: 10px;
}
.offer-inner .left {
	width: 100%;
}
.offer-inner .right {
	width: 100%;
}
.offer-inner .left .col-1 {
	margin-bottom: 0px;
}
.offer-inner .right .inner-text {
	top: 65%;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav {
	display: inline-block;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li {
	padding: 0px 4px;
	font-size: 14px;
	width: 100%;
	margin: 0px;
	border-bottom: none;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li.tab-nav-actived, .thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li:hover {
	border-bottom: none;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li:last-child {
	border-bottom: 1px solid #666;
}
.latest-blog {
	padding:5px 20px;
	margin-bottom:0px
}
footer .co-info {
	width: 100%;
	margin-bottom: 10px;
	margin-top: 0px
}
.brand-logo {
	padding: 0px 0px;
}
.forcefullwidth_wrapper_tp_banner, .fullwidthbanner-container {
	height: 250px !important;
}
.tp-caption.ExtraLargeTitle {
	left: 0px!important
}
.tp-caption.LargeTitle {
	left: 0px!important
}
.tp-caption.Title {
	left: 0px!important
}
.tp-simpleresponsive .caption, .tp-simpleresponsive .tp-caption {
	left: 0px!important
}
#thmg-slider-slideshow .container {
	padding: 0px
}
.top-offer-banner {
	margin: 15px 0px;
}
.catalog-category-view .page-heading {
	padding: 20px 0;
}
.catalog-category-view .page-heading .page-title h1, .catalog-category-view .page-heading .page-title h2 {
	font-size: 32px!important;
}
.breadcrumbs a, .breadcrumbs strong {
	font-size: 13px;
}
.our-features-box li {
	width: 100%;
}
.our-features-box li.last {
	width: 100%;
}
.sidebar .hot-banner img {
	margin-bottom: 15px;
}
.side-nav-categories, .side-nav-categories .block {
	margin-bottom: 20px;
}

.sidebar .hot-banner img {
	width: 75%;
}

.products-list .actions .add-to-links a.link-wishlist {margin:5px 0px}
.product-shop .social {
	margin-top: 13px;
}
.product-collateral {
	margin-top: 10px;
	margin-bottom: 10px;
	padding:0px 15px
}
.related-pro {padding:0px 15px}

.tab-content {
	width: 100%;
}
.box-reviews .form-list input.input-text {
	width: 100%;
}
.box-reviews textarea {
	width: 100% !important;
}
.product-collateral .tabs {
	border-bottom: none;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	width: 100%;
}
.nav-tabs.product-tabs > li > a {
	width: 100%;
}
.review1 {
	width: 100%;
}
.review2 {
	width: 100%;
}
.product-view .previews-list li {
	width: 68px !important;
}
.product-view .product-shop .add-to-box {
	padding: 22px 0px 10px;
}
.product-view .product-name {
	margin-bottom: 5px;
}
.table-responsive {
	margin-bottom: 0px;
	margin-top: 10px;
	border: none
}
.col-main {
	
}

.col2-right-layout .col-main {
    padding: 0;
}

.col-right.sidebar {
	 padding: 0;
	 
}

.product-list {
    padding: 0px;
    padding-top: 20px;
}

.product-grid {
	padding: 15px
}
.sidebar {
	padding-right: 15px;
	padding-left:15px
}
.col2-set .col-2 {
	margin-top: 20px;
}
.account-login {
	margin-top: 0px;
}
.footer-inner .container {
	padding-left: 15px;
	padding-right: 15px
}
}
@media (min-width: 768px) and (max-width: 991px) {
#nav li.drop-menu:hover ul {
	top: 79px
}
.header-container .fl-nav-menu {
	display: block;
	border-radius: 0px;
	/* overflow: hidden; */
    /* top: 150px; */
	background-color: #fff;
	clear: both;
}
.fl-header-right {
	position: absolute;
	right: 0;
	top: 0px;
}
#nav > li > a {
	line-height: 30px
}
.our-features-box .feature-box {
	margin-right: 0px
}

.our-features-box li.last a {
	display: inline-block;
	padding: 15px 60px;
}
.logo-item img {
	width: 100%
}
#thmg-slider-slideshow {

}
.forcefullwidth_wrapper_tp_banner, .fullwidthbanner-container {
	height:500px!important
}

.buy-btn {
    font-size: 20px!important;
    padding: 6px 25px!important;
}

.tp-caption.ExtraLargeTitle {
	left: 0px!important;
}
.tp-caption.LargeTitle {
	left: 0px!important;

}
.tp-caption.Title {
	left: 0px!important
}
.tp-simpleresponsive .caption, .tp-simpleresponsive .tp-caption {
	left: 0px!important
}
#thmg-slider-slideshow .container {
	padding: 0px
}


.top-cate .slider-items-products .owl-buttons .owl-prev, .top-cate .slider-items-products .owl-buttons .owl-next {display:none}
.best-pro.slider-items-products .owl-buttons .owl-prev {left:0px}
.best-pro.slider-items-products .owl-buttons .owl-next {right:-10px}
.spl-pro:after {border:none}

.testimonials-section {
	padding: 50px 5% 10px 5%;
}
.mid-section strong {
	font-size: 20px
}
.spl-pro {
	margin-top: 80px
}
.mid-section .block1 {
	margin-top: 40px
}
.mid-section .block3 {
	margin-top: 40px
}

.catalog-category-view .page-heading {
	padding: 30px 0;
}
.product-view .flexslider-thumb {
	padding: 0 28px;
}
.product-view .previews-list li {
	width: 85px!important
}
.product-view .flexslider-thumb .flex-direction-nav {
	left: 8px;
	top: 40%;
	width: 90%;
}
.cross-sell-pro .new_title h2 {
	font-size: 14px;
}
.product-view .flexslider-thumb .flex-direction-nav {
	z-index: 0;
}

.product-shop .social ul li {
	margin-bottom: 5px
}
.cat-img-title.cat-box {
	margin: 0;
	text-align: left;
	top: 11%;
	width:70%
}
.category-description .small-tag span {
	background: #ffc000;
	display: inline-block;
	line-height: 70px;
	padding-left: 10px;
	padding-right: 20px;
	height: 70px;
	width: 70px;
	border-radius: 80px;
	font-size: 25px;
	color: #fff;
}

#carousel-example-generic .carousel-caption {top:6%}



#nav .level0-wrapper2 .col-1 {
	width: 100%;
}
#nav .level0-wrapper2 .col-2 {
	width: 100%;
}
.col-2 .menu_image {
	display: inline-block;
}
.col-2 .menu_image1 {
	display: inline-block;
}
#nav .level0-wrapper, #nav.classic ul.level0 {
	top: 79px;
}
.footer-bottom .company-links li {
	margin-left: 6px;
}
.footer-bottom .company-links li a {
	font-size: 11px;
}
.social ul li a {
	width: 35px;
	height: 35px;
	line-height: 35px;
}
.our-features-box li {
	width: 20%;
	box-sizing: border-box;
}
.our-features-box .feature-box {
	padding: 20px 15px
}
.our-features-box ul {
	width: 100%;
}
.header-banner .assetBlock {
	width: 100%;
}
#nav li.drop-menu ul {
	top: 37px;
}
.nav-inner {
	border-top: 1px #b4d597 solid
}
#nav > li > a {
	padding: 0px 0
}
#nav > li > a > span {
	padding: 0 15px;
	line-height: 0px;
}
.toplinks .links div.demo {
	display: none;
}
.toplinks div.links div a {
	padding: 0 10px 0 0
}
.header-container .right_menu {
	margin-top: 6px;
}
.toplinks .links {
	text-align: left;
}
.product-view .flexslider-thumb .flex-prev {
	left: -10px;
}
.product-view .flexslider-thumb .flex-next {
	right: -27px;
}
.product-view .flexslider-thumb {
	padding: 0 20px;
}
.email-addto-box {
	width: 100%;
	margin-left: 0;
}
.product-view .email-friend {
	margin: auto;
	margin-top:10px
}
.product-view .product-shop .add-to-box .pull-right {
	float: left !important;
}
.products-list .actions .add-to-links a.link-wishlist {margin:5px 0px}
.products-list .add-to-links {
	display: inline-block;
}
#compare-items .btn-remove1 {
	top: 0px;
}
.footer-inner .newsletter-wrap {
	display: inline-block;
}
.social h4 {
	margin: 6px 0 5px;
}
.footer-bottom .company-links li {
	float: left;
}
.footer-inner .social {
	width: 100%;
	float: none;
	text-align: center
}
.social ul li {
	border-bottom: none;
	float: none!important;
	display: inline-block;
	margin-bottom: 8px
}
#sequence-theme .controls a {
	background-size: 50px;
}
.welcome-msg {
	display: none;
}
.search-box {
	margin-top: 5px;
	width: 162px;
}
#search {
	padding: 3px 6px;
	width: 160px;
}
.search-btn-bg {
	position: absolute;
	padding: 6px 5px;
	height: 26px;
	right: 10px
}
.cat-img-title.cat-box .inner {
	width: 720px;
	margin: auto;
}
#nav ul.level0 > li {
	width: 30%;
}
#nav ul.level0 > li.last {
	width: 30%;
}
#nav ul.level1 > li a {
	display: inline-flex;
	float: none;
}
#nav .level0-wrapper .nav-add .push_item {
	width: 22.8%;
	height: auto;
	margin-right: 15px;
}
#nav .level0-wrapper .nav-add .push_item img {
	width: 100%;
	height: auto;
}
#nav .level0-wrapper .nav-add .push_item .push_img a {
	width: 100%;
}
#nav .level0-wrapper .nav-add .push_item_last {
	width: 22.8%;
}
#nav .level0-wrapper .nav-add .push_item_last img {
	width: 100%;
}
#nav .level0-wrapper, #nav.classic .parent > ul {
	margin: auto;
	padding: 10px 4px 10px 0px;
}
.nav-block-center {
	margin-top: 0px;
}
.grid12-5 {
	width: 23%;
}
.grid12-5 img {
	width: 100%;
}
.grid12-3 {
	width: 21.5%;
}
.grid12-3 img {
	width: 100%;
}
#nav .grid12-8 ul.level0 > li {
	width: 22% !important;
}
footer .co-info {
	margin-right: 0px!important;
	width: 100%;
	float: none;
	text-align: center;
}
footer address {
}
footer address span {
	text-align: center;
	width: 100%
}
footer address em {
	display: block!important;
	vertical-align: middle!important;
	margin-right: 8px!important;
	text-align: center;
	margin-bottom: 10px!important;
}
.grid12-3 a img {
	width: 100%;
}
.grid12-4 {
	width: 30%;
	margin: 5px 20px 0 0;
}
.grid12-4 a img {
	width: 245px;
}
.grid12-8 ul.level0 > li {
	width: 145px !important;
}
.grid12-8 .cat-img img {
	width: 175px !important;
}
.grid13-8 {
	width: 70%;
}
.grid12-8 {
	width: 100%;
}
.grid13-8 ul.level0 > li {
	width: 240px !important;
}
.slideshow-wrapper .browse-button, .slideshow-wrapper .tab-pager {
	z-index: 5;
}
.fl-custom-tabmenu .grid12-5 img {
	width: 100%;
}
#nav .level0-wrapper .nav-add {
	padding-left: 10px;
}
#nav ul.level0 > li .cat-img img {
	width: 135px;
}
.mini-cart .basket a span {
}
.mini-cart .basket a {
	min-width: 100% !important;
}
.caption.light1 {
	right: 4% !important;
	margin-right: 15% !important;
	top: 0% !important;
	width: 65% !important;
	padding-top: 10px !important;
}
.caption.light1 .heading {
	font-size: 40px !important;
	margin-top: 0px !important;
}
.caption .normal-text1 {
	display: none !important;
}
.caption.light1 .badge {
	margin-top: -83px !important;
}
.the-slideshow .slideshow .slide img {
	width: 100% !important;
}
ul.slideshow {
	height: 255px !important;
}
.slideshow-wrapper .backward {
	top: 37% !important;
	width: 22px !important;
}
.slideshow-wrapper .forward {
	top: 37% !important;
	background: url(../static/img/slideshow-arrows.png) -79px 0 no-repeat !important;
	width: 22px !important;
}
.caption.top-center {
	padding-top: 0% !important;
	width: 400px !important;
}
.caption .normal-text {
	font-size: 20px !important;
	margin-bottom: 0px !important;
}
.caption .heading {
	font-size: 34px !important;
	margin: 0px !important;
}
.caption p {
	padding: 10px 0px !important;
}
.caption .intro-btn a {
	padding: 10px 20px !important;
}
.caption .intro-btn {
	margin-top: 0px !important;
	padding: 5px 0px !important;
}
.caption.top-left {
	padding-left: 0%;
}
.service-section .services {
	border-bottom: 1px #e5e5e5 solid;
}
.service-section .services span {
	font-size: 11px;
}
.top-offer-banner {
	margin: 25px 0px
}
.offer-inner .right .inner-text h3 {
	font-size: 26px
}
.thmg-slider-producttabs .thmg-slider-pdt-content ul.pdt-list li {
	width: 31%;
	margin-left: 15px;
}
.latest-blog {
	padding: 0px
}
.newsletter-wrap input[type="text"] {
	width: 300px!important;
}
.breadcrumbs ul {
	padding: 0px 15px;
}

.category-description .slider-items-products .owl-buttons a {
	left: 5px;
	padding: 2px 3px 10px 0px;
	height: 30px;
	width: 30px;
}
.category-description .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 20px;
	padding: 0px 10px;
	line-height: 24px;
}
.category-description .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 20px;
	padding: 0px 10px;
	line-height: 24px;
}

.sort-by {display:none}

.toolbar .pager {
	margin: 3px 0px;
	width: 75%;
}

.toolbar.bottom .pager {  width:20%;}

.pager .pages li a {
/*padding: 2px 8px;*/
}
.category-products ul.products-grid li.item {
	width: 50%;
}

.box-category > ul > li ul > li > a {

}
.col-left .block-cart ul li.item {
	display: inline-block;
}
.col-left .block-cart .block-content ul li .product-details {
	width: 100%;
	margin-top: 10px;
}
.side-nav-categories {
	margin-bottom: 10px;
}
ol#compare-items li a.product-name {
	width: 80%;
	padding: 0;
}
.button, .btn, .button-inverse, .button-vote, .sidebar .button, .sidebar .btn, .dropdown .dropdown-menu .button, .buttons-set .back-link a, .scrolltop, .button-tabs li a {
	margin-bottom: 5px;
}
#recently-viewed-items .product-name {
	width: 90%;
}
.block-banner img {
	width: 100%;
}
.block {
	margin: 0px 0px 25px 0px;
}
.products-list .product-shop {
	width: 51%;
	margin-left: 15px;
}
.products-list .product-image {
	width: 45%;
}
.products-list .item a img {
	width: 100%;
}
.product-view .product-img-box .product-image .owl-next {top:1%}
.product-view .product-img-box .product-image .owl-prev {top:1%}


.product-img-box img {
	width: 100%;
}

select#billing-address-select {
	width: 100%;
}

#shopping-cart-table {
	margin-top: 0px;
}
label {
	font-weight: normal;
}
.product-view .product-shop .add-to-box button.button.btn-cart span {
	padding: 0px 10px
}

button.button.btn-proceed-checkout span {
	font-size: 14px;
}

button.button.btn-proceed-checkout:before {
	font-size: 13px;
}
.cart-collaterals .col-sm-4 {
	padding-right: 0;
}
.cart-collaterals .col-sm-4 .totals {
	margin-right: 15px;
}

.crosssel h2 {
	font-size: 14px;
	font-weight: normal;
}
.col2-set .col-1 h2 {
	font-size: 20px;
}
.col2-set .col-2 h2 {
	font-size: 20px;
}
.state_bar li {
	width: 18%;
	padding: 5px 12px;
}

#brand-logo-slider {
	margin: 30px 0 !important;
}
.custom-slider h3 {
	margin-top: 0px;
}
.custom-slider h3 a {
	font-size: 28px;
}
.custom-slider p { display:none

}
.custom-slider .carousel-indicators {
	bottom: 0;
}

.col2-set .col-2 .f-right {display:block}

 @media (min-width: 992px) and (max-width: 1169px) {
 .fl-cart-contain {
display:none
}



}
}
@media (min-width: 992px) and (max-width: 1199px) {
	
	
#page {
	width: 100%
}
.tp-caption.ExtraLargeTitle {
	left: 0px!important
}
.tp-caption.LargeTitle {
	left: 0px!important
}
.tp-caption.Title {
	left: 0px!important
}
.tp-simpleresponsive .caption, .tp-simpleresponsive .tp-caption {
	left: 0px!important
}
#thmg-slider-slideshow .container {
	padding: 0px
}
#nav > li > a > span {
	padding: 0px 10px
}

.top-cate {
    background: url(../static/img/top-cat-bg.jpg) no-repeat bottom left;
}
.top-catemobile {
	background: url(../static/img/top-cat-bg.jpg) no-repeat bottom left;
}
  

.forcefullwidth_wrapper_tp_banner, .fullwidthbanner-container {
    height: 450px!important;
}

.top-offer-banner {
	margin: 30px 0px
}
.state_bar li {
	width: 18.8%;
	font-size: 12px;
}
.cross-sell-pro .new_title h2 {
	font-size: 20px;
}
.our-features-box .feature-box {
	padding: 20px
}
.product-view .previews-list li {
	width: 85px!important;
	margin-right: 5px
}
.product-view .flexslider-thumb .flex-direction-nav {
	z-index: 0;
}
.payment-accept {
	width: 100%;
	text-align: center;
	display: inline-block;
	margin: 0;
	padding: inherit;
}
.payment-accept img {
	margin-left: 5px;
	opacity: 0.5;
	width: 50px;
}
.our-features-box ul {
	width: 100%;
}
.header-banner .assetBlock {
	width: 100%;
}
#nav .level0-wrapper .nav-add {
	padding-left: 0;
}
.col-2 .menu_image img {
	width: 100%;
}
.col-2 .menu_image1 img {
	width: 100%;
}
#nav .level0-wrapper2 .col-1 {
	width: 69%;
}
#nav .col-1 ul.level0 > li {
	width: 29%
}
.toplinks .links div.demo {
	display: none;
}
.toplinks div.links div a {
	padding: 0 0 0 8px;
}
.our-features-box ul {
	width: 100%;
}
.product-view .flexslider-thumb .flex-prev {
	left: -10px;
}
.product-view .flexslider-thumb .flex-next {
	right: -8px;
}
.product-view .flexslider-thumb {
	padding: 0 22px;
}
.product-view .email-friend {
	margin: auto;
}
.product-view .product-shop .add-to-box .pull-right {
	float: left !important;
	margin-top: 10px;
}


.product-additional .block-product-additional img {
	width: 100%;
}
.footer-column {
	margin-right: 15px
}
footer address em {
	vertical-align: top!important
}
footer address span {
	width: 90%
}
.social h4 {
	margin: 6px 0 5px;
}
.footer-bottom .company-links li {
	float: left;
}
#sequence-theme .controls a {
	background-size: 50px;
}
.welcome-msg {
	float: left;
}
.search-box {
	width: 240px;
}
#search {
	padding: 3px 6px;
	width: 230px;
}
.search-btn-bg {
	position: absolute;
	padding: 6px 5px;
	height: 26px;
	right: 10px
}
#nav .level0-wrapper, #nav.classic .parent > ul {
	padding-top: 15px;
}
#nav ul.level0 > li {
	width: 14.2%;
}
#nav ul.level0 > li.last {
	width: 136px;
}
#nav ul.level1 > li a {
	display: inline-flex;
	float: none;
}
#nav .level0-wrapper .nav-add .push_item {
	width: 22.3%;
	height: auto;
	margin-right: 15px;
}
#nav .level0-wrapper .nav-add .push_item img {
	width: 100%;
	height: auto;
}
#nav ul.level0 > li .cat-img img {
	width: 100%;
}
#nav .level0-wrapper .nav-add .push_item .push_img a {
	height: 95px;
	width: 100%;
}
#nav .level0-wrapper .nav-add .push_item_last {
	width: 23.3%;
}
#nav .level0-wrapper .nav-add .push_item_last img {
	width: 100%;
}
#nav .level0-wrapper, #nav.classic .parent > ul {
	margin: auto;
	padding: 10px 4px 10px 0px;
}
#nav .grid12-8 ul.level0 > li {
	width: 22% !important;
}
.nav-block-center {
	margin-top: 5px;
}
.grid12-5 {
	width: 23%;
}
.grid12-5 img {
	width: 100%;
}
.grid12-3 {
	width: 22%;
}
.grid12-3 img {
	width: 100%;
}
#nav .fl-custom-tabmenulink .header-nav-dropdown-wrapper p {
	font-size: 12px;
}
.grid12-4 {
	width: 30%;
	margin: 5px 20px 0 0;
}
.grid12-4 a img {
	width: 100%;
}
.grid12-8 ul.level0 > li {
	width: 185px !important;
}
.grid12-8 .cat-img img {
	width: 185px !important;
}
.grid13-8 {
	width: 71%;
}
.grid12-8 {
	width: 65%;
}
#nav .grid13-8 ul.level0 > li {
	width: 150px;
}
.slideshow-wrapper .browse-button, .slideshow-wrapper .tab-pager {
	z-index: 5;
}
.cat-img img {
	width: 170px;
}
.fl-custom-tabmenu .grid12-5 img {
	width: 100%;
}
#nav .level0-wrapper .nav-add {
	padding-left: 10px;
}
.mini-cart .basket a {
	min-width: 100% !important;
}
.header-container .right_menu {
	margin-top: 8px;
}
.the-slideshow .slideshow .slide img {
	width: 100%;
}
ul.slideshow {
	height: 330px !important;
}
.slideshow-wrapper .backward {
	width: 25px;
	top: 40%;
}
.slideshow-wrapper .forward {
	top: 40%;
	background: url(../static/img/slideshow-arrows.png) -76px 0 no-repeat;
	width: 25px;
}
.slideshow-wrapper .forward:hover {
	background-position: -76px -66px;
}
.caption.light1 {
	right: 4% !important;
	margin-right: 15% !important;
	top: 8% !important;
	width: 500px !important;
	padding-top: 10px !important;
}
.caption.light1 .heading {
	font-size: 40px;
	margin-top: 0px;
}
.caption .normal-text1 {
	display: none;
}
.caption.light1 .badge {
	margin-top: -190px !important;
}
.caption.top-center {
	padding-top: 4% !important;
	width: 400px !important;
}
.caption .normal-text {
	font-size: 20px !important;
	margin-bottom: 0px !important;
}
.caption .heading {
	font-size: 34px !important;
	margin: 0px !important;
}
.caption p {
	padding: 10px 0px !important;
}
.caption .intro-btn a {
	padding: 10px 20px !important;
}
.caption .intro-btn {
	margin-top: 0px !important;
	padding: 5px 0px !important;
}
.caption.top-left {
	padding-left: 0% !important;
	margin-left: 24% !important;
}
.service-section .services {
	width: auto;
}
.caption.light1 .heading {
	font-size: 62px !important;
}
.offer-inner .left .col-1 img {
	width: 100%;
}
.thmg-slider-producttabs .thmg-slider-pdt-content ul.pdt-list li {
	width: 23%;
	margin-left: 15px;
}
.thmg-slider-pdt-content a.link-compare {
	padding: 6px 8px;
}
.latest-blog {
	padding: 0px
}
.breadcrumbs ul {
	padding: 0px 23px;
}



.cat-img-title.cat-box {
	width: 60%;
	left: 6%;
	top: 5%;
}
.category-description .slider-items-products .owl-buttons a {
	left: 5px;
	padding: 2px 3px 10px 0px;
	height: 40px;
	width: 40px;
}
.category-description .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 20px;
	padding: 0px 13px;
	line-height: 34px;
}
.category-description .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 20px;
	padding: 0px 13px;
	line-height: 34px;
}

.sort-by {display:none}

.toolbar .pager {
	margin: 3px 0px;
	width:70%;
}
.pager .pages li a {
/*	padding: 2px 8px;*/
}
.category-products ul.products-grid li.item {
	width: 33.33%;
}

.col-left .block-cart ul li.item {
	display: inline-block;
}
.col-left .block-cart .block-content ul li .product-details {
	width: 55%;
}
.side-nav-categories {
	margin-bottom: 20px;
}
ol#compare-items li a.product-name {
	width: 75%;
}
#recently-viewed-items .product-name {
	width: 90%;
}
.block-banner img {
	width: 100%;
}

.block {
	margin: 0px 0px 25px 0px;
}
.products-list .product-shop {
	width: 66%;
	margin-left: 15px;
}
.products-list .product-image {
	width: 30%;
}
.products-list .item a img {
	width: 100%;
}
.col2-set .col-1 h2 {
	font-size: 20px;
}
.col2-set .col-2 h2 {
	font-size: 20px;
}

#nav .fl-custom-tabmenulink {
	display: none
}
.navbar-form {
	display: none
}

.blog-side .featured-thumb {
    float: none;
    margin: 0 12px 10px 0;
    position: relative;
	width:100%
}

.blog-side .featured-thumb img {
    width:100%;
    height:auto;
}

.custom-slider .carousel-indicators {display:none}

}

@media (min-width: 1200px) and (max-width: 1500px) {
.top-cate {background: url(../static/img/top-cat-bg.jpg) no-repeat bottom left;}

}

 @media (min-width: 1200px) {
}
 @media (max-width: 767px) {

#header {
    position: relative;
}	 
	 
.page-heading {
	margin-top: 0px;
	padding: 50px 0px
}
.blog-wrapper .entry-date {
	position: relative;
	right: auto;
	top: auto;
}
.page-not-found img {
	max-width: 100%
}
.multiple_addresses .title-buttons {
	text-align: left;
}

.col2-left-layout .main-blog {
    padding: 0px;
	margin-top:30px
}

.blog-side {
	padding: 0px;
}

.comment-form input[type="text"], .comment-form input[type="email"] {
    width: 100%;
}

.comment-form textarea {width:100%}

}

@media (min-width: 1200px) and (max-width: 1249px) {
.container {
    width: 1170px;
}

}
