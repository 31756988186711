/*
Theme Name: Organtic
Author:  ThemesGround
Description:  Organtic HTML Template
Version: 1.4
License: GNU General Public License v2 or later
Tags: two-columns, left-sidebar, fixed-layout, responsive-layout, custom-background, custom-colors, custom-header, custom-menu
Text Domain:  Organtic
*/
/* TABLE OF CONTENTS

1. Body

2. Layout 

3. Header

4. Navigation

5. Features Box

6. Home page Sliders

	6a. Brand Logo Slider

	6b. offer slider

	6c. bestsell slider
 
	6d. featured slider

	6e. special slider

	6f. Other Slider


7. Products Grid 

8. Products List

9. Rating and Price

10. Latest Blog

11. Breadcrumbs

12. Sidebar

	12a. Category boxes
	12b. Block: Account
	12d.  Block: Layered Navigation
	12e. Block: Cart
	12f. Block: Related
	12g. Block: Compare Products
	12h. Block: Recently Viewed
	12i. Block: Recently Compared
	12g. Block: Poll
	12k. Block: Tags
	12l. Block: Subscribe
	12m Block: Recently Viewed
	12n. Block: Reorder 
	12m. Block: Banner

13. custom-slider

14. Product Sorting Toolbar

15. Product View

	15a. Product Images
	15b. Product Shop
	15c.  Product Collatera
	
16. related slider & upsell slider

17. Global Messages 

18. bottom banner

19. footer

20. hot deal

21. Shopping Cart Table

22. Wishlist Table

23. Dashboard

24. checkout pages

25. Compare Table

26. Multiple Addresses Checkout

27. Account Login

28. 404 Page

29. Newsletter popup

30. Data Table



/* */


/*----------- 1. Body --------------*/

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	background:#fff;
	line-height:1.6em
	
	
}
h2, .h2 {
	font-size: 20px;
}
a {
	color: #333;
}
a:hover {
	color: #f65f73;
	text-decoration: none;
}
:hover {
}
small {
	font-size: 85%;
	font-weight: normal;
	text-transform: lowercase;
	color: #999999;
}



*{outline:none}

/* Layout */
.main-col {
	margin: auto;
	overflow: hidden;
}
/* Base Columns */
.col-left {
	margin-bottom: 0px;
}
.col-main {
}
.col-right {
	margin-bottom: 0px;
	padding-right:0px
}
/* 1 Column Layout */
.col1-layout .header-background-default {
	margin: 20px;
	padding: 0;
}
.col1-layout .col-main {
	float: none;
	width: auto;
}
/* 2 Columns Layout */
.col2-left-layout .col-main {
}
.col2-right-layout .col-main {
	padding-left: 0;
}
.col2-right-layout ol.opc .col-md-4 {
	margin-right: 15px;
}
/* 3 Columns Layout */
.product-grid{padding-right:0px; padding-left:10px}
.pro-coloumn{    padding: 0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 30px;
    background-color: #fff;

    }

.col3-layout .col-main {
}
.col3-layout .col-wrapper {
	float: left;
	width: 780px;
}
.col3-layout .col-wrapper .col-main {
	float: right;
}
.main-container {
	padding: 0px;
}

.content {}

/* Header Start */ 
header {
	width: 100%;
	position: relative;
	z-index:1000;



}

#header {background: #ffb637}

.sticky-header {
	 transition: opacity .5s ease, transform .5s ease; transform: translateY(-200%);
    

}

.sticky-header-bar {
    transform: translate(0, 0)!important;
    position: fixed;
    margin-top: 0px;
    z-index: 10000;
    display: block;
    top: 0px;
    background: #ffb637!important;
    width: 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    /* border-top: 10px #333 solid; */
 }

.header-container {position:relative;}

.logo {
    vertical-align: middle;
    cursor: pointer;
    text-align: left;
    display: block;
    float: left;
    margin-right: 35px;
    line-height:83px;
    padding-top: 6px;
}
.header-container .fl-nav-menu {
	display: block;
	border-radius:3px ;

}
.header-container .fl-header-right {
display: flex;
}
.search-bar {
	position: relative;
	width: 0%;
	min-width: 70px;
	height: 75px;
	float: right;
	overflow: hidden;
	transition: width 0.3s;
	display: inline-block;
}
.search-bar-input {
	border: none;
	outline: none;
	background: #fff;
	width: 80%;
	height: 45px;
	margin: 0;
	padding: 0px 52px 0px 20px;
	font-size: 14px;
	color: #bebebe;
	border-bottom: 1px #F3547D solid;
	border-left: 0px #E8E8E8 solid
}
.search-icon, .search-bar-submit {
	width: 70px;
	height: 75px;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0;
	margin: 0;
	line-height: 42px;
	text-align: center;
	cursor: pointer;
	border-left: 1px solid #e5e5e5;
}
.search-bar-submit {
	background: #fff; /* IE needs this */
	opacity: 0;
	color: transparent;
	border: none;
	outline: none;
	z-index: -1;
}
.search-icon:before {
	content: '\f002';
	font-family: FontAwesome;
	font-size: 18px;
	color:#333
}

.navbar-form {padding:0px; margin:0px}

.navbar-collapse {float:right; padding:0px}

.navbar-form .search-btn {
	color: #fff;
	background: none;
	z-index: 90;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	border: none;
	outline: none;
	width: 76px;
	height:98px;
	display: block;
	right: 0;
	top: 0;
	padding: 0;
	margin: 0;
	line-height: 82px;
	text-align: center;
	cursor: pointer;
	border-radius:0;
}
.navbar-form .search-btn .glyphicon {line-height: 45px; vertical-align:middle}
.navbar-form .search-btn .glyphicon-search:before {
    font-size: 16px;
	color:#fff;
	vertical-align:middle
}

.navbar-collapse form[role="search"] input {
    font-size: 18pt;
    opacity: 0;
    display: none;
    height: 48px;
	position: relative;
    z-index: 2;
    float: left;
	outline:none
}

.navbar-collapse form[role="search"].active input {display: table-cell;opacity: 1;z-index: 100;border-radius: 0;border: none;line-height: 45px;height: 58px;font-size: 14px;padding: 0px 25px;width: 380px;position: absolute;right: 75px;outline:none;box-shadow:none;border-left:1px #e5e5e5 solid;top: 20px;}



/* Open state */
.search-bar.search-bar-open, .no-js .search-bar {
	width: 870px;
	z-index: 100;
	background-color: #fff;
}
.search-bar.search-bar-open .search-icon, .no-js .search-bar .search-icon {
	z-index: 11;
}
.search-bar.search-bar-open .search-bar-submit, .no-js .search-bar .sb-search-submit {
	z-index: 90;
}
.search-icon {
	position: absolute;
	right: 0px;
	margin-top: 0px;
}
.search-box {
	width: 220px;
	position: absolute;
	margin: auto;
	right: 2px;
	margin-top: 0px
}
#search {
	border: medium none;
	color: #666;
	font-family: Lato, sans-serif;
	font-size: 13px;
	height: 75px;
	margin: 0;
	outline: medium none;
	padding: 0 10px;
	width: 400px;
	background-color: #fff;
}
.button-common {
	border: 0 none;
	overflow: hidden;
	padding: 0px;
	text-indent: -9999px;
	width: 25px;
}
.search-btn-bg {
	cursor: pointer;
	display: inline-block;
	height: 56px;
	min-width: 20px;
	position: absolute;
	right: 0px;
	top: 0;
	width: 56px;
	right: 0px;
	background: #000;
	outline: none;
}
.searchIconNew {
	background: url("../static/img/search-icon.png") no-repeat scroll center center;
	width: 45px;
	height: 45px;
	left: 7px;
	display: inline;
	position: absolute;
	top: 5px;
}
.cross-icon {
	position: absolute;
	right: 0px;
	margin-top: -35px
}
/* ========= End search  ==========*/

.glyphicon-search:before {
	font-size: 18px;
}
.welcome-msg {
	text-align: left;
	display: inline-block;
	padding: 0px;
	margin: 4px 5px;
	font-size: 13px;
	color: #999;
}
/* language &amp; currency */
/*.menu_top{ display:table;}*/

.language-currency-div {
	overflow: hidden;
	background: #f8f8f8;
	padding: 5px 15px
}
.language-currency {
	overflow: hidden;
	padding:8px 0px 12px 0px;
}
.fl-language {
	display: inline;
	float: right;
	line-height: 17px;
	padding-left: 5px;
}
ul.lang {
	text-align: left;
	display: block;
	margin: 0;
	padding: 0x;
	list-style: none;
	float: left;
	padding: 0px;
}
ul.lang li {
	float: left;
	display: inline;
	margin: 0px 0px 0 5px
}

ul.lang li a {background:#f2f2f2; height:30px; width:30px; line-height:30px; display:block; text-align:center; border-radius:999px}

ul.lang li span {
	text-indent: -10000px;
	display: none
}
.fl-currency {
	display: inline;
	float: left;
	line-height: 17px
}
ul.currencies_list {
	float: left;
	display: inline;
	margin: 0px 8px 0 0px;
	padding: 0px;
}
ul.currencies_list li {
	float: left;
	display: inline;
	margin: 0 5px 0 0px
}
ul.currencies_list li a {
	color: #a3d659;
	font-size: 14px;
	font-weight: 600;
    width: 30px;
    display: block;
    height: 30px;
    line-height: 30px;
    text-align: center;
	background:#131e2c;
	border-radius:999px
}
ul.currencies_list li a:hover {
	color: #fff;
	text-decoration: none;
}
/* End language &amp; currency*/

/*  Toplink =*/
.toplinks {
	line-height: normal;
	padding: 0px;
	display: inline-block;
	text-align: right;
	margin-top: 18px;
}
.toplinks .links {
	text-align: right;
	display: inline-block;
}
.toplinks .links div {
	display: inline-block;
	text-align: right;
}
.toplinks div.links div a {
	display: inline-block;
	padding: 0px;
	color: #333;
	font-size: 13px;
	padding: 0px 0px 0px 15px;
	text-transform: none;
	font-family: 'Open Sans', sans-serif;
	text-decoration: none;
}
.toplinks div.links div a:hover {
	color: #f65f73;
	text-decoration: none;
}
.block-company {
	background: none;
	box-shadow: none;
	border: none;
	color: #aaaaaa;
	font-size: 14px;
}
.block-company .caret {
	margin-left: 5px;
}
.block-company-wrapper {
	display: inline-block;
}
.block-company label {
	font-weight: normal;
	padding: 5px 5px 2px 0px;
}
.block-company-wrapper .dropdown-menu {
	min-width: 0;
	border-top: 2px solid #000;
	box-shadow: 0 3px 3px 0 #888888;
}
.block-company-wrapper .dropdown-menu li {
	padding: 5px 15px 5px 0;
}
.block-company-wrapper .dropdown-menu li:hover {
	background-color: #eaeaea;
	color: #000;
	transition: background-color 0.2s ease-in-out;
}
.block-company-wrapper .dropdown-menu a {
	padding: 3px 10px 3px 10px;
}
.block-company-wrapper .dropdown-menu a:hover {
	color: #000;
	background: none;
}
/* Top Shopping Cart */
.mini-products-list .product-image {
	float: left;
	padding: 0;
	width: 60px;
}
.mini-products-list .product-image img {
	width: 60px;
}
.fl-mini-cart-content .product-name {
	margin: 0;
	width: 190px;
	line-height: 1
}
.fl-mini-cart-content .product-name a {
	font-size: 13px;
	line-height: normal;
	font-weight: normal;
}
.fl-mini-cart-content .product-name a:hover {
	font-size: 13px;
	color: #f65f73
}
.fl-cart-contain .price {
	color: #f65f73;
	font-weight: 400;
	text-transform: uppercase;
	padding-left: 10px;
	font-size: 18px;
	float:right
}
.fl-cart-contain .product-details .price {
	color: #f65f73;
	width: auto;
	float: none;
	font-weight: 400;
	padding-left: 0px;
	font-size:13px
}

.fl-cart-contain .product-details strong {font-weight:400}

.mini-cart {
	text-align: center;
	cursor: pointer;
	margin: 0px;
	display: block;
	/* border-left:1px #e5e5e5 solid */
}
.mini-cart .basket a {
	color: #333;
	margin: 0px;
	line-height: 17px;
	font-size: 13px;
	padding: 0px 25px;
	font-family: 'Open Sans', sans-serif;
	letter-spacing: 0.5px;
	font-weight: normal;
	display: block;
	line-height: 100px;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #202020;
}
.fl-cart-contain > .mini-cart:hover > .basket a {
	color: #fff;
}
.mini-cart .basket a span {
    background: #f65f73;
    display: inline-block;
    color: #fff;
    width: 25px;
    border-radius: 999px;
    font-size: 12px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    vertical-align: top;
    margin-top: 28px;
    position: absolute;
    font-weight: 700;
    right: 20px;
}
.fl-mini-cart-content .pull-right {
	margin-right: 10px;
	padding-top: 3px
}
.fl-mini-cart-content .basket a {
	border: none;
	color: #f65f73
}
.mini-cart .basket a:hover {
	text-decoration: none;
}
.mini-cart .basket a:first-child:before {
	content: '\f291';
	font-family: FontAwesome;
	font-size: 20px;
	padding-right: 5px;
	color:#fff

}
.mini-cart .open .basket a {
	color: #000;
	background: #fff;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.3)
}
.mini-cart .dropdown-toggle {
	font-size: 14px;
	padding: 4px 0 6px;
	text-transform: none;
	padding-bottom: 20px;
}
.fl-cart-contain {
	position: relative;
	float: right;
	
}
.top-cart-title p {
	margin: 0;
}
.fl-mini-cart-content .empty {
	padding: 10px;
	color: #333333;
}
.mini-cart .fl-mini-cart-content {
	background: #ffffff;
	background-repeat: repeat;
	background-position: 0 0;
	padding: 0px;
	margin: 0;
	overflow: hidden;
	font-size: 12px;
	line-height: 25px;
	z-index: 10000;
	border-top: 0;
	display: none;
	position: absolute;
	right: 0px;
	opacity: 1;
	visibility: visible;
	transition: all 0.3s ease-in-out 0s;
	-webkit-transform: scale(1.0) rotate(0deg);
	transform: scale(1.0) rotate(0deg);
	box-shadow: 0 10px 20px -5px;
	font-family: 'Rubik', sans-serif;
	border-radius:0px 0px 10px 10px

}
.top-subtotal {
	color: #131e2c;
	font-size: 14px;
	font-weight: 500;
	padding: 15px 12px;
	text-align: left;
}
.fl-mini-cart-content .product-details .btn-remove {
	position: absolute;
	right: 10px;
	top: 4px;
}
.fl-mini-cart-content .block-subtitle {
	color: #fff;
	overflow: hidden;
	padding: 0px 5px;
	text-align: left;


}
.fl-mini-cart-content .block-subtitle .price {
	color: #131e2c;
}
ul#cart-sidebar {
	padding: 10px 15px 0px;
}

.col-left .block-cart .block-content ul li .product-details .btn-edit {padding: 0px 0px; border:none; display:block; text-align:center}

.block-cart ul {
	padding: 0px;
}


/* top cart  */
.fl-mini-cart-content li.item {
	width: 310px;
	margin: 5px 0px 10px;
	border-bottom: 1px #eee solid;
	display: inline-block;
	padding-bottom: 10px;
}
.fl-mini-cart-content li.item.last {
	margin: 0;
	border-bottom: 0px #eee solid;
	padding-bottom: 10px
}
.fl-mini-cart-content .product-details {
	color: #333333;
	position: relative;
	text-align: left;
}
.mini-products-list .product-details {
	margin-left: 80px;
	padding: 0;
}
.fl-mini-cart-content .product-details .btn-remove {
	position: absolute;
	right: 10px;
	top: 12px;
}
.fl-mini-cart-content .product-details .btn-edit {
	position: absolute;
	right: 30px;
	top: -4px;
	border:none;
	padding:0px
}
.fl-mini-cart-content .product-details .btn-edit:hover {
	color: #f65f73;
}
.fl-mini-cart-content .actions {
	padding: 20px;
	margin: 0px 0 0;
	overflow: hidden
}
.button.btn-checkout {
	background: #FED557;
	border: 1px solid #F5C328;
	color: #003169;
	padding: 8px 18px;
}
.button.btn-checkout:hover {
	background: #FED557
}
.button:btn-bag:hover {
background:#f6f6f6
}
.mini-cart .view-cart {
	background: none repeat scroll 0 0 transparent;
	border: medium none;
	cursor: pointer;
	float: none;
	font-size: 13px;
	font-weight: bold;
	padding: 10px 25px;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	display: inline-block;
	background: #eee;
	color: #666;
}
.mini-cart .view-cart:before {
	content: '\f07a';
	font-family: FontAwesome;
	font-size: 16px;
	padding-right: 7px;
}
.btn-remove1 {
	background-image: url(../static/img/btn_remove.png);
	margin: 0px 0 0 5px;
}
.btn-remove1:hover, .btn-remove1:focus {
	background-position: 0 -12px;
}
.btn-remove1 {
	display: block;
	width: 15px;
	height: 13px;
	font-size: 0;
	line-height: 0;
	background-position: 0 0;
	background-repeat: no-repeat;
	text-indent: -999em;
	overflow: hidden;
	position: absolute;
	left: 28px;

	margin-top: 5px;
}
.block-compare .btn-remove1 {
	left: auto;
	right: 0px;
	margin-top: 0px;
	top: 5px
}
.mini-cart .btn-remove1 {
	left: auto;
	right: 0px;
	margin-top: 0px;
	top: 2px;
}
.mini-cart .actions .btn-checkout {
	background: #97bc77;
	color: #fff;
	font-size: 14px;
	text-shadow: none;
	padding: 10px 0px;
	margin-top: 0px;
	font-weight: 500;
	border-radius: 999px;
	display: block;
	width: 100%;
	font-family:'Rubik', sans-serif;
	text-transform:uppercase;
	border:none;
	letter-spacing:0.5px
}
.mini-cart .actions .btn-checkout:hover {
	box-shadow:none;
	border:none;
	color: #fff;
	background:#131e2c
}
.mini-cart .actions .btn-checkout:before {
	content: "\f00c";
	font-family: FontAwesome;
	font-size: 18px;
	padding-right: 10px;
}
/* Nav */

nav {
	position: relative;
	margin: auto;
	width: 100%;
	z-index: 99;
}
/*.sticky {
position: fixed;
z-index: 10000;
width: 100%;
top: 0px;
background-color: #fff;
}*/
.nav-inner {
	position: relative;
}
#nav > li > a.active:nth-child(1) {
	color: #97bc77;

}
#nav > li > a:hover.active:nth-child(1) {
	color: #fff;
}
#nav > li > a:first-child {
	margin-left: 0px;
}
#nav > li > a:last-child a {
	color: #333
}
.nav {
	width: 1000px;
	margin: 0px auto 0
}
#nav > li:hover > a, .vertnav-top li > a:hover {
	color: #fff;
	border-bottom: none;

}
.cms-index-index #nav #nav-home > a, #nav > li.active > a, .vertnav-top li.current > a {
	color: #97bc77;
}
.cms-index-index #nav #nav-home > a, #nav > li.active > a, .vertnav-top li.current > a:hover {
	color: #fff;
	padding: 0px 0px;
}


#nav > li.active > a span:after {
	position: absolute;
    height: 4px;
    background: #f65f73;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;}
#nav > li.active > a:hover {background:none; box-shadow:none;}
#nav > li.active > a:hover span{}

#nav > li:first-child a span {
}
.cms-index-index nav {
	border-bottom: none
}
#nav {
	position: relative;
	z-index: 10;
	-webkit-transform: translateZ(0);
	padding: 0;
	margin: auto;
	text-align: center
}
/* All levels */
#nav li {
	text-align: left;
}
#nav li a {
	display: block;
	text-decoration: none;
}
#nav ul.level1 > li a {
	color: #7a7a7a
}
#nav ul.level1 > li a:hover {
	color: #97bc77
}
#nav li a span {
}
#nav li ul a span {
}
/* 1st level only */
#nav > li {
	list-style: none;
	border-bottom: none;
	display: block;
	float: left
}
#nav > li.last {
	padding-right: 0;
}
#nav > li > a {
	display: block;
	padding: 0px 0px;
	line-height:38px;
}
#nav > li > a > span {
	display: inline-block;
	padding: 0 15px;
	line-height: 100px;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
	#nav > li > a > span {
		display: inline-block;
		padding: 0 15px;
		line-height: 0px;
	}
	
}

#nav > li > a:hover > span:after {
	position: absolute;
    height: 4px;
    background: #f65f73;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;}
/* Drop-down boxes:
wide:    2nd-level category drop-down box + custom block drop-down box
classic: custom block drop-down box */
#nav .level0-wrapper {
	position: absolute;
	left: -10000px;

}


#nav .level0-wrapper .container {
	padding:0px
}



/* Inner wrapper for additional padding */
#nav .level0-wrapper2 {
	padding: 20px 10px 10px 20px;
	background: #fff;
	border-top: none;
	box-shadow: 0 10px 20px -15px;
	border-radius: 0px 0px 10px 10px;
}
#nav .level0-wrapper2:before, #nav .level0-wrapper2:after {
	display: table;
	line-height: 0;
	content: "";
}
#nav .level0-wrapper2:after {
	clear: both;
}
/* Home link*/

#nav a.level-top.feature {
	margin: 0;
}
/* Icon inside item */
#nav li a.level-top .icon {
	padding: 3px;
	margin: 0;
	float: none;
	display: inline-block;
	vertical-align: middle;
	background-color: transparent !important;
}
#nav li:hover a.level-top .icon {
	padding: 3px;
}
/* Single icon as home link */
#nav #homelink-icon {
	margin-left: 12px;
}
#nav #homelink-icon a.level-top {
	padding-left: 5px;
	padding-right: 5px;
	background: none !important;
	box-shadow: none !important;
	border: none !important;
}
/* Custom menu item */
#nav .fl-custom-tabmenulink > a {
	cursor: default;
}
/* Remove 'relative' positioning from custom 'li' (custom block have to be positioned relative to #nav) */
#nav .fl-custom-tabmenulink {
	position: static;
}


/* Static block's content - basic formatting */
#nav .fl-custom-tabmenulink .header-nav-dropdown-wrapper {
	padding: 15px 10px 10px 0px;
	overflow: hidden;
		background: #fff!important;
		border-radius: 0px 0px 10px 10px;
}
#nav .fl-custom-tabmenulink .header-nav-dropdown-wrapper p {
	margin: 10px 0px 30px 0px;
	color: #999;
	line-height: 1.5em;
	font-size: 14px;
	font-weight: normal
}
#nav .fl-custom-tabmenulink .header-nav-dropdown-wrapper .heading {
	margin: 10px 0;
}
/* Caret */
#nav a.level-top .caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-bottom: 2px;
	margin-left: 2px;
	vertical-align: middle;
	content: "";
	border-style: solid solid none;
	border-width: 4px 4px 0;
	border-color: #333 transparent transparent;
}
/* Number of products in category */
/* Sidebar menu */
.vertnav .number {
	margin-left: 3px;
	color: #aaa;
}
/* Main menu */
#nav .number {
	display: inline;
	margin-left: 3px;
}
#nav ul li .number {
	color: #aaa;
}
/* Other */
.nav .itemslider {
	margin-top: 0;
	margin-bottom: 0;
}
.nav .itemslider .item {
	padding-bottom: 0;
}
/* 1st level */
li.level0 > a > span {
	position: relative;
}
li.level0 > a > span > .cat-label {
	position: absolute;
	top: -12px;
	right: 0;
	padding: 0 6px;
	font-size: 14px;
	line-height: 22px;

}
/* Add pin (only 1st level) */
li.level0 > a > span > .pin-bottom:before {
	position: absolute;
	right: 6px;
	top: 22px;
	width: 0;
	height: 0;
	content: "";
	border-style: solid;
	border-width: 4px;
	border-color: transparent;
	transition: border-color 450ms ease-in-out;
	-moz-transition: border-color 450ms ease-in-out;
	-webkit-transition: border-color 450ms ease-in-out;
	-o-transition: border-color 450ms ease-in-out;
}
li.level0 > a:hover > span > .pin-bottom:before {
	transition-duration: 100ms;
	-moz-transition-duration: 100ms;
	-webkit-transition-duration: 100ms;
	-o-transition-duration: 100ms;
}
/* 2nd level */
li.level1 > a > span > .cat-label {
	display: inline-block !important;
	padding: 0 4px;
	margin-left: 2px;
	vertical-align: middle;
}
/* 3rd level */
li.level2 > a > span > .cat-label {
	display: inline-block !important;
	padding: 0 4px;
	margin-left: 2px;
}
/* Category labels inside mobile menu / vertical menu */
ul.vertnav li > a > span > .cat-label {
	position: static;
	display: inline-block !important;
	padding: 0 4px;
	margin-left: 2px;
}
/* Hide pin */
ul.vertnav li > a > span > .pin-bottom:before {
	display: none;
}
/* Label size: all levels */
ul.vertnav li > a > span > .cat-label {
	font-size: inherit;
	line-height: inherit;
}
ul.vertnav-top li > a > span > .cat-label {
	font-size: 14px;
	line-height: 18px;
}
/* Category blocks */
.nav-block {
	margin-top: 0;

}
.nav-block-top {
}
.nav-block-bottom {
	float: left
}
.nav-block-center {
	margin-top: 0px;
}
.nav-block-right {
	margin-top: 0px;
	float: right;
	padding: 0px;
	text-align: center;
}
.nav-block-right img {
	width: 100%
}
.nav-block-level1-top {
	margin-bottom: 10px;
}
.nav-block-level1-bottom {
	margin-top: 10px;
}
.nav-block .section-line {
	border-color: #eee;
}
/* horizontal + mobile menu trigger */
#nav > li > a {
	font-size: 14px;
	text-transform: uppercase /*1.3333em*/;
	font-family: 'Rubik', sans-serif;
	font-weight: 500;
	position: relative;
	letter-spacing: 1px;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff;
}
.icon-headers{
	padding:0px 0px 0px 25px;
	line-height:100px;
	height:90px;
	display:block;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
	#nav > li > a {
		font-size: 8px;
	}
}

ul.level0 {
	padding: 0
}
ul.level1 {
	padding: 0px;
	padding-left:10px
}
#nav .level0-wrapper, #nav.classic ul {
	color: #333333;
}
#nav ul li a:hover {
	color: #f65f73;
}
.cat-label-label1 {
	background-color: #D12631;
	color: #ffffff;
}
.cat-label-label2 {
	background-color: #d52462;
	color: #ffffff;
}
#nav .level0-wrapper, #nav.classic .parent > ul {
	width: 100%;
	padding: 0px;
}
#nav .level0-wrapper, #nav.classic ul.level0 {
	top:120px;
}
#nav > li > a {
	color: #fff;
}
#nav > li.mega-menu {
	position: static;
}
.nav-inner #nav li.mega-menu .level0-wrapper {
	border: 0 none;
	padding: 0px 0px;
	z-index: 10000;
	transform: scale(0) rotate(0deg);
	transition: all 0.4s ease-in-out 0s;
	opacity: 0;
	visibility: hidden;
	display: block!important;
	left:0
}
.nav-inner #nav li.mega-menu:hover .level0-wrapper {
	opacity: 1;
	visibility: visible;
	transition: all 0.4s ease-in-out 0s;
	-webkit-transform: scale(1.0) rotate(0deg);
	transform: scale(1.0) rotate(0deg);
}
#nav .level0-wrapper2 .col-1 {
	float: left;
	width: 72%;
}
#nav .level0-wrapper2 .col-2 {
	padding: 0;
	float: left;
	width: 26%;
}
#nav .level0-wrapper2 .col-3 {
	padding: 5px 0;
	float: left;
	width: 20%;
}
#nav .col-1 ul.level0 > li {
	width: 30%;
}
.col-2 img:hover {
	opacity: 0.8;
}
.col-2 .menu_image {
	display: block;
	margin-top: 10px;
}
.col-2 img {
	max-width: 100%
}
.col-2 .menu_image1 {
	display: block;
	margin-top: 15px;
}
/* 1st level only */
#nav > li:hover > a {
	position: relative;
	z-index: 1;
}
/* 2nd level only */
#nav ul.level0 > li {
	display: inline-block;
	float: none;
	margin-right: 20px;
	padding-bottom: 30px;
	padding-top: 0;
	vertical-align: top;
	width: 14.4%;
}
#nav ul.level0 > li.last {
	display: inline-block;
	float: none;
	vertical-align: top;
	padding-top: 0;
	padding-bottom: 15px;
	margin-right: 0px
}
#nav ul.level0 > li > a {
    color: #131e2c;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding: 5px 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
	font-family:'Rubik', sans-serif

}
/* 2nd level+ */
#nav ul li {
	list-style: none;
	padding: 3px 0;
}
#nav li ul li ul li {

}
#nav li ul li ul li a {display:inline-block}

#nav li ul li ul li:last-child {
	border: none
}
/* 3rd level only */
#nav ul.level1 {
	position: static;
}
#nav ul.level1 > li a {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	font-size: 13px;
	letter-spacing: normal;
}
#nav ul.level1 > li a:hover {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	padding-left: 10px;
}
/* 4th level */
#nav ul.level2 {
	margin: 7px 0;
	padding: 0;
	position: static;
}
#nav ul.level2 a {
	font-size: 0.9166em;
	line-height: 1.2727em;
	opacity: 0.6;
}
#nav li.level2:hover ul.level2 a {
	opacity: 1;
	transition: opacity 150ms ease-out;
	-moz-transition: opacity 150ms ease-out;
	-webkit-transition: opacity 150ms ease-out;
	-o-transition: opacity 150ms ease-out;
}
/* 4rd level+ */
#nav ul ul ul li {
	background: none;
	padding-left: 0;
}
/* Vertical nav-links */

.nav-links > li, .nav-links > li > a {
	line-height: 3em;
}
.nav-links > li > a {
	padding: 0 0.5833em;
}
.nav-links > li.label {
	padding-right: 0.5833em;
}
/* Basic nav-links, no separators */
.nav-links > li {
	float: left;
}
.nav-links > li > a {
	display: block;
}
.grid12-5 {
	display: inline;
	float: left;
	margin-left: 1%;
	margin-right: 1%;
	width: 23%;
}
.grid12-3 {
	display: inline;
	float: left;
	margin-left: 1.5%;
	margin-right: 1.5%;
	width: 22%;
	text-align: center
}
.grid12-3 img {
	max-width: 100%;

	
}
.grid12-3 .item-title a {
	text-transform: uppercase;
	margin-top: 10px;
	color: #aaa
}
.grid12-4 {
	display: inline;
	float: left;
	margin: 5px 0 0;
	width: 34%;
}
.grid12-8 {
	display: inline;
	float: left;
	margin-left: 0;
	margin-right: 1%;
	width: 64%;
}
#nav .grid12-8 ul.level0 > li {
	width: 21%;
}
.grid13-8 {
	display: inline;
	float: left;
	margin-left: 0;
	margin-right: 1%;
	margin-bottom: 0;
	width: 80.90%;
}
.back-link {
	float: left
}
#nav .level0-wrapper .nav-add {
	padding: 0px;
	overflow: hidden;
	padding-bottom: 15px
}
#nav .level0-wrapper .nav-add .push_item {
	float: left;
	margin-right: 18px;
	width: 23.6%;
}
#nav .level0-wrapper .nav-add .push_item img {
	max-width: 100%;
}
#nav .level0-wrapper .nav-add .push_item_last {
	float: left;
	margin-right: 0px;
	width: 23.6%;
}
#nav .level0-wrapper .nav-add .push_item .push_img a {
	display: block;
}
#nav .level0-wrapper .nav-add .push_item .push_text {
	color: #666;
	font-size: 12px;
	padding: 8px 0;
	text-align: center
}
.fl-custom-tabmenu {
	color: #999
}
.fl-custom-tabmenu { padding-top:0px!important; top:120px!important; box-shadow: 0 10px 20px -15px;}


.icon-star:before {
	content: "\f0a3";
}
.icon-star {
	font-family: FontAwesome;
	display: inline-block !important;
	font-size: 28px;
	color: #f65f73;
	height: 60px;
	width: 60px;
	line-height: 60px!important;
	text-align: center;
	border: 1px #eee solid;
	border-radius: 50px;
	text-align: center
}
.heart-icon:before {
	content: "\f06b";
	font-family: FontAwesome;
}
.heart-icon {
	font-family: FontAwesome;
	display: inline-block !important;
	font-size: 28px;
	color: #f65f73;
	height: 60px;
	width: 60px;
	line-height: 60px!important;
	text-align: center;
	border: 1px #eee solid;
	border-radius: 50px;
	text-align: center;
	padding-left: 6px;
}
.custom-icon:before {
	content: "\f0e9";
}
.custom-icon {
	font-family: FontAwesome;
	display: inline-block !important;
	font-size: 28px;
	color: #f65f73;
	height: 60px;
	width: 60px;
	line-height: 60px!important;
	text-align: center;
	border: 1px #eee solid;
	border-radius: 50px;
	text-align: center
}
.icon-custom-grid:before {
	content: "\f00a";
}
.icon-custom-grid {
	font-family: FontAwesome;
	display: inline-block !important;
	font-size: 28px;
	color: #f65f73;
	height: 60px;
	width: 60px;
	line-height: 60px!important;
	text-align: center;
	border: 1px #eee solid;
	border-radius: 50px;
	text-align: center
}
#nav .fl-custom-tabmenulink .header-nav-dropdown-wrapper h4.heading {
	color: #000;
	font-size: 12px;
	text-transform: uppercase;
	margin-top: 0px;
	margin-bottom: 10px;
}
#nav .fl-custom-tabmenulink .header-nav-dropdown-wrapper h4.heading {
	color: #383838;
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 10px;
	letter-spacing: 0.5px;
	margin-top:20px;
	font-family:'Oswald', sans-serif;
}
#nav li.drop-menu ul {
	position: absolute;
	top: 75px;
	left: 0;
	background: #fff;
	padding: 6px 0;
	border-top: none;
	box-shadow: 0 0 20px -5px;
	width: 165px;
	text-align: left;
	z-index: 1000;
	opacity: 0;
	transform: scale(0) rotate(0deg);
	transition: all 0.3s ease-in-out 0s;
	visibility: hidden
}
#nav li.drop-menu:hover ul {
	position: absolute;
	top: 120px;
	background: #fff;
	padding: 6px 0;
	border-top: none;
	box-shadow: 0 10px 20px -15px;
	width: 165px;
	text-align: left;
	z-index: 1000;
	display: block;
	opacity: 1;
	-webkit-transform: scale(1.0) rotate(0deg);
	transform: scale(1.0) rotate(0deg);
	visibility: visible;
	border-radius: 0px 0px 10px 10px;

}
#nav > li.drop-menu ul li ul {
	border-top: none;
	opacity:0;
	z-index:0

	
}

#nav li.drop-menu:hover ul li ul {opacity:0;}
#nav li.drop-menu:hover ul li:hover ul {opacity:1; margin:0px}

#nav > li.drop-menu {
	position: relative;
}
#nav > li.drop-menu a {
	
}
#nav li.drop-menu ul li {
	position: relative;
}
#nav li.drop-menu ul li a {
	color: #333;
	line-height: 24px;
	font-size: 13px;
	padding: 0 12px;
	display: block;
	transition: all 200ms ease-in-out;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
}
#nav li.drop-menu ul li:hover > a {
	color: #f65f73;
	padding-left: 18px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
#nav li.drop-menu ul li span.plus {
	display: block;
	position: absolute;
	right: 7px;
	top: 50%;
	margin-top: -1px;
	height: 1px;
	width: 7px;
	background: #535353;
}
#nav li.drop-menu ul {
	display: none;
	opacity:0
}
.sticky {
	position: fixed;
	margin-top: 0px;
	z-index: 500;
	display: block;
	top: 0px;
	height: 51px;
	background: #141414;
	width: 100%;
}
/* End Nav */


/* store messages Start */

.service-section {
	background: #fff;
	border-bottom: 1px #e5e5e5 solid;
}
.service-section .services {
	padding: 5px 0px 10px 0px;
}
.service-section .services span {
	font-size: 13px;
	line-height: 35px;
	transition: color 100ms ease 0s;
	color: #333;
	text-align: center;
	display: inline-block;
	margin: 0px 2px 0px 2px;
	font-weight: normal;
}
.service-section .last {
	margin-right: 0px!important;
	border-right: none!important;
	float: right;
}
.service-section .services.last span {
	margin: 0px 2px 0px 0px;
}
.offer-banner-section {
	overflow: hidden;
	width: 100%;
}
.service-section1 {
	margin-top: 15px;
}
.service-section1 span {
	color: #666!important;
}
.service-section1 span {
	color: #666!important;
}

/* Promotion banners */
#top {
	margin: 0px auto 0;
	position: relative;
	overflow:hidden;
	margin-bottom: 15px;
	background: #f7f8fa;
	padding-top:65px;
	padding-bottom:75px
}

#top .container { padding:0px}

#top ul {
  width: 100%;
  padding:0px;
  margin:0px;

}

#top ul li {
  float: left;
  width:50%;
  display:block
}

#top img {width:100%}

#top img {
	width: 100%;
}
#top > div a {
	display: block;
	overflow: hidden;
}
#top > div a img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
	border-radius:15px;
}
#top > div a:hover img {
opacity:0.9
}

#top ul li:nth-child(2) {

}

#top ul li:nth-child(3) {

}

#top ul li:nth-child(4) {

  clear: both;
}

#top ul li:nth-child(5) {

}

#top ul li:nth-child(6) {

}

/* Promotion banners end*/


/* New & Sale Label */

.new-box, .sale-box {
	height: 40px;
	overflow: hidden;
	position: absolute;
	text-align: center;
	top: -1px;
	width: 55px;
	z-index: 10;
}
.new-box {
	left: -1px;
}
.sale-box {
	right: -1px;
}
.new-top-left {
	left: 0px;
	top: 0;
	background: #fff;
	letter-spacing: 1px;
}
.new-top-right {
	background: none repeat scroll 0 0 #FFFFFF;
	right: 0px;
	top: 0;
}
.sale-top-right {
	top: 0;
	background: #fff;
	letter-spacing: 1px;
	right: 0px;
}
.sale-top-left {
	font-size: 11px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	background: #dd4b39;
	text-transform: uppercase;
	padding: 3px 0 4px;
	text-align: center;
	display: block;
	position: absolute;
	right: -33px;
	top: 9px;
}
.ie8 .new-label {
	left: 0px;
	top: 0px;
	width: auto;
	padding: 5px 15px;
}
.ie8 .new-label:after {
	display: none;
}
.ie8 .new-label:before {
	display: none;
}
.ie8 .sale-label {
	right: 0px;
	top: 0px;
	width: auto;
	padding: 5px 15px;
}
.ie8 .sale-label:after {
	display: none;
}
.ie8 .sale-label:before {
	display: none;
}
.ie8 .new-box {
	top: -1px;
	left: -1px;
}
.ie8 .sale-box {
	right: -1px;
	top: -1px;
}
.new-box, .sale-box {
	position: fixed;
	text-align: center;
	top: -1px;
	width: 55px;
	z-index: 10;
}
.new-box {
	left: 0;
}
.sale-box {
	right: 0;
	top: 0px;
}
.new-label {
	font-size: 11px;
	font-family:'Rubik', sans-serif;
	color: #fff;
	background: #dd4b59;
	text-transform: uppercase;
	padding: 0px 10px;
	text-align: center;
	display: block;
	position: absolute;
	left: 10px;
	top: 10px;
	font-weight: 400;
	height:24px;
	border-radius:3px;
	line-height:26px;
	z-index:10
}
.sale-label {
	font-size: 11px;
	font-family:'Rubik', sans-serif;
	color: #fff;
	background: #97bc77;
	text-transform: uppercase;
	padding: 0px 10px;
	text-align: center;
	display: block;
	position: absolute;
	right:10px;
	top:10px;
	font-weight: 400;
	height:24px;
	border-radius:3px;
	line-height:26px;
	z-index:10;
	letter-spacing:0.5px
}
.ie8 .new-label {
	left: 0px;
	top: 0px;
	width: auto;
	padding: 5px 15px;
}
.ie8 .new-label:after {
	display: none;
}
.ie8 .new-label:before {
	display: none;
}
.ie8 .sale-label {
	right: 0px;
	top: 0px;
	width: auto;
	padding: 5px 15px;
}
.ie8 .sale-label:after {
	display: none;
}
.ie8 .sale-label:before {
	display: none;
}
.ie8 .new-box {
	top: -1px;
	left: -1px;
}
.ie8 .sale-box {
	right: -1px;
	top: -1px;
}
.product-view #product-img-box .new-box, .product-view #product-img-box .sale-box {
	position: absolute;
	text-align: center;
	top: -1px;
	width: 55px;
	z-index: 10;
}
.product-view #product-img-box .new-box {
	left: 160px;
}
.product-view #product-img-box .sale-box {
	right: -5px;
	top: 0px;
}
.product-view #product-img-box .new-label.new-top-left {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	color: #000;
	background: #41bea6;
	text-transform: uppercase;
	padding: 10px 25px;
	text-align: center;
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 100;
	font-weight: normal;
}
.product-view #product-img-box .new-label.new-top-right {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	color: #000;
	background: #41bea6;
	text-transform: uppercase;
	padding: 10px 25px;
	text-align: center;
	display: block;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 100;
	font-weight: normal;
}
.product-view #product-img-box .sale-label.sale-top-left {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	background: #dd4b39;
	text-transform: uppercase;
	padding: 10px 25px;
	text-align: center;
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 100;
	font-weight: normal;
}
.product-view #product-img-box .sale-label.sale-top-right {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	background: #dd4b39;
	text-transform: uppercase;
	padding: 10px 25px;
	text-align: center;
	display: block;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 100;
	font-weight: normal;
}
/*  Main Col */  
/*--- products-grid-home ---*/
.products-grid-home {
	margin: 0;
	list-style: none;
	padding: 0;
}
.products-grid-home .item.first {
	margin-left: 0;
}
.products-grid-home .item {
	float: left;
	background: #FFF;
	margin-top: 20px;
}
.products-grid-home .item.show-addtocart {
	margin-top: 37px;
}
.products-grid-home .item .item-inner {
	position: relative;
	font-family: 'Montserrat', sans-serif;
}
.products-grid-home .item .item-inner .actions {
	display: inline-block
}
.products-grid-home .item .item-inner .item-img {
	position: relative;
	border: 1px #eaeaea solid;
}
.products-grid-home .item .item-inner .item-img .ico-product {
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 2;
	background: #de44c3;
	padding: 4px 20px 8px;
	color: #FFF;
	font-size: 150%;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	border-radius: 18px;
}
.products-grid-home .item .item-inner .item-img .ico-product strong {
	font-weight: 600;
}
.products-grid-home .item .item-inner .item-img .ico-product.ico-sale {
	background: #7ebf38;
	right: 10px;
	left: auto;
}
.products-grid-home .item .item-inner .item-img .item-img-info {
	text-align: center;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	position: relative;
	overflow: hidden;
}
.products-grid-home .item .item-inner .item-img .item-img-info a.product-image {
	display: inline-block;
	width: 100%;
}
.products-grid-home .item .item-inner .item-img .item-img-info a.product-image img {
	width: 100%;
}
.products-grid-home .item .item-inner .item-img:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	-moz-transform: scale(1.2);
}
.products-grid-home .item .item-inner .item-img img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
.products-grid-home .item .item-inner .item-img .item-img-info a.product-image:before {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
	content: "";
	opacity: 0;
	-webkit-transition: all 0.4s ease-out 0.2s;
	-moz-transition: all 0.4s ease-out 0.2s;
	-o-transition: all 0.4s ease-out 0.2s;
	transition: all 0.4s ease-out 0.2s;
	background-color: #ccc;
}
.products-grid-home .item .item-inner .item-img .item-img-info a.product-image .img-main {
	display: inline;
}
.products-grid-home .item .item-inner .item-img .item-box-hover {
	z-index: 2;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	position: absolute;
	width: 100%;
	top: 35%;
	opacity: 0
}
.products-grid-home .item .item-inner .item-img .item-box-hover .product-detail-bnt {
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 15px;
	margin-right: 7px
}
.products-grid-home .item .item-inner .item-img .item-box-hover .product-detail-bnt a {
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 14px;
	font-weight: normal;
	margin: 0;
	padding: 8px 0px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #fff solid;
	width: 40px;
	background: none
}
.products-grid-home .item .item-inner .item-img .item-box-hover .product-detail-bnt a:hover {
	background-color: #f65f73;
	cursor: pointer;
	bottom: 10%;
}

.products-grid-home .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button {
	border: 0;
	padding: 8px 12px;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
	color: #fff;
	font-weight: normal;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px solid #fff;
	background: none
}
.products-grid-home .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button:hover {
	background: #f65f73
}
.products-grid-home .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button span {
	background: none;
	font-family: 'Montserrat', sans-serif;
	padding: 0px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: bold;
}
.products-grid-home .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button.btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
	font-size: 14px;
	margin-right: 7px
}
.products-grid-home .item .item-inner .item-img .item-box-hover .box-inner .product-detail-bnt a.button.detail-bnt:before {
	content: "\f002";
	font-family: 'FontAwesome';
	font-size: 14px;
}
.products-grid-home .item .item-inner .item-info {
	text-align: center;
}
.products-grid-home .item .item-inner .item-info .info-inner {
	border: 0px solid #fff;
	border-top: none;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.products-grid-home .item .item-inner .item-info .info-inner .item-content .no-rating, . products-grid-home .item .item-inner .item-info .info-inner .item-content .ratings {
margin: 0 0;
}
.products-grid-home .item .item-inner .item-info .info-inner .item-content .no-rating .rating-box, .products-grid-home .item .item-inner .item-info .info-inner .item-content .ratings .rating-box {
	float: none;
	margin: 0 0;
}
.products-grid-home .item .item-inner .item-info .info-inner .item-content .no-rating .rating-links, .products-grid-home .item .item-inner .item-info .info-inner .item-content .ratings .rating-links {
	display: none;
}
.products-grid-home .item .item-inner .item-info .info-inner .item-content .item-price {
}
.products-grid-home .item .item-inner .item-info .info-inner .item-title {
	margin-bottom: 7px;
	padding-top: 20px;
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	letter-spacing: 0.5px
}
.products-grid-home .item .item-inner .item-info .info-inner .item-title a {
	text-decoration: none;
	text-transform: uppercase;
}
.products-grid-home .item .item-inner .item-info .info-inner .item-title a:hover {
	color: #f65f73;
}
.products-grid-home .item:hover .item-inner .item-img .item-img-info {
	border-color: #ccc;
}
.products-grid-home .item:hover .item-inner .item-img .item-img-info a.product-image:before {
	left: 0px;
	top: 0px;
	margin: 0 0;
	background: #000;
	opacity: 0.6;
	cursor: pointer
}
.products-grid-home .item:hover .item-inner .item-img .item-box-hover {
	visibility: visible;
	opacity: 1;
	left: 0px;
	bottom: 0px;
}
.products-grid-home .item:hover .item-inner .item-info .info-inner {
	border-color: #fff;
	background: #fff;
	opacity: 0.8;
}
.products-grid-home .item:hover .item-inner .item-info .info-inner .item-title a {
	color: #f65f73;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content .products-grid-home {
	padding-bottom: 0px;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content .products-grid-home .item {
	margin-bottom: 20px;
	margin-top: 15px;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content .products-grid-home .item.last {
	margin-top: 15px;
	border-right: 0px solid #D9D9D9;
	padding-right: 0;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content .products-grid-home .item.show-addtocart {
	margin-top: 15px;
	border-right: 1px solid #D9D9D9
}
.main-col .pdt-nav {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
	height: 40px;
}
.main-col .producttabs {
	margin-top: 40px
}
.main-col .thmg-slider-pdt-nav {
	display: block;
	text-align: left;
	height: 40px;
}
.main-col .pdt-nav > li {
	font-size: 14px;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	height: 39px;
	padding: 0;
	display: inline-block;
	background: #fff;
	color: #777;
	margin: 0;
	float: none;
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 30px;
	padding: 0px 20px;
	cursor: pointer;
}
.main-col .pdt-nav > li span {
	padding: 0 0;
	display: inline-block;
}
.main-col .pdt-nav > li:first-child {
}
.main-col .thmg-slider-pdt-nav .pdt-nav > li.tab-nav-actived, .main-col .thmg-slider-pdt-nav .pdt-nav > li:hover {
	text-decoration: none;
	color: #222;
	border-bottom: 3px #333 solid
}
.main-col .tab-content-actived {
	display: inline-block;
}
.home-tabs {
	padding-bottom: 30px
}
.producttabs {
	margin-top: 10px
}
.thmg-slider-producttabs .thmg-slider-pdt-container {
	text-align: center;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav {
	display: block;
	text-align: left;
	height: 40px;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li {
	font-size: 16px;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	height: 50px;
	padding: 0;
	display: inline-block;
	color: #333;
	margin: 0;
	float: none;
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	letter-spacing: 1px;
	line-height: 50px;
	padding: 0px 0px;
	border: 1px solid #666;
	width: 215px;
	text-transform: uppercase;
	margin-left: 5px;
	margin-right: 5px;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #f65f73
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li span {
	padding: 0 0;
	display: inline-block;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li:first-child {
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li.tab-nav-actived, .thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-nav .pdt-nav > li:hover {
	color: #fff;
	border: 1px solid #e0403d;
	background: #f65f73;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-webkit-box-shadow: inset 0 -34px 0 0 #222;
	box-shadow: inset 0 -60px 0 0 #f65f73;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .pre-text {
	margin: 15px 0 0;
	display: inline-block;
	width: 70%;
	text-align: center;
	color: #666666;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content {
	border-right: 0px #D9D9D9 solid;
	margin-top: 30px;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content {
	margin: 0 0;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content .products-grid-tabs {
	padding-bottom: 0px;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content .products-grid-tabs .item {
	margin-bottom: 25px;
	margin-top: 15px;
	border: 2px solid #ddd;
	padding-right: 20px;
	padding: 20px
}
.thmg-slider-producttabs .thmg-slider-pdt-container .thmg-slider-pdt-content .pdt-content .products-grid-tabs .item.last {
	margin-bottom: 25px;
	margin-top: 15px;
	border-right: 0px solid #D9D9D9;
	padding-right: 0;
}
.thmg-slider-producttabs .pre-text {
	margin: 0 0 15px 0;
}
.thmg-slider-producttabs .post-text {
	margin: 10px 0 0;
}
.thmg-slider-producttabs a:hover {
	text-decoration: none;
}
.thmg-slider-producttabs .thmg-slider-pdt-container {
	padding: 0;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .pdt-nav {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
	height: 40px;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .pdt-nav > li {
	float: left;
	font-size: 16px;
	line-height: 100%;
	margin: 0 10px 10px 0;
	padding: 8px 15px;
	cursor: pointer;
}
.thmg-slider-producttabs .thmg-slider-pdt-container .pdt-nav > li.tab-nav-actived, .thmg-slider-producttabs .thmg-slider-pdt-container .pdt-nav > li:hover {
}
.thmg-slider-producttabs .thmg-slider-pdt-content {
	margin: 0;
	padding: 0;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content {
	margin: 30px 0 0;
	padding: 0;
	width: 100%;
	display: none;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-list {
	margin: 0;
	padding: 0;
	width: 100%;
	float: left;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .tab-content-actived {
	display: inline-block;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .item.first {
	margin-left: 0px;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .item {
	margin-bottom: 10px;
	margin-top: 0;
	list-style: none;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .item-animate {
	opacity: 0;
	position: relative;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .play .item-animate {
	opacity: 1.0;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .play .item-animate {
	-webkit-animation-play-state: running;
	-moz-animation-play-state: running;
	-o-animation-play-state: running;
	animation-play-state: running;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .slideBottom.play .item-animate {
	-webkit-animation: slideBottom 600ms ease both;
	-moz-animation: slideBottom 600ms ease both;
	-o-animation: slideBottom 600ms ease both;
	animation: slideBottom 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .slideLeft.play .item-animate {
	-webkit-animation: slideLeft 600ms ease both;
	-moz-animation: slideLeft 600ms ease both;
	-o-animation: slideLeft 600ms ease both;
	animation: slideLeft 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .slideRight.play .item-animate {
	-webkit-animation: slideRight 600ms ease both;
	-moz-animation: slideRight 600ms ease both;
	-o-animation: slideRight 600ms ease both;
	animation: slideRight 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .bounceIn.play .item-animate {
	-webkit-animation: bounceIn 600ms ease both;
	-moz-animation: bounceIn 600ms ease both;
	-o-animation: bounceIn 600ms ease both;
	animation: bounceIn 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .bounceInRight.play .item-animate {
	-webkit-animation: bounceInRight 600ms ease both;
	-moz-animation: bounceInRight 600ms ease both;
	-o-animation: bounceInRight 600ms ease both;
	animation: bounceInRight 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .zoomIn.play .item-animate {
	-webkit-animation: zoomIn 600ms ease both;
	-moz-animation: zoomIn 600ms ease both;
	-o-animation: zoomIn 600ms ease both;
	animation: zoomIn 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .zoomOut.play .item-animate {
	-webkit-animation: zoomOut 600ms ease both;
	-moz-animation: zoomOut 600ms ease both;
	-o-animation: zoomOut 600ms ease both;
	animation: zoomOut 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .pageRight.play .item-animate {
	-webkit-animation: pageRight 600ms ease both;
	-moz-animation: pageRight 600ms ease both;
	-o-animation: pageRight 600ms ease both;
	animation: pageRight 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .pageLeft.play .item-animate {
	-webkit-animation: pageLeft 600ms ease both;
	-moz-animation: pageLeft 600ms ease both;
	-o-animation: pageLeft 600ms ease both;
	animation: pageLeft 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .pageTop.play .item-animate {
	-webkit-animation: pageTop 600ms ease both;
	-moz-animation: pageTop 600ms ease both;
	-o-animation: pageTop 600ms ease both;
	animation: pageTop 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .pageBottom.play .item-animate {
	-webkit-animation: pageBottom 600ms ease both;
	-moz-animation: pageBottom 600ms ease both;
	-o-animation: pageBottom 600ms ease both;
	animation: pageBottom 600ms ease both;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-content .starwars.play .item-animate {
	-webkit-animation: starwars 600ms ease-out both;
	-webkit-transform-origin: 50% 50%;
	-moz-animation: starwars 600ms ease-out both;
	-moz-transform-origin: 50% 50%;
	-o-animation: starwars 600ms ease-out both;
	-o-transform-origin: 50% 50%;
	animation: starwars 600ms ease-out both;
	transform-origin: 50% 50%;
}
.thmg-slider-producttabs .pdt-content .content-loading {
	width: 100%;
	max-width: 100%;
	padding: 0;
	position: relative;
	min-height: 400px;
	display: block;
	z-index: 0;
}
.thmg-slider-producttabs .thmg-slider-pdt-content .pdt-loadmore {
	display: block;
	overflow: hidden;
	text-align: center;
	margin: 0 auto;
	width: 100%;
}
.thmg-slider-producttabs .pdt-content .pdt-loadmore .btn-loadmore {
	padding: 6px 20px;
	display: inline-block;
	background: #777;
	color: #FFF;
	cursor: pointer;
	position: relative;
}
.thmg-slider-producttabs .pdt-content .pdt-loadmore .btn-loadmore.loading {
	padding: 6px 20px 6px 50px;
}
.thmg-slider-producttabs .pdt-content .pdt-loadmore .btn-loadmore:hover {
	background: #222;
}
.thmg-slider-producttabs .pdt-content .pdt-loadmore .ico-loading {
	width: 24px;
	height: 24px;
	display: none;
	margin-right: 10px;
	vertical-align: middle;
	position: absolute;
	top: 6px;
	left: 20px;
}
.thmg-slider-producttabs .pdt-content .pdt-loadmore .btn-loadmore.loading .load-number {
	display: none;
}
.thmg-slider-producttabs .pdt-content.is-loading .pdt-loadmore {
	display: none;
}
.thmg-slider-producttabs .pdt-content .pdt-loadmore .load-number:before {
	content: "("attr(data-more);
}
.thmg-slider-producttabs .pdt-content .pdt-loadmore .load-number:after {
	content: attr(data-total)")";
}
.thmg-slider-producttabs .pdt-list .wide-first {
	margin-left: 0px;
}
.pdt-list .item {
	margin-left: 2.4%;
	float: left;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.pdt-list .item.last {
	margin-left: 2.4%;
}
.thmg-slider-producttabs .wide-6 .item {
	width: 14.529914529915%;
}
.thmg-slider-producttabs .wide-5 .item {
	width: 23.2%;
}
.thmg-slider-producttabs .wide-4 .item {
	width: 23.076923076923%;
}
.thmg-slider-producttabs .wide-3 .item {
	width: 23.4%;
}
.thmg-slider-producttabs .wide-2 .item {
	width: 48.717948717949%;
}
.thmg-slider-producttabs .wide-1 .item {
	width: 100%;
}
@-webkit-keyframes bounceIn {
0% {
opacity: 0;
-webkit-transform: scale(.3);
}
50% {
-webkit-transform: scale(1.05);
}
70% {
-webkit-transform: scale(.9);
}
100% {
opacity: 1;
-webkit-transform: scale(1);
}
}
@-moz-keyframes bounceIn {
0% {
opacity: 0;
-moz-transform: scale(.3);
}
50% {
-moz-transform: scale(1.05);
}
70% {
-moz-transform: scale(.9);
}
100% {
opacity: 1;
-moz-transform: scale(1);
}
}
@-o-keyframes bounceIn {
0% {
opacity: 0;
-o-transform: scale(.3);
}
50% {
-o-transform: scale(1.05);
}
70% {
-o-transform: scale(.9);
}
100% {
opacity: 1;
-o-transform: scale(1);
}
}
@keyframes bounceIn {
0% {
opacity: 0;
transform: scale(.3);
}
50% {
transform: scale(1.05);
}
70% {
transform: scale(.9);
}
100% {
opacity: 1;
transform: scale(1);
}
}
@-webkit-keyframes bounceInRight {
0% {
opacity: 0;
-webkit-transform: translateX(2000px);
}
60% {
-webkit-transform: translateX(-30px);
}
80% {
-webkit-transform: translateX(10px);
}
100% {
opacity: 1;
-webkit-transform: translateX(0);
}
}
@-moz-keyframes bounceInRight {
0% {
opacity: 0;
-moz-transform: translateX(2000px);
}
60% {
-moz-transform: translateX(-30px);
}
80% {
-moz-transform: translateX(10px);
}
100% {
opacity: 1;
-moz-transform: translateX(0);
}
}
@-moz-keyframes bounceInRight {
0% {
opacity: 0;
-o-transform: translateX(2000px);
}
60% {
-o-transform: translateX(-30px);
}
80% {
-o-transform: translateX(10px);
}
100% {
opacity: 1;
-o-transform: translateX(0);
}
}
@keyframes bounceInRight {
0% {
opacity: 0;
transform: translateX(2000px);
}
60% {
transform: translateX(-30px);
}
80% {
transform: translateX(10px);
}
100% {
opacity: 1;
transform: translateX(0);
}
}
@-webkit-keyframes zoomIn {
0% {
opacity: 0;
-webkit-transform: scale(1.8);
}
100% {
opacity: 1;
-webkit-transform: scale(1);
}
}
@-moz-keyframes zoomIn {
0% {
opacity: 0;
-moz-transform: scale(1.8);
}
100% {
opacity: 1;
-moz-transform: scale(1);
}
}
@-o-keyframes zoomIn {
0% {
opacity: 0;
-o-transform: scale(1.8);
}
100% {
opacity: 1;
-o-transform: scale(1);
}
}
@keyframes zoomIn {
0% {
opacity: 0;
transform: scale(1.8);
}
100% {
opacity: 1;
transform: scale(1);
}
}
@-webkit-keyframes zoomOut {
0% {
opacity: 0;
-webkit-transform: scale(.6);
}
100% {
opacity: 1;
-webkit-transform: scale(1);
}
}
@-moz-keyframes zoomOut {
0% {
opacity: 0;
-moz-transform: scale(.6);
}
100% {
opacity: 1;
-moz-transform: scale(1);
}
}
@-o-keyframes zoomOut {
0% {
opacity: 0;
-o-transform: scale(.6);
}
100% {
opacity: 1;
-o-transform: scale(1);
}
}
@keyframes zoomOut {
0% {
opacity: 0;
transform: scale(.6);
}
100% {
opacity: 1;
transform: scale(1);
}
}
@-webkit-keyframes slideBottom {
0% {
opacity: 0;
top: 50px;
}
100% {
opacity: 1;
top: 0;
}
}
@-moz-keyframes slideBottom {
0% {
opacity: 0;
top: 50px;
}
100% {
opacity: 1;
top: 0;
}
}
@-o-keyframes slideBottom {
0% {
opacity: 0;
top: 50px;
}
100% {
opacity: 1;
top: 0;
}
}
@keyframes slideBottom {
0% {
opacity: 0;
top: 50px;
}
100% {
opacity: 1;
top: 0;
}
}
@-webkit-keyframes slideRight {
0% {
opacity: 0;
left: 30px;
}
100% {
opacity: 1;
left: 0;
}
}
@-moz-keyframes slideRight {
0% {
opacity: 0;
left: 30px;
}
100% {
opacity: 1;
left: 0;
}
}
@-o-keyframes slideRight {
0% {
opacity: 0;
left: 30px;
}
100% {
opacity: 1;
left: 0;

}
}
@keyframes slideRight {
0% {
opacity: 0;
left: 30px;
}
100% {
opacity: 1;
left: 0;
}
}
@-webkit-keyframes slideLeft {
0% {
opacity: 0;
left: -30px;
}
100% {
opacity: 1;
left: 0;
}
}
@-moz-keyframes slideLeft {
0% {
opacity: 0;
left: -30px;
}
100% {
opacity: 1;
left: 0;
}
}
@-o-keyframes slideLeft {
0% {
opacity: 0;
left: -30px;
}
100% {
opacity: 1;
left: 0;
}
}
@keyframes slideLeft {
0% {
opacity: 0;
left: -30px;
}
100% {
opacity: 1;
left: 0;
}
}
@-webkit-keyframes pageLeft {
0% {
opacity: 0;
-webkit-transform: perspective(400px) rotateY(-90deg);
}
100% {
opacity: 1;
-webkit-transform: perspective(400px) rotateY(0deg);
}
}
@-moz-keyframes pageLeft {
0% {
opacity: 0;
-moz-transform: perspective(400px) rotateY(-90deg);
}
100% {
opacity: 1;
-moz-transform: perspective(400px) rotateY(0deg);
}
}
@-o-keyframes pageLeft {
0% {
opacity: 0;
-o-transform: perspective(400px) rotateY(-90deg);
}
100% {
opacity: 1;
-o-transform: perspective(400px) rotateY(0deg);
}
}
@keyframes pageLeft {
0% {
opacity: 0;
transform: perspective(400px) rotateY(-90deg);
}
100% {
opacity: 1;
transform: perspective(400px) rotateY(0deg);
}
}
@-webkit-keyframes pageRight {
0% {
opacity: 0;
-webkit-transform: perspective(400px) rotateY(90deg);
}
100% {
opacity: 1;
-webkit-transform: perspective(400px) rotateY(0deg);
}
}
@-moz-keyframes pageRight {
0% {
opacity: 0;
-moz-transform: perspective(400px) rotateY(90deg);
}
100% {
opacity: 1;
-moz-transform: perspective(400px) rotateY(0deg);
}
}
@-o-keyframes pageRight {
0% {
opacity: 0;
-o-transform: perspective(400px) rotateY(90deg);
}
100% {
opacity: 1;
-o-transform: perspective(400px) rotateY(0deg);
}
}
@keyframes pageRight {
0% {
opacity: 0;
transform: perspective(400px) rotateY(90deg);
}
100% {
opacity: 1;
transform: perspective(400px) rotateY(0deg);
}
}
@-webkit-keyframes pageTop {
0% {
opacity: 0;
-webkit-transform: perspective(400px) rotateX(90deg);
}
100% {
opacity: 1;
-webkit-transform: perspective(400px) rotateX(0deg);
}
}
@-moz-keyframes pageTop {
0% {
opacity: 0;
-moz-transform: perspective(400px) rotateX(90deg);
}
100% {
opacity: 1;
-moz-transform: perspective(400px) rotateX(0deg);
}
}
@-o-keyframes pageTop {
0% {
opacity: 0;
-o-transform: perspective(400px) rotateX(90deg);
}
100% {
opacity: 1;
-o-transform: perspective(400px) rotateX(0deg);
}
}
@keyframes pageTop {
0% {
opacity: 0;
transform: perspective(400px) rotateX(90deg);
}
100% {
opacity: 1;
transform: perspective(400px) rotateX(0deg);
}
}
@-webkit-keyframes pageTopBack {
0% {
opacity: 0;
-webkit-transform: perspective(400px) rotateX(90deg);
}
100% {
opacity: 1;
-webkit-transform: perspective(400px) rotateX(0deg);
}
}
@-moz-keyframes pageTopBack {
0% {
opacity: 0;
-moz-transform: perspective(400px) rotateX(90deg);
}
100% {
opacity: 1;
-moz-transform: perspective(400px) rotateX(0deg);
}
}
@-o-keyframes pageTopBack {
0% {
opacity: 0;
-o-transform: perspective(400px) rotateX(90deg);
}
100% {
opacity: 1;
-o-transform: perspective(400px) rotateX(0deg);
}
}
@keyframes pageTopBack {
0% {
opacity: 0;
transform: perspective(400px) rotateX(90deg);
}
100% {
opacity: 1;
transform: perspective(400px) rotateX(0deg);
}
}
@-webkit-keyframes pageBottom {
0% {
opacity: 0;
-webkit-transform: perspective(400px) rotateX(-90deg);
}
100% {
opacity: 1;
-webkit-transform: perspective(400px) rotateX(0deg);
}
}
@-moz-keyframes pageBottom {
0% {
opacity: 0;
-moz-transform: perspective(400px) rotateX(-90deg);
}
100% {
opacity: 1;
-moz-transform: perspective(400px) rotateX(0deg);
}
}
@-o-keyframes pageBottom {
0% {
opacity: 0;
-o-transform: perspective(400px) rotateX(-90deg);
}
100% {
opacity: 1;
-o-transform: perspective(400px) rotateX(0deg);
}
}
@keyframes pageBottom {
0% {
opacity: 0;
transform: perspective(400px) rotateX(-90deg);
}
100% {
opacity: 1;
transform: perspective(400px) rotateX(0deg);
}
}
@-webkit-keyframes starwars {
0% {
opacity: 0;
-webkit-transform: perspective(200px) scale(3) translateY(180px) rotateX(80deg);
}
80% {
opacity: 1;
-webkit-transform: perspective(200px) scale(1) rotateX(60deg)
}
100% {
opacity: 1;
-webkit-transform: perspective(200px) scale(1) rotateX(0deg)
}
}
@-moz-keyframes starwars {
0% {
opacity: 0;
-moz-transform: perspective(200px) scale(3) translateY(180px) rotateX(80deg);
}
80% {
opacity: 1;
-moz-transform: perspective(200px) scale(1) rotateX(60deg)
}
100% {
opacity: 1;
-moz-transform: perspective(200px) scale(1) rotateX(0deg)
}
}
@-o-keyframes starwars {
0% {
opacity: 0;
-o-transform: perspective(200px) scale(3) translateY(180px) rotateX(80deg);
}
80% {
opacity: 1;
-o-transform: perspective(200px) scale(1) rotateX(60deg)
}
100% {
opacity: 1;
-o-transform: perspective(200px) scale(1) rotateX(0deg)
}
}
@keyframes starwars {
0% {
opacity: 0;
transform: perspective(200px) scale(3) translateY(180px) rotateX(80deg);
}
80% {
opacity: 1;
transform: perspective(200px) scale(1) rotateX(60deg)
}
100% {
opacity: 1;
transform: perspective(200px) scale(1) rotateX(0deg)
}
}
/* products page */

.thmg-slider-producttabs ul.pdt-list li.item a.thumb {
	position: relative;
	display: block;
	cursor: pointer;
}
.thmg-slider-producttabs ul.pdt-list li.item a.thumb:hover {
	border-color: #999;
}
ul.pdt-list li.item a img.image-hover {
	display: none;
	margin: 0;
}
.thmg-slider-producttabs ul.pdt-list li.item a.thumb span.quick-view {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto 0;
	height: 35px;
	overflow: hidden;
}
.thmg-slider-producttabs ul.pdt-list li.item a.thumb span.quick-view span {
	display: block;
	position: absolute;
	height: 100%;
	width: 100%;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: bold;
	color: #0a0a0a;
	padding: 8px 0 6px;
	background: rgba(255, 255, 255, 0.9);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}
.thmg-slider-producttabs ul.pdt-list li.item a.thumb span.quick-view span i {
	display: inline-block;
	width: 10px;
	height: 10px;
	vertical-align: middle;
	margin-top: -1px;
	background: url('../static/img/quick-view.png') no-repeat center;
	padding-right: 12px;
}
/* === FLIP 3D === */
.thmg-slider-producttabs ul.pdt-list li.item .product-wrapper {
	position: relative;
	margin-top: 20px;
}
.thmg-slider-producttabs ul.pdt-list li.item a.thumb.flip {
	transform-style: preserve-3d;
	transition: transform 0.5s linear;
}
.thmg-slider-producttabs ul.pdt-list li.item .product-wrapper .thumb-wrapper a.thumb span.quick-view {
	display: block;
	position: absolute;
	top: 0;
	left: -200px;
	right: 0;
	bottom: 0;
	margin: auto 0;
	height: 35px;
	overflow: hidden;
	opacity: 0;
}
.thmg-slider-producttabs ul.pdt-list li.item .product-wrapper .thumb-wrapper:hover a.thumb span.quick-view {
	display: block;
	position: absolute;
	top: 0;
	left: 0px;
	right: 0;
	bottom: 0;
	margin: auto 0;
	height: 35px;
	overflow: hidden;
	opacity: 0.8;
}
.col-item a.thumb span.quick-view {
	display: block;
	position: absolute;
	top: 0;
	left: -200px;
	right: 0;
	bottom: 0;
	margin: auto 0;
	height: 35px;
	overflow: hidden;
	opacity: 0;
}
.col-item:hover a.thumb span.quick-view {
	display: block;
	position: absolute;
	top: 0;
	left: 0px;
	right: 0;
	bottom: 0;
	margin: auto 0;
	height: 35px;
	overflow: hidden;
	opacity: 0.8;
}
/* View Type: Grid */
.products-grid {
	margin: 0;
	list-style: none;
}
.products-grid .link-wishlist:before {
	content: "\f004";
}
.products-grid .link-compare:before {
	content: "\f012";
}
.products-grid .link-wishlist {
	color: #252932;
	font-family: 'FontAwesome';
	font-size: 14px;
	font-weight: normal;
	margin: 0;
	padding: 8px 0px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #fff solid;
	width: 40px;
	margin-right: 7px;
	background:#fff;
	border-radius:50px
}
.products-grid .box-inner .actions .link-wishlist span, .products-grid .box-inner .actions .add-to-links a.link-compare span {
	display: none;
}
.products-grid .link-compare {
	color: #252932;
	font-family: 'FontAwesome';
	font-size: 14px;
	font-weight: normal;
	margin: 0;
	padding: 8px 0px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #fff solid;
	width: 40px;
	background:#fff;
	border-radius:50px
}
.products-grid .item .item-inner {
	position: relative;
	border: 1px #ddd dashed;
	border-radius: 5px;
	padding-bottom: 20px;
}
.products-grid .item .item-inner .actions {
	display: inline-block
}
.products-grid .item .item-inner .item-img {
	position: relative;
	
}
.products-grid .item .item-inner .item-img .ico-product {
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 2;
	background: #de44c3;
	padding: 4px 20px 8px;
	color: #FFF;
	font-size: 150%;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	border-radius: 18px;
}
.products-grid .item .item-inner .item-img .ico-product strong {
	font-weight: 600;
}
.products-grid .item .item-inner .item-img .ico-product.ico-sale {
	background: #7ebf38;
	right: 10px;
	left: auto;
}
.products-grid .item .item-inner .item-img .item-img-info {
	text-align: center;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	position: relative;
	overflow: hidden;
}
.products-grid .item .item-inner .item-img .item-img-info a.product-image {
	display: block;
	width: 100%;
}
.products-grid .item .item-inner .item-img .item-img-info a.product-image img {
	width: 100%;
}
.products-grid .item .item-inner .item-img:hover img {
	/* -webkit-transform: scale(1.8);
	transform: scale(1.8);
	-moz-transform: scale(1.8); */
}
.products-grid .item .item-inner .item-img img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
.products-grid .item .item-inner .item-img .item-img-info a.product-image:before {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
	content: "";
	opacity: 0;
	/* -webkit-transition: all 0.4s ease-out 0.2s;
	-moz-transition: all 0.4s ease-out 0.2s;
	-o-transition: all 0.4s ease-out 0.2s;
	transition: all 0.4s ease-out 0.2s; */
	background-color: #ccc;
}
.products-grid .item .item-inner .item-img .item-img-info a.product-image .img-main {
	display: inline;
}
.products-grid .item .item-inner .item-img .item-box-hover {
	z-index: 2;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	position: absolute;
	width: 100%;
	top: 35%;
	opacity: 0
}
.products-grid .item .item-inner .item-img .item-box-hover .product-detail-bnt {
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 15px;
	margin-right: 7px
}
.item .item-inner .item-img .item-box-hover .product-detail-bnt a {
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 14px;
	font-weight: normal;
	margin: 0;
	padding: 8px 0px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #fff solid;
	width: 40px;
	background: none
}
.item .item-inner .item-img .item-box-hover .product-detail-bnt a:hover {
	background-color: #f65f73;
	cursor: pointer;
	bottom: 10%;
}

.products-grid .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button {
	border: 0;
	padding: 8px 12px;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
	color: #fff;
	font-weight: normal;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px solid #fff;
	background: none
}
.products-grid .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button:hover {
	background: #f65f73
}
.products-grid .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button span {
	background: none;
	font-family: 'Montserrat', sans-serif;
	padding: 0px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: bold;
}
.products-grid .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button.btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
	font-size: 14px;
	margin-right: 7px
}
.products-grid .item .item-inner .item-img .item-box-hover .box-inner .product-detail-bnt a.button.detail-bnt:before {
	content: "\f002";
	font-family: 'FontAwesome';
	font-size: 14px;
}
.products-grid .item .item-inner .item-info {
	text-align: center;
}
.products-grid .item .item-inner .item-info .info-inner {
	border: 0px solid #fff;
	border-top: none;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.products-grid .item .item-inner .item-info .info-inner .item-content .no-rating, . products-grid .item .item-inner .item-info .info-inner .item-content .ratings {
margin: 0 0;
}
.products-grid .item .item-inner .item-info .info-inner .item-content .no-rating .rating-box, .products-grid .item .item-inner .item-info .info-inner .item-content .ratings .rating-box {
	float: none;
	margin: 0 0;
}
.products-grid .item .item-inner .item-info .info-inner .item-content .no-rating .rating-links, .products .item .item-inner .item-info .info-inner .item-content .ratings .rating-links {
	display: none;
}
.products-grid .item .item-inner .item-info .info-inner .item-title {
	margin-bottom: 10px;
	padding:0px 15px;
	padding-top: 20px;
	font-size: 16px;
	overflow: hidden;
	letter-spacing:normal;
	font-family:'Rubik', sans-serif
	
}
.products-grid .item .item-inner .item-info .info-inner .item-title a {
	text-decoration: none;
	font-weight:500;
	color:#292929

}
.products-grid .item .item-inner .item-info .info-inner .item-title a:hover {
	color: #6ca218;
}
.products-grid .item:hover .item-inner .item-img .item-img-info {
	border-color: #ccc;
}
.products-grid .item:hover .item-inner .item-img .item-img-info a.product-image:before {
	left: 0px;
	top: 0px;
	margin: 0 0;
	cursor: pointer
}
.products-grid .item:hover .item-inner .item-img .item-box-hover {
	visibility: visible;
	opacity: 1;
	left: 0px;
	bottom: 0px;
}
.products-grid .item:hover .item-inner .item-info .info-inner {
	border-color: #fff;
	background: #fff;
	opacity: 0.8;
}
.products-grid .item:hover .item-inner .item-info .info-inner .item-title a {
	color: #131e2c;
}
/*  End  =*/

/* Rating */
.no-rating {
	margin: 0;
}
.no-rating a {
	color: #000;
}
.ratings {
	font-size: 11px;
	line-height: normal;
	margin: 2px 0;
	margin-bottom:10px
}
.ratings strong {
	float: left;
	margin: 1px 3px 0 0;
}
.ratings .rating-links {
	margin: 0;
	display: none;
}
.ratings .rating-links a {
	margin: 0;
	text-decoration: none;
	color: #6daf23;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px
}
.ratings .rating-links .separator {
	margin: 0 2px;
	vertical-align: top;
}
.rating-box {
	width: 60px;
	height: 10px;
	font-size: 0;
	line-height: 0;
	background: url(../static/img/bkg_rating.png) 0 0 repeat-x;
	text-indent: -999em;
	overflow: hidden;
}
.rating-box .rating {
	float: left;
	height: 9px;
	background: url(../static/img/bkg_rating.png) 0 100% repeat-x;
}
.ratings .rating-box {
	margin-right: 8px;
	display: inline-block;
}
.ratings .amount {
}
.ratings-table th, .ratings-table td {
	font-size: 11px;
	line-height: 1.15;
	padding: 3px 0;
}
.ratings-table th {
	font-weight: normal;
	padding-right: 8px;
}
/* Price */
.price {
	font-size: 14px;
	color: #333;
	white-space: nowrap !important;
}
.price-box {
	margin: 0;
	padding: 0px 0px;

}
/* Regular price */
.regular-price {
	display: inline;
	    font-family: 'Rubik', sans-serif;
}
.regular-price .price {
	font-weight: 500;
	font-size: 14px;
	color: #f65f73;
}
.block .regular-price, .block .regular-price .price {
	color: #131e2c;
}
.price-box .price-from .price {
	font-weight: 500;
	font-size: 13px;
	color: #131e2c;
}
.price-box .price-to .price {
	font-weight: 500;
	font-size: 14px;
	color: #131e2c;
}
.price-box .minimal-price .price {
	font-weight: 500;
	font-size: 14px;
	color: #131e2c;
}
/* Old price */
.old-price .price-label {
	white-space: nowrap;
	color: #999;
	display: none;
}
.old-price {
	display: inline;
	margin-right: 5px;
}
/* Old price */
.old-price .price-label {
	color: #777777;
	display: none;
	white-space: nowrap;
}
.old-price .price {
	color: #ccc!important;
	font-size: 14px;
	font-weight: 500;
	text-decoration: line-through;
	margin-right: 6px;
	font-family:'Rubik', sans-serif
}
.latest-product .old-price .price-label {
	display: none;
}
/* Special price */
.special-price {
	margin: 0;
	padding: 3px 0;
	display: inline;
	font-family:'Rubik', sans-serif
}
.special-price .price-label {
	color: #666;
	display: none;
	font-size: 13px;
	font-weight: 400;
	white-space: nowrap;
}
.special-price .price {
    font-weight: 500;
    font-size: 14px;
    color: #131e2c;
	font-family:'Rubik', sans-serif
}
.special-price .price-label {
	font-size: 14px;
	font-weight: 500;
	white-space: nowrap;
	color: #666;
	display: none;
	font-family:'Rubik', sans-serif
}
.latest-product .special-price .price-label {
	display: none;
}
/* Minimal price (as low as) */
.minimal-price {
	margin: 0;
}
.minimal-price .price-label {
	white-space: nowrap;
}
.minimal-price-link {
	display: inline;
}
.minimal-price-link .label {
}
.products-grid .actions button.button.btn-cart {
	background: #fff;
	border: 1px solid #ddd;
	margin: 0 8px 0 8px;
	padding: 9px 10px 9px 10px;
	color: #333;
	line-height: 14px;
}
.products-grid .item:hover .actions button.button.btn-cart {
	background: #333;
	border: 1px solid #333;
	margin: 0 3px 0 3px;
	padding: 9px 10px 9px 10px;
	color: #FFFFFF;
}
.products-grid .actions button.button.btn-cart span {
	font-family: 'Open Sans', sans-serif;
	font-size: 11px;
	padding: 0 8px;
	font-weight: bold;
	margin-left: 0;
	text-transform: uppercase
}
/* Special price */

/*  Product Slider1 Start =*/

.carousel-inner {
	margin-top: 20px;
}
.best-seller-pro {
	position: relative;
	margin-bottom: 15px;
}
.featured-pro {
	/* padding:0px 25px; */
	position:relative;
	z-index:100;
	margin-top:0px;

	}
  



.top-cate{padding:0px;width:100%;position:relative;margin-top: -100px; background:url(../static/img/top-cat-bg.jpg) no-repeat top left;}
.top-catemobile {
	padding: 0px;
    width: 100%;
    background: url(../static/img/top-cat-bg.jpg) no-repeat top left;
	height:160px
}
.top-cate .container {width:95%}

.top-cate .new_title { text-align:left; margin-left:-20px; margin-right:-10px; border-bottom:1px #e5e5e5 solid}

.top-cate .new_title .title-img{ background:#97bc77; width:100px; height:50px; border-radius:0px 0px 50px 50px;  color:#fff; margin-top:0px; line-height:normal}

.top-cate .new_title h2 {
	color: #fff;
	font-size:16px;
	font-weight: bold;
	margin-bottom: 0px;
	padding: 22px 25px 22px 25px;
	position: relative;
	display: inline-block;
	font-family:'Montserrat', sans-serif;
	letter-spacing: 0.5px;
	text-transform:uppercase;
	background:#f65f73;
	margin-top:0px;
	min-width:250px

	
}


.top-cate .new_title h2:after {
  content: "";
  height: 1px;
  width: 1px;
  border-style: solid;
  border-width: 5px;
  position: absolute;
  bottom: -10px;
  left: 0;
  border-color: #d95451 #d95451 transparent transparent;
  }

.top-cate .new_title span {display:block; color:#333; margin-bottom:35px; font-size:14px}

.offer-slider {
	display: block;
	text-align: center;
	margin: 0px auto 0px;
}

.featured-pro .product-block {
	text-align: center
}
.featured-pro .pro-img {
	overflow: hidden;
	position: relative;
    z-index:1000

}


.featured-pro .pro-img img {
  position: relative;
  width: 100%;
  /* border: 5px #ddd solid; */
  margin-top:20px;
  border-radius:999px
}



.featured-pro .pro-img .pro-info {
	color: #383838;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.5px;
	margin: auto;
	padding: 6px 0px 0px 0px;
	text-transform: uppercase;
	font-family: 'Rubik', sans-serif;
	/* margin: 0px 20px; */
	position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
	text-align:center;
    transform: scale(0) rotate(0deg);
    transition: all 0.4s ease-in-out 0s;
    opacity: 0;
	display:block;
	width: 344px;
	  height: 361px;
}

.featured-pro .pro-img:hover .pro-info{
    color: #383838;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: auto;
    padding: 6px 0px 0px 0px;
    text-transform: uppercase;
    font-family: 'Rubik', sans-serif;
    /* margin: 0px 20px; */
    width: 344px;
    position: absolute;
    z-index: 1000;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../static/img/hover_01.png) no-repeat top left;
    height: 361px;
    opacity: 1;
     transition: all 0.4s ease-in-out 0s;
    -webkit-transform: scale(1.0) rotate(0deg);
    transform: scale(1.0) rotate(0deg);


}
.featured-pro .pro-img h3 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 40%;
	font-size:18px;
	
}

.offer-slider li {
	position: relative
}
.offer-slider p {
	color: #777;
	padding: 0;
	font-size: 14px;
	font-weight: normal;
}
.offer-slider a.shop-now {
	padding: 7px 25px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: bold;
	color: #000;
	margin-left: 10px;
	border-radius: 25px;
	letter-spacing: 1px;
	font-family: 'Montserrat', sans-serif;
	display: inline-block;
	background: #ffc000;
}
.offer-slider a.shop-now:after {
	content: '\f0da';
	font-family: FontAwesome;
	display: inline-block !important;
	margin-left: 15px;
	color: #fff;
	font-size: 16px;
}
.offer-slider a.shop-now:hover {
	color: #fff;
	background-color: #f65f73;
}




#featured-slider .link-wishlist, #featured-slider .link-compare {
	display: none!important
}
#featured-slider .products-grid .item .item-inner .item-info .info-inner .item-title a {
	font-size: 13px
}
#featured-slider .price-box {
	border: none;
	margin: 0px;
	padding-bottom: 2px
}
#featured-slider .regular-price .price {
	font-size: 12px
}
#featured-slider .special-price .price {
	font-size: 12px
}
#featured-slider .products-grid .actions button.button.btn-cart {
	padding: 3px 8px;
	border: 1px #eaeaea solid
}
#featured-slider .products-grid .actions button.button.btn-cart span {
	font-size: 10px
}
#featured-slider .products-grid .actions .link-wishlist {
	padding: 8px 10px;
	font-size: 12px;
	display: none
}
#featured-slider .products-grid .item:hover .actions .link-wishlist {
	padding: 8px 10px;
	font-size: 12px;
	display: inline-block
}
#featured-slider .products-grid .actions .link-compare {
	padding: 8px 10px;
	font-size: 12px;
	display: none
}
#featured-slider .products-grid .item:hover .actions .link-compare {
	padding: 8px 10px;
	font-size: 12px;
	display: inline-block
}
.glyphicon-chevron-right:before {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: normal;
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\f105";
	text-transform: none;
	font-size: 18px;
	padding: 6px 6px 6px 6px;
	line-height: 30px;
}
.glyphicon-chevron-left:before {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: normal;
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\f104";
	text-transform: none;
	font-size: 18px;
	padding: 6px 4px;
	line-height: 30px;
}
.col-item {
	position: relative;
	background: #FFF;
}
.col-item .photo img {
	margin: 0 auto;
	width: 100%;
}
.col-item .info {
	margin-top: 1px;
	text-align: center;
}
.col-item:hover .info {
	background-color: #fff;
}
.clear-left {
	clear: left;
}
.col-item .separator p {
	line-height: 20px;
	margin-bottom: 0;
	margin-top: 10px;
	text-align: center;
}
.col-item .separator p i {
	margin-right: 5px;
}
.col-item .btn-add {
	width: 50%;
	float: left;
}
.col-item .btn-add {
	border-right: 1px solid #E1E1E1;
}
.col-item .btn-details {
	width: 50%;
	float: left;
	padding-left: 10px;
}
[data-slide="prev"] {
margin-right: 10px;
}
.col-item .actions-links {
	height: 50px;
	background: #222;
	position: absolute;
	top: 55%;
	width: 100%;
	opacity: 0;
	z-index: 1000;
	overflow: hidden;
}
.col-item:hover .actions-links {
	height: 35px;
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 58.4%;
	width: 100%;
	opacity: 8;
	z-index: 10000;
}
.col-item .actions-links .add-to-links a.thmg-slider-btn-quickview {
	left: 120%;
	opacity: 0;
	position: absolute;
	margin-top: 4px;
	z-index: 10;
	cursor: pointer;
}
.col-item:hover .actions-links .add-to-links a.thmg-slider-btn-quickview {
	left: 26%;
	opacity: 8;
	position: absolute;
	margin-top: 0px;
	border-left: 1px solid rgba(255, 255, 255, 0.3);
	width: 40px;
	text-align: center;
}
.col-item .actions-links .add-to-links a.thmg-slider-btn-quickview:before {
	content: "\f002";
}
.col-item .actions-links .add-to-links a.thmg-slider-btn-quickview {
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	text-decoration: none;
	display: inline-block;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	padding: 5px 5px;
	line-height: 25px;
	width: 30px;
}
.col-item .actions-links .add-to-links a.thmg-slider-btn-quickview:hover {
	background: #f65f73;
}
.col-item .actions-links .add-to-links a.link-wishlist:before {
	content: "\f004";
}
.col-item .actions-links .add-to-links a.link-compare:before {
	content: "\f012";
}
.col-item .actions-links .add-to-links a.link-wishlist {
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	text-decoration: none;
	display: inline-block;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	padding: 5px 5px;
	line-height: 25px !important;
	width: 30px;
}
.col-item .actions-links .add-to-links a.link-wishlist:hover {
	font-family: 'FontAwesome';
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	padding: 5px 5px;
	text-decoration: none;
	color: #fff;
	line-height: 25px;
	background: #f65f73;
	width: 30px;
}
.col-item .actions-links .add-to-links a.link-compare {
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	text-decoration: none;
	display: inline-block;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	padding: 5px 5px;
	line-height: 25px !important;
	width: 30px;
}
.col-item .actions-links .add-to-links a.link-compare:hover {
	font-family: 'FontAwesome';
	font-size: 16px;
	font-weight: normal;
	margin: 0;
	padding: 5px 5px;
	text-decoration: none;
	color: #fff !important;
	line-height: 25px !important;
	background: #f65f73;
	width: 30px;
}
.col-item .add-to-links a span {
	display: none;
}
.col-item .actions-links .add-to-links a.link-wishlist {
	right: 120%;
	opacity: 0;
	position: absolute;
	margin-top: 4px;
	z-index: 10;
}
.col-item:hover .actions-links .add-to-links a.link-wishlist {
	right: 44%;
	opacity: 8;
	position: absolute;
	margin-top: 0px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-top: none;
	border-bottom: none;
	width: 40px;
	text-align: center;
}
.col-item .actions-links .add-to-links a.link-compare {
	right: 120%;
	opacity: 0;
	position: absolute;
	margin-top: 4px;
	z-index: 10;
}
.col-item:hover .actions-links .add-to-links a.link-compare {
	right: 29%;
	opacity: 8;
	position: absolute;
	margin-top: 0px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-top: none;
	border-bottom: none;
	border-left: none;
	width: 40px;
	text-align: center;
}
.col-item .info .info-inner .item-title {
	margin-bottom: 5px;
	padding-top: 16px;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
}
.col-item .info .info-inner .item-title a {
	text-decoration: none;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #000;
}
.col-item .info .info-inner .item-title a:hover {
	color: #f65f73;
}
/*.actions button.button.btn-cart {
background: #fff;
border: 1px solid #ddd;
margin: 10px 8px 0 8px;
padding: 9px 10px 9px 10px;
color: #333;
line-height: 14px;
transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.actions button.button.btn-cart:hover {
background: #333;
color: #fff;
}*/
.products-list .desc .link-learn {
	font-size: 13px;
	font-weight: normal;
	margin-top: 5px;
	color: #282828;
}
.products-list .ratings .rating-links {
	margin: 0;
	display: inline-block;
}
.category-image img {
	width: 100%;
}
/*.actions button.button.btn-cart span {
font-family:  'Open Sans', sans-serif;;
font-size: 11px;
padding: 0 8px;
font-weight: bold;
margin-left: 0;
text-transform: uppercase
}*/
.promo-banner-section {
	margin: 5px auto 25px;
}
.small-pr-slider span.quick-view {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto 0;
	height: 35px;
	overflow: hidden;
}
.small-pr-slider span.quick-view span {
	display: block;
	position: absolute;
	height: 100%;
	width: 100%;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: bold;
	color: #0a0a0a;
	padding: 8px 0 6px;
	background: rgba(255, 255, 255, 0.9);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}
.small-pr-slider span.quick-view span i {
	display: inline-block;
	width: 10px;
	height: 10px;
	vertical-align: middle;
	margin-top: -1px;
	background: url('../static/img/quick-view.png') no-repeat center;
	padding-right: 12px;
}
.small-pr-slider .controls .prev {
	top: 10px;
	right: 50px;
}
.small-pr-slider .controls .next {
	top: 10px;
	right: 15px;
}
.small-pr-slider .col-item .actions-links {
	height: 32px;
	top: 52%;
}
.small-pr-slider .col-item:hover .actions-links {
	height: 30px;
	top: 45.8%;
}

/*Best Products Slider */

.cate-banner-img {
    position: absolute;
    z-index: 100;
    margin-top: -11px;
    margin-left: 0px;
    right: 0px;
}

#best-seller .owl-item .item {margin-left:14px; margin-right:14px}

.best-pro {  padding: 0px 20px;
  position: relative;
  margin-top: 0px;
  background-color: #fff;
 }

.best-pro .new_title {
  text-align: center;
  margin-bottom:0px}

.best-pro .new_title h2 {
	color: #292929;
	font-size:42px;
	font-weight: 500;
	margin-bottom: 0px;
	padding: 5px 25px 10px 25px;
	position: relative;
	display:block;
	font-family:'Oswald', sans-serif;
	letter-spacing: 0.5px;
	text-transform:uppercase;
	margin-top:0px;
    min-width:250px;
	font-family: 'Oswald', sans-serif;
}
	
	
 
.best-pro .new_title h4 {background:#97bc77; color:#fff; padding:6px 10px; border-radius:999px; display:inline-block; font-family:'Oswald', sans-serif; font-weight:300; text-transform:uppercase; font-size:15px; letter-spacing:0.8px; margin:0px; margin-bottom:25px} 

.best-pro.slider-items-products .owl-buttons .owl-prev {
    position: absolute;
    left: -35px;
    top:45%
	
}

.best-pro.slider-items-products .owl-buttons .owl-next {
    position: absolute;
    right: -45px;
     top:45%
}

.best-pro.slider-items-products .owl-buttons .owl-prev a, .best-pro.slider-items-products .owl-buttons .owl-next a {border:none; background:#3f5168; color:#000; opacity:0.2; width: 48px !important; height: 46px !important; line-height: 48px; border-radius:999px;}
.best-pro.slider-items-products .owl-buttons .owl-prev a:hover, .best-pro.slider-items-products .owl-buttons .owl-next a:hover {background:#131e2c; opacity:1}
.best-pro.slider-items-products .owl-buttons .owl-prev a:before, .best-pro.slider-items-products .owl-buttons .owl-next a:before {color:#fff; line-height: 46px;}
.best-pro.slider-items-products .owl-buttons .owl-prev a:hover:before, .best-pro.slider-items-products .owl-buttons .owl-next a:hover:before {color:#97bc77}


#best-seller .owl-item .item.first {
	margin-left: 0;
}
#best-seller .owl-item .item {
	float: left;
	background: #FFF;
	margin-top: 20px;
}
#best-seller .owl-item .item.show-addtocart {
	margin-top: 37px;
}
#best-seller .owl-item .item .item-inner {
	position: relative;
	font-family: 'Montserrat', sans-serif;
	border:none;
	padding-bottom:0
}
#best-seller .owl-item .item .item-inner .actions {
	display: inline-block
}
#best-seller .owl-item .item .item-inner .item-img {
	position: relative;
	border: 1px #eaeaea solid;
}
#best-seller .owl-item .item .item-inner .item-img .ico-product {
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 2;
	background: #de44c3;
	padding: 4px 20px 8px;
	color: #FFF;
	font-size: 150%;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	border-radius: 18px;
}
#best-seller .owl-item .item .item-inner .item-img .ico-product strong {
	font-weight: 600;
}
#best-seller .owl-item .item .item-inner .item-img .ico-product.ico-sale {
	background: #7ebf38;
	right: 10px;
	left: auto;
}
#best-seller .owl-item .item .item-inner .item-img .item-img-info {
	text-align: center;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	position: relative;
	overflow: hidden;
}
#best-seller .owl-item .item .item-inner .item-img .item-img-info a.product-image {
	display: block;
	width: 100%;
}
#best-seller .owl-item .item .item-inner .item-img .item-img-info a.product-image img {
	width: 100%;
}
#best-seller .owl-item .item .item-inner .item-img:hover img {
	-webkit-transform: scale(1.8);
	transform: scale(1.8);
	-moz-transform: scale(1.8);
}
#best-seller .owl-item .item .item-inner .item-img img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
#best-seller .owl-item .item .item-inner .item-img .item-img-info a.product-image:before {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
	content: "";
	opacity: 0;
	-webkit-transition: all 0.4s ease-out 0.2s;
	-moz-transition: all 0.4s ease-out 0.2s;
	-o-transition: all 0.4s ease-out 0.2s;
	transition: all 0.4s ease-out 0.2s;
	background-color: #ccc;
}
#best-seller .owl-item .item .item-inner .item-img .item-img-info a.product-image .img-main {
	display: inline;
}
#best-seller .owl-item .item .item-inner .item-img .item-box-hover {
	z-index: 2;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	position: absolute;
	width: 100%;
	top: 35%;
	opacity: 0
}
#best-seller .owl-item .item .item-inner .item-img .item-box-hover .product-detail-bnt {
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 15px;
	margin-right: 7px

}
.item .item-inner .item-img .item-box-hover .product-detail-bnt a {
	color: #252932;
	font-family: 'FontAwesome';
	font-size: 14px;
	font-weight: normal;
	margin: 0;
	padding: 8px 0px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #fff solid;
	width: 40px;
	background: #fff;
	border-radius:50px
}
.item .item-inner .item-img .item-box-hover .product-detail-bnt a:hover {
	background-color: #252932;
	cursor: pointer;
	bottom: 10%;
	color:#fff;
	border-color:#252932
}

.item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button {
	border: 0;
	padding: 8px 12px;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
	color: #252932;
	font-weight: normal;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px solid #fff;
	background: #fff;
	border-radius:50px
}
#best-seller .owl-item .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button:hover {
	background: #f65f73
}
#best-seller .owl-item .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button span {
	background: none;
	font-family: 'Montserrat', sans-serif;
	padding: 0px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: bold;
}
#best-seller .owl-item .item .item-inner .item-img .item-box-hover .box-inner .add_cart button.button.btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
	font-size: 14px;
	margin-right: 7px
}
#best-seller .owl-item .item .item-inner .item-img .item-box-hover .box-inner .product-detail-bnt a.button.detail-bnt:before {
	content: "\f002";
	font-family: 'FontAwesome';
	font-size: 14px;
}
#best-seller .owl-item .item .item-inner .item-info {
	text-align: center;
}
#best-seller .owl-item .item .item-inner .item-info .info-inner {
	border: 0px solid #fff;
	border-top: none;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
#best-seller .owl-item .item .item-inner .item-info .info-inner .item-content .no-rating, #best-seller-slider .owl-item .item .item-inner .item-info .info-inner .item-content .ratings {
margin: 0 0;
}
#best-seller .owl-item .item .item-inner .item-info .info-inner .item-content .no-rating .rating-box, #best-seller-slider .owl-item .item .item-inner .item-info .info-inner .item-content .ratings .rating-box {
	float: none;
	margin: 0 0;
}
#best-seller .owl-item .item .item-inner .item-info .info-inner .item-content .no-rating .rating-links, #best-seller-slider .owl-item .item .item-inner .item-info .info-inner .item-content .ratings .rating-links {
	display: none;
}
#best-seller .owl-item .item .item-inner .item-info .info-inner .item-content .item-price {
}


#best-seller .owl-item .item:hover .item-inner .item-img .item-img-info {
	border-color: #ccc;
}
#best-seller .owl-item .item:hover .item-inner .item-img .item-img-info a.product-image:before {
	left: 0px;
	top: 0px;
	margin: 0 0;
	cursor: pointer
}
#best-seller .owl-item .item:hover .item-inner .item-img .item-box-hover {
	visibility: visible;
	opacity: 1;
	left: 0px;
	bottom: 0px;
}
#best-seller .owl-item .item:hover .item-inner .item-info .info-inner {
	border-color: #fff;
	background: #fff;
	opacity: 0.8;
}
#best-seller .owl-item .item:hover .item-inner .item-info .info-inner .item-title a {
	color: #111;
}
.products-grid .item .add_cart {opacity:0;position:absolute;bottom: 0;z-index:10;left: 0;margin-left: 0;width: 100%;}
.products-grid .item:hover .add_cart {margin-top:15px; opacity:1}
.products-grid .btn-cart {background:#5d5757;border: none;box-shadow:none;color:#fff;border-radius: 0;width: 100%;height: 40px;display: block;}
.products-grid .btn-cart span {/* display:none */padding-left: 5px;}
.products-grid .btn-cart:hover {box-shadow: inset 0 -60px 0 0 #f65f73;border: none;}
.products-grid .btn-cart {padding:0px!important;}
.products-grid .btn-cart:before {content:'\f067';font-family: FontAwesome;color:#fff;font-size:18px;padding:8px 5px;display: inline-block;}
.btn-cart span {display:inline-block; padding:0px 15px}

/* products page */
.col-item {
	text-align: center;
	margin-bottom: 30px;
	width: 100%;
}
.col-item a.thumb {
	position: relative;
	display: block;
	cursor: pointer;
}
.col-item a.thumb:hover {
	border-color: #999;
}
.col-item a.thumb span.quick-view {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto 0;
	height: 35px;
	overflow: hidden;
}
.col-item a.thumb span.quick-view span {
	display: block;
	position: absolute;
	height: 100%;
	width: 100%;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 700;
	color: #0a0a0a;
	padding: 10px 0 5px;
	background: rgba(255, 255, 255, 0.9);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}
.col-item a.thumb span.quick-view span i {
	display: inline-block;
	width: 10px;
	height: 10px;
	vertical-align: middle;
	margin-top: -1px;
	background: url('../static/img/quick-view.png') no-repeat center;
	padding-right: 18px;
}
/* === FLIP 3D === */
.col-item .product-wrapper {
	position: relative;
	margin-top: 20px;
}
.col-item a.thumb.flip {
	transform-style: preserve-3d;
	transition: transform 0.5s linear;
}
.col-item .thumb-wrapper:hover a.thumb.flip {
	transform: rotateY(180deg);
}
.col-item .product-wrapper a.thumb.flip .face {
	display: block;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	position: relative;
	z-index: 1;
}
.col-item .product-wrapper a.thumb.flip .face img {
	width: 100%;
}
.col-item .product-wrapper a.thumb.flip .face.back {
	display: block;
	transform: rotateY(180deg);
	position: absolute;
	top: 3px;
	left: 0px;
	right: 0px;
	z-index: 10;
}
.col-item .product-wrapper a.thumb.flip .face.back img {
	width: 100%;
}
.col-item .actions .add-to-links a.link-wishlist {
	right: -142px;
	opacity: 0;
	position: absolute;
	margin-top: -35px;
	z-index: 100;
}
.col-item:hover .actions .add-to-links a.link-wishlist {
	right: 36px;
	opacity: 1;
	position: absolute;
	margin-top: -35px;
	z-index: 100;
}
.col-item .actions .add-to-links a.link-compare {
	right: -106px;
	opacity: 0;
	position: absolute;
	z-index: 100;
	margin-top: -35px;
}
.col-item:hover .actions .add-to-links a.link-compare {
	right: 5px;
	opacity: 1;
	z-index: 100;
	position: absolute;
	margin-top: -35px;
}
.col-item .actions .add-to-links a.link-wishlist:before {
	content: "\f004";
}
.col-item .actions .add-to-links a.link-compare:before {
	content: "\f012";
}
.col-item .actions a.link-wishlist, .col-item .actions a.link-compare {
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 13px;
	font-weight: normal;
	margin: 0;
	text-decoration: none;
	display: inline-block;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	padding: 6px 8px;
	line-height: 17px;
	background-color: #333;
}
.col-item .actions .add-to-links a.link-wishlist:hover, .col-item .actions .add-to-links a.link-compare:hover {
	color: #fff;
	background-color: #000;
}
.col-item .item-title {
	margin-bottom: 5px;
	padding-top: 16px;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.col-item .item-title a {
	text-decoration: none;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #000;
	font-size: 13px;
}
/* products page */

.recommend {
	margin: 20px auto;
	position: relative;
}
.recommend .controls .next {
	right: 0;
}
.recommend .controls .prev {
	right: 36px;
}


.new_title.center {
	padding: 0 0 0px 0;
	text-align: center;
	margin: 0px
}
.new_title {
	color: #000000;
	margin: 0 auto 10px;
	text-align: center;
	text-transform: none;
}
.cross-sell-pro .new_title h2 {
	padding: 8px 20px 8px 0px;
}
.cross-sell-pro {
	margin-top: 25px;
}
/*  Product Slider1End  =*/


/* Hot Deals */

.hot-deal {
	overflow: hidden;
	/* margin-top: 145px; */

	
}

.hot-deal ul.products-grid {
    padding: 0;
    margin: 0 -15px;
}
.hot-deal ul.products-grid .item .item-inner {
    background: #fff;
    padding: 0px 0 15px 0px;
    border: 2px #97bc77 solid;
    border-radius: 10px;
	overflow:hidden;
	
}

.hot-deal ul.products-grid .item .item-inner .item-img {
    position: relative;

}

.hot-deal ul.products-grid .item .item-inner .item-img img {border-radius:10px}
.hot-deal ul.products-grid .item .item-inner .item-img:hover img  {border-radius:10px}
	
.hot-deal .brand {
    color: #333;
	border-bottom: 1px rgba(0,0,0,0.2) solid;
	
}


.hot-label {
    font-size: 13px;
    color: #fff;
    background: #eb2771;
    padding: 3px 10px;
    text-align: center;
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    font-weight: 700;
}

.hot-label.hot-top-left:after {
    border-color: #eb2771 transparent transparent;
    border-style: solid none solid solid;
    border-width: 9px 0px 9px 9px;
    bottom: -18px;
    content: "";
    right: 0px;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    width: 9px;
}

.timer-grid {
	overflow: hidden;
	display: block;
	text-align:center
}
.timer-grid .box-time-date {
	font-size: 13px;
	color: #fff;
	width:22%;
	text-align: center;
	font-weight: normal;
	text-transform: uppercase;
	display: inline-block;
	float:left;
	height:94px;
	margin-right:10px;
	background:#f65f73;
	border-radius:999px;
	font-family:'Rubik', sans-serif;
	letter-spacing:0.5px

	
}
.timer-grid .day.box-time-date {
	border-left:none;

}
.timer-grid .day.box-time-date span {
	color: #fff;
}

.timer-grid .box-time-date span {
	display: block;
	font-size: 30px;
	color: #fff;
	margin-top: 24px;
	font-weight:600;
	padding-bottom:10px;
	font-family: 'Open Sans', sans-serif;
	letter-spacing:normal
}

.box-timer {
	text-align: left;
	margin: 0 0px;
	color:#333;
	border-radius:10px;
	width: 450px;
	margin:auto;
	margin-top: -135px;
	position: absolute;
	left: 50%;
	margin-left: -210px;
}

.offer-text {margin-top:15px}

.hot-section {background:url(../static/img/dry_banner.png) no-repeat 0 0px; padding:75px 0px 80px 0px; overflow:hidden; margin-top:10px;}

.hot-section h2 {
    font-size: 44px;
    margin: 0px 0;
    font-weight: 300;
    color: #292929;
	text-align:center;
	padding-bottom:0px;
	letter-spacing:0.5px;
	font-family: 'Oswald', sans-serif;
	text-transform:uppercase

}

.hot-section h3 {
    font-size: 70px;
    margin: 0px 0;
    font-weight: 500;
    color: #97bc77;
	text-align:center;
	padding-bottom:10px;
	letter-spacing:0.5px;
	font-family: 'Oswald', sans-serif;
	text-transform:uppercase

}
.hot-section h4 {
    font-size: 20px;
    font-weight: 600;
    text-transform: none;
    font-family: 'Open Sans', sans-serif;
    color: #292929;
    text-decoration: none;
    background-color: transparent;
    padding: 0px 0px 30px 0px;
    text-align: center;
    margin: auto;
	line-height:30px
}

/* blog-post */
.blog-wrapper {
    padding:0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 30px;

    }

.blog-wrapper header{ z-index:auto}

.blog-wrapper .entry-date{background:#fff; color:#97bc77; padding:10px 15px; font-family:'Montserrat', sans-serif; text-transform:uppercase; position:absolute; right:20px; top:18px; border:#ececec double}

.blog-wrapper .entry-content {padding-top:0px}
.blog-wrapper .entry-content ul {margin-bottom:25px; }

.blog-index .col-main {padding-right:0px}

.blog-side {}

.col2-left-layout .main-blog {padding-right:0px}

.blog-side h2{
    font-size: 18px;
    letter-spacing: 0.05em;
    font-weight: 400;
    padding: 10px 15px;
    position: relative;
    display: block;
    font-family: 'Oswald', sans-serif;
    margin-top: 0px;
    color: #fff;
    text-transform: uppercase;
    background: #97bc77;
}

.blog-side .widget-content {padding:15px; font-size:13px; padding-bottom:0px}

.blog-wrapper blockquote {
    font-family: georgia;
    font-style: italic;
	position:relative;
	line-height:1.6em
}

blockquote {
    margin: 0 0 20px;
    padding: 20px 20px 30px 67px;
    border-left: 3px solid #eaeaea;
    background: #f8f8f8;
}


blockquote:before {
    content: open-quote;
    display: block;
    position: absolute;
    left: 14px;
    top: 10px;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 72px;
    line-height: 1em;
    color: #c2c2c2;
}

.blog-wrapper h2 {font-size:26px; font-family: 'Rubik', sans-serif; text-transform:uppercase; font-weight:500}
.blog-wrapper h3 {font-size:24px; font-family: 'Rubik', sans-serif; text-transform:uppercase; font-weight:500; margin-top:15px}
.blog-wrapper h4 {font-size:20px; font-family: 'Rubik', sans-serif; text-transform:uppercase; font-weight:500; margin-top:15px}
.blog-wrapper h5 {font-size:18px; font-family: 'Rubik', sans-serif; text-transform:uppercase; font-weight:500; margin-top:15px}

.blog-side .widget-content li {overflow:hidden;}

.blog-side .featured-thumb {    float: left;
    margin: 0 12px 10px 0;
    position: relative;}

.blog-side .featured-thumb img {width:90px; height:60px}

.blog-side .content-info {width:100%;}

.blog-side .content-info h4 {font-size: 14px;
    line-height: 18px;
    padding-top: 0px;

    margin-bottom: 9px;
    margin: auto;}
	
.blog-side .content-info h4 a {font-size:14px; color:#333}	

.blog-side .post-meta {font-size: 13px; color: #999; margin-top:5px; border:none }
.blog-side .icon-calendar {margin-right:5px}

.blog-side .widget_categories {
    padding: 0px;
    position: relative;
    margin-top: 0px;
    background-color: #fff;
    margin-bottom: 30px;}

.blog-side .widget_categories .content{padding:15px; padding-top:0px}

.blog-side .widget_categories ul {
    background: none repeat scroll 0 0 #fff;
    border-top: medium none;
    font-size: 14px;
    overflow: hidden;
    /* padding-left: 15px; */
}

.blog-side .widget_categories li {
    padding: 5px 0px;
}

.blog-side .text-widget {padding: 0px;
    position: relative;
    margin-top: 0px;
    background-color: #fff;
    margin-top: 30px;}
	
.blog-side .text-widget .widget-content {padding:10px 15px}


.popular-posts {
    padding: 0px;
    position: relative;
    margin-top: 0px;
    background-color: #fff;
    margin-bottom: 30px;}

.blog_entry {position:relative; padding-bottom:0px}
.blog_entry .btn {background: #97bc77;
    color: #fff;
    font-size: 14px;
    text-shadow: none;
    padding: 10px 40px 8px 40px;
    margin-top: 0px;
    font-weight: 500;
    border-radius: 999px;
    display: block;
    font-family: 'Rubik', sans-serif;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.5px;
	display:inline-block}
.blog_entry .btn:hover{ background:#131e2c;  padding: 10px 40px 8px 40px; border:none; }




.blog_entry-title {font-family:'Rubik', sans-serif; text-transform:uppercase; font-weight:500; font-size:28px; margin-top:25px; padding-bottom:2px; letter-spacing:0.5px; line-height:1.4em}
	
.entry-content	{padding-top:20px}
	
.blog-wrapper img{ max-width:100%}	

.latest-blog .blog-img img {
	width: 100%;
	border-radius: 10px
}
.latest-blog {
  padding:20px;
  position: relative;
  overflow:hidden;
  background-color: #fff;
  margin-bottom:50px
}

.latest-blog .blog-post:last-child .blog_inner {border:none}

.latest-blog .blog_inner {
  position: relative;
  margin-top: 0px;
  background-color: #fff;

}
  
 .latest-blog .fl-right {
	float: right;
	margin-right:0px
}


.blog-info{padding:25px 0px 0px 0px} 

.blog_entry p {
    margin: 0 0 1.5em 0;
    font-size: 14px;
    line-height: 1.6em;
}

.main-blog .post-meta {
	margin:0px;
	padding:0px;
    margin-bottom: 12px!important;

    text-align: left;
    border-bottom: 1px #e5e5e5 solid;
    padding-bottom: 15px;
}


.post-meta {
	margin:0px;
	padding:0px;
    margin-bottom: 10px!important;
    text-align: left;
    padding-bottom:8px;
}

.post-meta li {
    display: inline-block;
    margin-right: 10px;
    color: #a7a7a7;
    text-transform: none;
    font-size: 13px;
}

.post-meta li i {
    padding-right: 10px;
}

.post-meta li a {
    margin-right: 5px;
    color: #97bc77;
	 font-size: 13px;
}

.latest-blog .blog-title {
	color: #000000;
	height: 50px;
	margin: 0 auto 0px;
	text-align: left;
	text-transform: none;
	padding-left: 15px
}
.latest-blog h3 {
	margin-top: 0px;
	margin-bottom: 0px;
	line-height:26px

}
.latest-blog h3 a {
	font-size: 18px;
	font-weight: 500;
	font-family:'Rubik', sans-serif;
	padding-bottom: 10px;
	display: block;
	text-transform:uppercase
}

.latest-blog .post-date {
	height:70px;
	width:70px;
	background:#f65f73;
	position:absolute;
	display:inline-block;
	color:#fff;
	font-weight: 400;
	font-family:'Rubik', sans-serif;
	font-size:18px;
	text-transform:uppercase;
	text-align:center;
	left:22px;
	margin-top:-115px;
	padding-top:15px;
	border-radius:999px;

	
}

.latest-blog .post-date span {display:block; font-weight:400; font-size:12px; letter-spacing:0.5px}

.latest-blog .readmore {
	font-size: 12px;
	display: inline-block;
	padding: 6px 15px;
	border: 1px #ddd solid;
	text-transform: uppercase;
	font-size: 12px;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: 1px;
	font-weight: 700;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff
}
.latest-blog .readmore:hover {
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 -40px 0 0 #f65f73;
	border: 1px solid #e0403d;
	color: #fff
}
.latest-blog .blog-title h2 {
	color: #666;
	font-size: 16px;
	font-weight: bold;
	margin-top: 0;
	padding: 12px 10px 2px 0px;
	position: relative;
	display: inline-block;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.latest-blog h2 {
color: #292929;
    font-size: 42px;
    font-weight: 500;
    margin-bottom: 0px;
    padding: 5px 25px 25px 25px;
    position: relative;
    display: block;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 0px;
    min-width: 250px;

	
}


.post-date {
	color: #5e5f5f;
	font-weight: normal;
	font-size: 13px;
}
.latest-blog a {
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: normal;
	margin: 0px;
}
.latest-blog a:hover {
	color: #000;
	text-decoration: none;
}
.blog_post img {
	max-width: 100%;
	height: auto;
}
.latest-blog p {
	font-size: 14px;
	line-height: 20px;
	margin: 0px 0px 0px 0px;
	text-align: center;
	color: #777
}
.blog_post img {
	margin-bottom: 20px;
	width: 100%;
}
.latest-blog .blog-img {
	display: block;
	overflow: hidden;
	border-radius: 10px;
}
.latest-blog .blog-img img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
.latest-blog .blog-img:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	-moz-transform: scale(1.2);
}
.blog-img img {
	-webkit-transition: all 0.4s ease-in-out 0.5s;
	-moz-transition: all 0.4s ease-in-out 0.5s;
	-o-transition: all 0.4s ease-in-out 0.5s;
	-ms-transition: all 0.4s ease-in-out 0.5s;
	transition: all 0.4s ease-in-out 0.5s;
}
.blog-img .mask {
	background-color: #000;
	opacity: 0;
	-webkit-transition: all 0.3s ease-in 0.4s;
	-moz-transition: all 0.3s ease-in 0.4s;
	-o-transition: all 0.3s ease-in 0.4s;
	-ms-transition: all 0.3s ease-in 0.4s;
	transition: all 0.3s ease-in 0.4s;
}
.blog-img a.info {
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out 0.1s;
	-moz-transition: all 0.3s ease-in-out 0.1s;
	-o-transition: all 0.3s ease-in-out 0.1s;
	-ms-transition: all 0.3s ease-in-out 0.1s;
	transition: all 0.3s ease-in-out 0.1s;
	-webkit-transform: translateY(70px);
	-moz-transform: translateY(70px);
	-o-transform: translateY(70px);
	-ms-transform: translateY(70px);
	transform: translateY(70px);
}
.blog-img:hover .mask {
	background-color: rgba(0,0,0,0.5);
	opacity: 1;
	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	-o-transition-delay: 0s;
	-ms-transition-delay: 0s;
	transition-delay: 0s;
	border-radius:10px
}
.blog-img:hover a.info {
	opacity: 1;
	-webkit-transform: translateY(70px);
	-moz-transform: translateY(70px);
	-o-transform: translateY(70px);
	-ms-transform: translateY(70px);
	transform: translateY(70px);
	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	-ms-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.blog-img .mask, .blog-img .content {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	text-align: center;
}
.blog-img a.info {
	display: inline-block;
	text-decoration: none;
	padding: 12px 25px;
	color: #97bc77;
	font-family:'Rubik', sans-serif;
	font-weight: 500;
	font-size: 14px;
	border:none;
	text-transform: uppercase;
	margin-top: 10%;
	border-radius:999px;
	background:#fff;
	letter-spacing:0.5px;
	line-height:normal
}
.blog-img a.info:hover {
	background: #97bc77;
	color: #fff;
}
.blog-img {
	position: relative;
}

.blog-info a.info {margin-top:18px; display:block; text-decoration:underline; color:#97bc77; font-weight: 400; font-family: 'Rubik', sans-serif; letter-spacing:0.2px}
/* blog-End */


/* services */
.mid-section  {
	background: url(../static/img/mid-img.jpg) no-repeat 0 0px;
	padding: 80px 0px 90px 0px;
	overflow: hidden;
	margin-top: 60px;

}

.mid-section .container {padding:0px}

.mid-section h2 {
    font-size: 64px;
    margin: 0px 0;
    font-weight: 500;
    color: #97bc77;
    text-align: center;
    padding-bottom: 20px;
    letter-spacing: 0.5px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

.mid-section h3 {
    font-size: 44px;
    margin: 0px 0;
    font-weight: 300;
    color: #292929;
    text-align: center;
    padding-bottom: 10px;
    letter-spacing: 0.5px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}



.spl-pro {background:#fff;border-radius:999px;padding:0px;/* margin:15px; */margin-bottom:0px;z-index: 10;margin-top:30px;text-align:center;position: relative; background:#fff}
.spl-pro:after {border:5px #97bc77 dotted; position:absolute; height:400px; width:100%; border-radius:999px; content:""; top:0; left:0;}
.spl-pro .col-lg-4 {padding:0px}
.spl-pro img {max-width:100%;border-radius:999px;display:block;text-align:center;margin:auto;}
.spl-pro .item-title a {font-size:14px; color: #131e2c; font-weight:500; font-family: 'Rubik', sans-serif; }
.mid-section .block1 {overflow:hidden;color:rgba(255,255,255,0.6);font-size:14px;margin-top: 86px;background:#685c88;border-radius:20px;padding: 25px 25px 25px 25px;}
.mid-section strong {font-weight:400; color:#fff; font-size:24px; text-transform:uppercase; font-family:'Oswald', sans-serif; display:block; margin-bottom:14px; letter-spacing:1px; line-height: 30px;}
.mid-section p {margin:0px}
.mid-section .block2 {overflow:hidden;color:rgba(255,255,255,0.7);font-size:14px;margin-top:25px;background:#6ec3f0;border-radius:20px;padding: 25px 25px 25px 25px;}
.mid-section .block3 {overflow:hidden;color:rgba(255,255,255,0.6);font-size:14px;margin-top: 86px;background:#1bbc9b;border-radius:20px;padding: 25px 25px 25px 25px;}
.mid-section .block4 {overflow:hidden;color:rgba(255,255,255,0.6);font-size:14px;margin-top:25px;background:#e31837;border-radius:20px;padding: 25px 25px 25px 25px;}
/* ======================== Features Box ============================ */

.our-features-box {
	overflow: hidden;
	padding: 0px 0;
	background: #fff;
	margin-bottom: 0px;
	margin-bottom:50px;
	margin-top:50px;
}

.our-features-box .container{ padding:0px}

.our-features-box ul {
	list-style: none;
	margin: auto;
	padding: 0;
	border: none;
	padding:0px 0px;
	overflow: hidden
}
.our-features-box li {
	float: left;
	margin-bottom: 0px;
	text-align: center;
	width: 20%;
	padding: 0;
	display: inline-block;
}
.our-features-box li.last {
	margin-right: 0;
	border:none

}

.our-features-box li.last .feature-box {margin-right:0px!important; border:none}
.our-features-box li.last a { display:block; padding:15px 15px; background:#fff; border-radius:999px; margin-bottom:10px; margin-top:10px; color:#6a9c23}
.our-features-box li.last .feature-box i {font-size:20px; margin-right:10px}


.our-features-box .feature-box {
    overflow: hidden;
    font-size: 14px;
    text-align: center;
    color: #292929;
    line-height: 1.4em;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 3px;
    padding: 25px 30px;
    border-right: 1px #eaeaea solid;
	
}


.our-features-box .feature-box .content {
  display: block;
  text-align: center;
  line-height: 22px;

}
.our-features-box .feature-box span {
	font-size: 12px;
	display: block;
	font-weight: 400;
	text-transform: none;
	display: block;
	padding-top: 5px;
	color: #fff;
	line-height: 18px;
	font-style: italic;
}
.icon-truck:before {
	content: "\f0d1";
}
.icon-truck, .icon-money, .icon-support, .icon-return, .icon-leaf {
font-family: FontAwesome;
    display: block !important;
    font-size: 26px;
    margin: auto;
    color: #97bc77;
    width: 65px!important;
    text-align: center;
    margin-bottom: 26px;
    line-height: 60px;
    border: 2px #97bc77 dotted;
    border-radius: 999px;

}
.icon-money:before {
	content: "\f155";
}
.icon-support:before {
	content: "\f095";
}
.icon-return:before {
	content: "\f0b1";
}

.icon-leaf:before {
	content: "\f06c";
}
/* ======================== End Features Box ============================ */

/* PRODUCTS SLIDER */
.owl-item .item {
	margin: 0 15px;
}
.slider-items-products {
	position: relative;
}
.slider-items-products .item {
	text-align: center;
	margin: 0 15px;
}
.slider-items-products .item .product-image {
	display: block;
	overflow: hidden;
}
.slider-items-products .item .product-image img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
.slider-items-products .item .product-image:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	-moz-transform: scale(1.2);
}
#category-desc-slider img {
	width: 100%
}
.owl-buttons {
	opacity: 1;
}




.slider-items-products .owl-buttons .owl-prev {
position: absolute;
  right: 43px;
  top: 0%;
  margin-top: -62px;

}
.slider-items-products .owl-buttons .owl-next {
	position: absolute;
	right:0%;
	top: 0%;
	margin-top: -62px;

}
.slider-items-products .owl-buttons .owl-prev a {
	background-position: 0 100%;
	border-right: 1px solid #111;
	opacity: .3;
}
.slider-items-products .owl-buttons .owl-next a {
	background-position: 100% 100%;
	top: 40%;
	opacity: .3;
}
.slider-items-products .owl-buttons .owl-next a:before {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: bold;
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\f105";
	text-transform: none;
	font-size: 19px;
	color:#333;
	  width: 38px;
  height: 32px;
  line-height: 34px;
}
.slider-items-products .owl-buttons .owl-prev a:before {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: bold;
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\f104";
	text-transform: none;
	font-size: 19px;
	width: 38px;
    height: 32px;
    line-height: 34px;
}
.slider-items-products .owl-buttons a {
	background: #fff;
	display: block;
	width: 38px;
    height: 34px;
    line-height: 32px;
	margin: 0px 0 0 -30px;
	position: absolute;
	top: 40%;
	z-index: 5;
	color: #666;

}





.product-flexslider {
	margin: 0px 0px;
	margin-top: 0px;
}
.owl-theme .owl-controls .owl-buttons div {
	background: none !important;
}
.slider-items-products .owl-buttons a:hover {
	text-decoration: none;
	color: #333;
	opacity:1;

}

.top-cate .slider-items-products .owl-buttons .owl-prev {
    position: absolute;
    left: -20px;
    top: 45%;
    margin-top: 0;
}

.top-cate .slider-items-products .owl-buttons .owl-next {
    position: absolute;
    right: -40px;
    top: 45%;
    margin-top: 0;
}

.top-cate .slider-items-products .owl-buttons .owl-prev a {border:none; opacity:1; background:none}
.top-cate .slider-items-products .owl-buttons a:hover {opacity:1}
.top-cate .slider-items-products .owl-buttons .owl-next a {border:none; opacity:1; background:none}
.top-cate .slider-items-products .owl-buttons a:hover {opacity:1}
.top-cate .slider-items-products .owl-buttons .owl-next a:before, .top-cate .slider-items-products .owl-buttons .owl-prev a:before {font-size:42px}

/*	Breadcrumbs */
.breadcrumbs {
	padding: 3px 0px 10px 0px;
	margin: auto;
	font-size: 12px;
	color: #fff;
	font-family:'Rubik', sans-serif
}
.breadcrumbs a {
	color: #fff;
	font-size: 12px;
	font-weight: 400;
}
.breadcrumbs span {
	display: inline-block;
	margin: 0 5px 0 5px;
	color: #aaa;
	font-size: 15px;
	font-weight:300
}
.breadcrumbs ul {
	margin: 0px;
	padding: 0px;
}
.breadcrumbs ul li {
	list-style-type: none;
	display: inline;
}
.breadcrumbs strong {
	font-weight: 500;
	color: #fff !important;
}
.slider-items-products .owl-buttons .owl-prev a {
	background-position: 0 100%;
}
/* Sidebar */

.button, .btn, .button-inverse, .button-vote, .sidebar .button, .sidebar .btn, .dropdown .dropdown-menu .button, .buttons-set .back-link a, .scrolltop, .button-tabs li a {
	font-family: 'Open Sans', sans-serif;
	border: 1px #ddd solid;
	background: #fff;
	padding: 5px 12px;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.btn:hover, .button-inverse, .button-vote:hover, .sidebar .button:hover, .sidebar .button:focus, .sidebar .btn:hover, .sidebar .btn:focus, .form-list .bootstrap-select.btn-group .btn:hover .caret, .form-list .bootstrap-select.btn-group .btn:focus .caret, .buttons-set .back-link a:hover, .buttons-set .back-link a:focus, .scrolltop, .button-tabs li a:hover, .button-tabs li a:focus {
	background: #131e2c;
}
.button-checkout:before {
	content: "\f00c";
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 5px;
}
.button-compare:before {
	content: "\f074";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 5px;
}
.button-clear:before {
	content: "\f12d";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 5px;
}
.button-subscribe:before {
	content: "\f0e0";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 5px;
}
.button-vote:before {
	content: "\f164";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 5px;
}
.block {
	margin: 0 0 35px;
}
.sidebar .block {
	    padding: 0px;
    position: relative;
    background-color: #fff;
    margin-top: 30px;
	margin-bottom:0px
}

.block .block-title {
    font-size: 18px;
    letter-spacing: 0.05em;
    font-weight: 400;
    padding: 10px 15px;
    position: relative;
    display: block;
    font-family: 'Oswald', sans-serif;
    margin-top: 0px;
    color: #fff;
    text-transform: uppercase;
    background: #97bc77;
	}


  
  
.block .block-title a {
	text-decoration: none;
}
.block .block-content .item {
	padding: 5px 0;
}
.block .btn-remove, .block .btn-edit {
	float: right;
	margin-top: 6px
}
.sidebar .block .access .btn-remove {
	float: right;
	margin-top: -38px
}
.block .actions {
	text-align: left;
	padding: 8px 0;
}
.block .actions a {
	float: right;
	margin-left: 10px;
	margin-bottom: 10px;
	font-weight: bold;
}
.block .empty {
	color: #666666;
}
/*	Category boxes */

/* Left Navigation */

.side-nav-categories {
}
.side-nav-categories ul li {
	padding: 2px 0px;
}
.side-nav-categories ul li a {
	display: inline-block;
	position: relative;
}
.side-nav-categories ul#magicat ul li a:before {
	content: "\f105";
	font-family: 'FontAwesome';
	line-height: 17px;
	margin-right: 5px;
	top: 6px;
}
.side-nav-categories ul#magicat ul li a {
	color: #666;
}
.side-nav-categories ul#magicat ul li a {
	padding-left: 12px;
}
.side-nav-categories, .side-nav-categories .block {
	    padding:0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 0px;
    background-color: #fff;
    margin-bottom: 30px;

}

.box-category > ul > li:last-child {border:none}


ul#magicat li, ul.listStyle li {
	cursor: pointer;
	display: block;
	float: left;
	list-style-type: none;
	margin: 0 0 1px 0;
	padding-left: 0px;
	position: relative;
	width: 100% !important;
	box-shadow: 0 1px 0 #ddd, 0 2px 0 #FFFFFF;
}
ul#magicat li.last {
	box-shadow: none;
}
ul#magicat li.level0 span.magicat-cat, ul.listStyle li span {
	text-align: left;
	display: inline-block;
	width: 100%;
}
ul#magicat li .open-close, .block-layered-nav dt .toggleBtn {
	display: inline-block;
	height: 30px;
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
}
.block-layered-nav dt .toggleBtn {
	cursor: pointer;
}
ul#magicat li.active > .open-close, .block-layered-nav dt .toggleBtn.highlight {
	background-position: 2px -28px;
}
ul#magicat ul {
	padding-left: 10px;
}
ul#magicat li .open-close, .block-layered-nav dt .toggleBtn {
	display: inline-block;
	height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	width: 30px;
}
ul#magicat li .open-close, .block-layered-nav dt .toggleBtn {
	background: url("../static/img/leftNav.png") no-repeat scroll 2px 3px;
}

.side-nav-categories .block-title  {
    font-size: 18px;
    letter-spacing: 0.05em;
    font-weight: 400;
    padding: 10px 15px;
	position: relative;
	display:block;
	font-family:'Oswald', sans-serif;
	margin-top:0px;
	color:#fff;
	text-transform:uppercase;
	background: #97bc77;

     }




/* Left Navigation End */

.box-category {

}
.box-category ul {
	list-style: none;
	margin: 0;
	padding: 0px;
}
.box-category > ul > li {
	display: block;
	position: relative;
	margin: 0;
	border-bottom:1px #ececec solid;
	padding:0px 15px
	
}
.box-category > ul > li:last-child {
	box-shadow: none;
}
.box-category > ul > li.last {
	display: block;
	position: relative;
	box-shadow: 0 0px 0 #000, 0 0px 0 #666;
}
.box-category > ul > li:first-child {
	border-top: 0;
}
.box-category .subDropdown {
	top: 12px;
	right: 15px;
	position: absolute;
	cursor: pointer;
	width: 16px;
	height: 16px;
	padding: 2px;
}
.box-category .subDropdown:hover {
	opacity: 0.8;
}
.box-category .subDropdown.plus {
	background-image: url("../static/img/plus-blue.png");
}
.box-category .subDropdown.minus {
	background-image: url("../static/img/minus-blue.png");
}

.box-category > ul > li ul > li .subDropdown {right:0px!important; top: 3px!important;}

.box-category ul li a {
	padding: 12px 0px;
	display: block;
	font-weight: normal;
	font-family:'Rubik', sans-serif;

}
.box-category ul li.double a {
	display: inline-block;
	margin: 0;
}
.box-category ul li {
	position: relative;
}
.box-category > ul > li > a {
	color: #292929;
	text-transform: uppercase;
	display: block;
	font-size: 14px;
	font-weight:500;
	letter-spacing:0.5px
}

.box-category > ul > li ul {
	display: none;
	padding-left: 0px !important;
	padding-bottom: 15px;
	padding-right: 0px!important
}

.box-category > ul > li > ul li {
	padding: 0px;
}

.box-category > ul > li a.active {
	font-weight: normal;
	font-family:'Rubik', sans-serif;
}
.box-category > ul > li > a.active {
	color: #f65f73;
	font-weight: 500;
	margin-bottom: 0;
	font-size: 14px;
	padding-bottom:5px;
	padding-top:14px
}
.box-category > ul > li a.active + ul {
	display: block;
}
.box-category a:hover {
	text-decoration: none;
	color: #000;
}
.box-category > ul > li ul li a {
	font-family:'Open Sans', sans-serif;
}
.box-category > ul > li ul > li > a {
	border-bottom: 0 solid #EAEAEA;
	font-size: 13px !important;
	margin: 0 !important;
	padding: 3px 3px !important;
	text-decoration: none;
	padding-left: 0px;
	color: #898989;


}

.box-category > ul > li ul > li ul > li a {
	text-decoration: none;
	font-size: 13px !important;
	margin: 4px 8px!important;
	padding: 0px 16px!important;
	border-bottom: 0px solid #EAEAEA;
}
.box-category > ul > li ul > li ul > li a:hover {
	color: #333;
}
.box-category > ul > li ul > li > a:hover {
	color: #333;
}
/* Sidebar */

.sidebar-left {
	margin-left: 0 !important;
}
.bv3 .sidebar-right, .bv3 .sidebar-left {
	padding-left: 15px;
}
.sidebar ol, .sidebar ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.sidebar .block-venedor-ads .block-content li {
	padding: 0;
}
.sidebar .block dt {
font-size: 14px;
    font-weight: 600;
    color: #333;
    padding: 10px 0;
    text-transform: none;
    letter-spacing: normal;
    font-family: 'Open Sans', sans-serif;

}
.sidebar .block dd {
	border-bottom: 0 solid #CCCCCC;
	margin: 0;
	padding: 0 0px;
}
.sidebar address {
	margin: 0;
	padding: 0;
	color: #333;
	text-align: left;
	border-bottom: none;
}
.page-title h1, .page-title h2 {
	font-family:'Oswald', sans-serif;
	font-size: 42px;
	font-weight: 500;
	text-transform: uppercase;
    margin-top: 0px;
    padding: 0px 25px;
    margin: 0px;
    display: inline-block;
    margin-top: 5px;
	letter-spacing: 1px;
}
.col-main {
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
	margin-top:30px;

}
.one-page-checkout {
	list-style: none;
    padding: 0px 0;
    position: relative;
    margin-top: 0px;
    padding-bottom: 30px;
    background-color: #fff;
	margin-bottom:0px

}
.one-page-checkout li.section {
	border-bottom: 0;
	border-bottom:1px #eaeaea solid
}
.one-page-checkout .step-title {
	text-align: right;
	padding: 12px 0;
}
.one-page-checkout .active .step-title {
	cursor: default;
	font-size: 14px;
	padding-top: 0px;
	text-align: left;
	line-height: 30px;
	font-family: 'Open Sans', sans-serif;
	border-top: none;
}
.one-page-checkout .step-title .number {
	float: left;
	line-height: 15px;
	font-size: 14px;
	color: #333;
	padding: 10px 14px;
	background-color: #fff;
	border:1px #e5e5e5 solid;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	border-radius: 999px;
}
.one-page-checkout .step-title h3 {
	border-bottom: 0px solid #E4E4E4;
	margin: 0 0 0 55px;
	text-align: left;
	line-height: 38px;
	font-size: 14px;
	font-family:'Rubik', sans-serif;
	color: #333;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing:1px
}
.one-page-checkout .active .step-title .number {
	background: none repeat scroll 0 0 #f65f73;
	border: 1px solid #f65f73;
	color: #fff;
	padding: 10px 14px;
	font-weight: bold;
	border-radius:999px
}
.one-page-checkout .active .step-title h3 {
	color: #f65f73;
	font-size: 14px;
	font-family:'Rubik', sans-serif;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing:1px
}
.one-page-checkout .step {
	padding: 0px 10px 20px 55px;
	background-color: #FFFFFF;
}
#checkoutSteps .required {
	color: #F00;
}
fieldset {
	border: 0 none;
	margin: 0;
	padding: 0;
}
.group-select li {
	margin: 0 0 10px;
	overflow: hidden;
	list-style: none;
}
.group-select input[type="radio"], .group-select input[type="checkbox"] {
	line-height: normal;
	margin: 4px 8px 0 0;
}

.group-select input[type="radio"] {margin:0px 0px 0px 0px; vertical-align: middle}

.group-select label {
	font-size: 13px;
	font-weight: normal;
	color: #333;
	margin:0px;
	margin-right:10px
}
.group-select li select {
	/*width: 256px;*/
	padding: 3px;
	background: #fff;
	border: 1px solid #d1d1d1;
	padding: 10px;
	width: 95%;
}
select#billing-address-select {
	width: 570px;
	padding: 10px 15px;
	margin-top: 5px;
	background: #fff;
	border: 1px solid #d1d1d1;
	outline: none;
	color: #333;
}
legend {
	display: none;
}
.group-select li .input-box {
	float: left;
	width: 50%;
}
.group-select .require {
	color: #FF0000;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
}
input.radio {
	margin: 0;
    margin-right: 5px;
    vertical-align: middle;
}
.radio, .checkbox {
	display: inline-block;
}
button.button.continue:before {
	content: "\f061";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
.dashboard .welcome-msg {
	text-align: left;
	padding: 0px;
	color: #333;
	font-size: 13px;
	margin:0px;
	margin-bottom:15px
}
.dashboard .welcome-msg strong {
	font-weight: 600;
	font-size: 15px;

}
.data-table {
	border: 1px solid #E5E5E5;
	border-spacing: 0;
	text-align: left;
	width: 100%;
	font-size: 13px;
}
#my-orders-table {
	border: none;
	margin-top: 10px;
}
.my-account {
	    padding:0px 0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 30px;
    background-color: #fff;

 
}
.title-buttons a, .title-buttons .separator {
	margin-top: 0;
	display: inline-block;
	text-decoration: underline;
	color: #f65f73;
	font-size:12px
}
.recent-orders .title-buttons a {
	float: right;
}
.data-table thead tr, .data-table tfoot tr {

}
.data-table th {
	line-height: 20px;
	padding: 10px 20px;
	font-weight: 500;
	font-size: 13px;
	font-family:'Rubik', sans-serif;
	text-transform: uppercase;
}
.data-table thead td, .data-table tfoot th, .data-table tfoot td, .cart .totals table th, .cart .totals table td {
	border-top: 0px solid #ddd;
	border-bottom: 1px solid #e5e5e5;
	font-family: 'Open Sans', sans-serif;
}
.data-table .price {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}
.group-select li input.input-text, .group-select li textarea {
	/*width: 585px;*/
	background: #fff;
	border: 1px solid #d1d1d1;
	padding: 10px 15px;
	outline: none;
	width: 98%;
}
.group-select li .input-box input.input-text, .group-select li .input-box textarea {
	/*width: 275px;*/
	margin-top: 5px;
	background: #fff;
	border: 1px solid #d1d1d1;
	padding: 10px 15px;
	width: 95%;
}
.group-select li .input-box input.input-text:focus, .group-select li .input-box textarea:focus {
	background: #fff;
	border: 1px solid #ddd;
}
.group-select li input.input-text:focus, .group-select li textarea:focus {
	background: #fff;
	border: 1px solid #ddd;
	outline: none;
}
.box-account {
	margin-top: 0px;
	font-size: 14px;
	padding:20px 0
}
.col2-right-layout {
	margin-top: 10px;
}
.box-account .page-title h2 {
	font-size: 14px;
	margin-top:15px;
	padding:0px;
	font-family: 'Rubik', sans-serif;
}
.col2-set {
	overflow: hidden;
	padding: 0px;
}
.col2-set h2 {
	font-size: 16px;
}
.col2-set .col-1 {
	float: left;
	width: 48%;
	text-align: left;
	border: 0px #336600 solid;
	padding-top: 0px;
	padding-bottom: 0px;
}
.col2-set .col-1 ul.ul {
	margin: auto;
	padding-left: 17px;
}
.col2-set .col-1 ul li {
	list-style: disc;
}
.col2-set h5 {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 5px;
	color: #333;

}
.box-account .col2-set a {
	text-decoration: underline;
}
.col2-set .col-2 {
	float: right;
	width: 48%;
	text-align: left;
}
.box-account address {
	line-height: 21px;
	color: #000;
	border-bottom: none;
}
.col2-set h4 {
	font-size: 13px;
	font-weight: normal;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	margin-bottom: 5px;
}
.block .slider-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.block-layered-nav .block-subtitle {
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
}
.block-layered-nav dt {
	font-size: 16px;
}
.block-layered-nav .currently ol {
	margin: 0;
	padding: 10px 15px;
}
.block-layered-nav .currently span.value {
	font-weight: bold;
}
.block-layered-nav li {
	padding: 3px 0;
	color:#999
}
.block-layered-nav .label {
	margin-right: 5px;
}
.block-layered-nav .button-arrow {
	width: 25px;
	height: 25px;
	padding: 0;
	margin-top: -2px;
	position: absolute;
	right: 15px;
}
#narrow-by-list2 dd {
	display: block !important;
	
}
.sidebar {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	margin-top: 30px;
	padding-left:0px
}

.col-right.sidebar {padding-left:15px; margin-top:0px}

.sidebar .block-layered-nav .price {
	font-size: 13px;
	font-weight: normal;
}
.block-layered-nav .block-subtitle, .block-layered-nav dt {
	    color: #333;
    text-transform: uppercase;
    display: block;
    font-size: 14px;
    font-weight: 500;
    font-family:'Rubik', sans-serif;
}
.block-layered-nav .currently ol {
	border: 1px solid #ececec;
	background-color: #f4f4f4;
	border-radius: 0px;
}
.sidebar a, .sidebar .block-layered-nav .price .sub {
	color: #898989;
	font-size: 13px;
	font-weight:400
}
.sidebar .block-layered-nav .price {
	color: #666;
	font-family: 'Open Sans', sans-serif;
}
.sidebar .link-cart, .sidebar .link-reorder, .block .actions a {
	color: #CA0000;
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	font-size: 14px;
}
.sidebar .link-compare, .sidebar .link-wishlist {
	color: #aaa;
	font-size: 14px;
}
.block-tags .actions a.view-all {
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	color: #333;
	font-weight: 900;
}
.block-tags .actions a.view-all:hover {
	color: #333;
}
.sidebar .link-cart:hover, .sidebar .link-wishlist:hover, .sidebar .link-reorder:hover, .sidebar .link-compare:hover, .sidebar .link-cart:focus, .sidebar .link-wishlist:focus, .sidebar .link-reorder:focus, .sidebar .link-compare:focus, .block .actions a:hover, .block .actions a:focus {
	color: #333;
}
.sidebar .block dl {
	border-bottom: 0px solid #ccc;
	margin: 0;
}
.block .block-content .price {
	font-size: 13px;
	font-weight: 400;
	color: #333;
}
/* Block: Account */
.block-account {
}
.block-account .block-content {
	padding-top: 0 !important;
	padding-bottom: 5px !important;
}
.block-account .block-content ul {
	margin-top: 5px;
	margin-bottom: 5px;
}
.block-account .block-content li {
	padding: 5px 0px;
}
.block-account .block-content li:first-child {
	border-top: none;
}

.sidebar .block-content li.last {
	border-bottom: none;
}
.block-account .block-content li a {
	cursor: pointer;
	padding: 8px 5x;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;

	
}
.block-account .block-content li a:hover {
	cursor: pointer;
	padding: 0 12px;
	color: #000;
}
.sidebar .block-content li.current {
	font-weight: 900;
	color: #333;
}
/* Block: Currency Switcher */

.block-currency select {
	width: 100%;
	margin-right: 8px;
}
/* Block: Layered Navigation */
.block-layered-nav {
	background-color: #FFFFFF;
}
.block-layered-nav .block-content {
	padding: 0;
}
.block-layered-nav dt {
	font-weight: bold;
	overflow: hidden;
	padding: 0px 0 0px !important;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.block-layered-nav dd {
	margin: 0;
	padding: 0;
}
.block-layered-nav dd ol {
	position: relative;
	margin-bottom:15px
}

.block-layered-nav .btn-remove {
	float: right;
}
.block-list .price {
	color: inherit;
	font-size: 14px;
}
/* Block: Cart */
.block-cart {
	padding: 0px;
	background-color: #FFFFFF;

}
.block-cart .amount {
	margin-bottom: 5px;
	text-align:center
}
.block-cart .actions .paypal-logo {
	float: left;
	width: 100%;
	margin: 3px 0 0;
	text-align: right;
}
.block-cart .actions .paypal-logo .paypal-or {
	clear: both;
	display: block;
	padding: 0 55px 5px 0;
}
.block-cart .mini-products-list .product-details .product-name, .block-cart .mini-products-list .product-details .nobr small {
	word-wrap: break-word;
}
.block-cart .mini-products-list .product-details .nobr {
	white-space: normal !important;
}
.block-cart .summary {
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
}
.block-cart .subtotal {
	margin: 0;
	padding: 0;
	text-align: center;
	margin-bottom: 8px
}
.block-cart .block-content .summary .subtotal .label {
	background: none repeat scroll 0 0 transparent;
	border-radius: 0 0 0 0;
	box-shadow: none;
	font-weight: normal;
	padding: 2px 0;
	text-shadow: none;
	color: #000;
	font-size:14px;
}
.block-cart .subtotal .price {
	color: #f65f73;
	font-size: 14px;
	font-weight: 600;
}
.ajax-checkout {
	border: 0 none;
	margin: 0;
	padding: 5px 0 5px;
	text-align: center;
	margin-bottom: 0px;
}
.ajax-checkout a {
	border: 0px #dc436b solid;
	background: #323C46;
	text-shadow: 0 1px 0 #333;
	border-radius: 0px;
	color: #FFFFFF;
	font-size: 11px;
	padding: 8px 12px;
	font-family:'Montserrat', sans-serif;
	text-transform: uppercase;
}
.ajax-checkout a:hover {
	border: 0px #111 solid;
	background: #222;
	padding: 6px 10px;
	text-shadow: 0 1px 0 #000;
	border-radius: 0px;
	color: #FFFFFF;
	font-size: 11px;
	cursor: pointer;
}

.sidebar .block-cart .button-checkout {background:#f65f73; border-radius:999px; color:#fff; font-family: 'Rubik', sans-serif; border:none; padding: 10px 20px; line-height:normal; letter-spacing:1px}
.sidebar .block-cart .button-checkout span {font-size:14px; font-weight:500}

.sidebar .block-cart .block-subtitle {
    margin: 20px 0 8px;
    font-weight: 600;
    font-size: 14px;
}
.col-left .block-cart .block-content ul li .product-details {
	float: right;
	width: 62%;
	margin-left: 0px;
}
.col-left .block-cart .block-content ul li .product-details .access {
	float: right
}

.col-left .block-cart .block-content ul li .product-details strong {font-weight:normal}

.col-left .block-cart .block-content ul li .product-details .access .btn-remove1 {
	position: inherit;
}
.col-left .block-cart ul li.item {
	border-bottom: 1px solid #ECECEC;
	margin-bottom: 10px;
	padding-bottom: 12px;
	overflow: hidden;
}
.block-cart ul li.item img {
	width: 80px;
}
.col-left .block-cart ul li.item:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}
.col-left .block-cart .block-content ul li a.product-image {
	display: inline;
}
.col-left .block-cart .block-content ul li a.product-image img {
	border: 1px solid #ECECEC;
}
.col-left .block-cart .block-content ul li .product-name {
	margin: 0 0 2px;
	font-size: 12px;
	line-height: 17px;
}
.col-left .block-cart .block-content ul li .product-name a {
	font-size: 14px;
	margin-top:3px;
	display:block;
	color:#292929
}
.col-right .block-cart .block-content ul li .product-details {
	float: right;
	width: 172px;
}
.col-right .block-cart ul li.item {
	border-bottom: 1px solid #ECECEC;
	margin-bottom: 5px;
	padding-bottom: 8px;
}
.col-right .block-cart ul li.item.last {
	border-bottom: none;
	margin-bottom: 5px;
	padding-bottom: 0px;
}
.col-right .block-cart .block-content ul li a.product-image {
	display: inline;
}
.col-right .block-cart .block-content ul li a.product-image img {
	border: 1px #ddd solid;
}
.col-right .block-cart .block-content ul li .product-name {
	margin: 0 0 2px;
	font-size: 14px;
}
.price, .breadcrumbs li strong, .product-details span.price, #recently-viewed-items .price-box .special-price .price, #recently-viewed-items .price-box .regular-price .price, .availability.out-of-stock span {
	color: #FF4C4C;
}
/* Block: Related */
.related-slider {
	float: right;
	margin: auto;
	margin-bottom: 15px;
}
.related-block .block-title {
	background: none repeat scroll 0 0 #fff;
	border-bottom: 1px solid #e5e5e5;
	color: #000000;
	font-size: 14px;
	font-weight: bold;
	margin: 0;
	padding: 10px;
	text-align: left;
	text-transform: uppercase;
}
.related-block {
	border: 1px solid #e5e5e5;
	margin-bottom: 20px;
	margin-top: 25px;
}
.related-slider .slider-items-products .owl-buttons a {
	height: 41px;
	margin: -32px 0 0;
	top: 50%;
	width: 40px;
}
.related-slider .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 24px;
	line-height: 38px;
}
.related-slider .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 24px;
	line-height: 38px;
}
.related-slider .slider-items-products .owl-buttons .owl-prev a {
	left: -8px;
}
.related-slider .slider-items-products .owl-buttons .owl-next a {
	left: -1px
}
.related-slider button.button.btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
}
.related-slider .products-grid .item .item-inner .item-img .item-img-info {
	border: none;
}
.related-slider h2 {
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
	margin: 0px 0 20px;
	text-align: left;
	text-shadow: 0 1px 0 #fff;
}
.block-related .block-content {
	padding: 0px 0 10px 0;
	border: 1px solid #ECECEC;
	border-top: none;
	overflow: hidden;
}
.block-related ul {
	margin: 0;
	padding: 0;
}
.block-related li {
	padding: 5px 0;
}
.block-related input.checkbox {
	position: absolute;
	left: 85px;
	top: 12px;
	z-index: 10;
}
.block-related .product {
	margin-left: 20px;
}
.block-related .product .product-image {
	float: left;
	margin-right: -65px;
}
.block-related .product .product-details {
	margin-left: 65px;
}
.block-related .mini-products-list .product-details {
	margin-left: 125px;
	text-align: left;
}
.block-related .mini-products-list .product-details .product-name {
	text-align: left;
}
.block-related .mini-products-list .product-image {
	width: 100px;
	margin: 0;
	padding: 0 10px;
}
.block-related .block-subtitle {
	padding: 10px 10px 0 10px;
	font-size: 13px;
	font-weight: bold;
	margin: 0;
}
.block-related .price-box .old-price .price {
	display: inline;
	font-size: 15px;
	font-family: 'Open Sans', sans-serif;
}
.block-related a.link-cart {
	text-transform: uppercase;
	font-size: 14px;
	color: #23AEAC;
	font-weight: bold;
	font-family: 'Open Sans', sans-serif;
}
.block-related a.link-cart:hover {
	text-transform: uppercase;
	font-size: 14px;
	color: #CA0000;
	font-weight: bold;
	font-family: 'Open Sans', sans-serif;
}
/* Block: Compare Products */
.block-compare {
	background-color: #FFFFFF;

}
.block-compare li {
	padding: 5px 0;
}
.block-compare .block-content ol, .block-compare .block-content ul {
	margin: 0;
}
ol#compare-items li a.product-name {
    font-size: 14px;
    margin-top: 3px;
    display: block;
	display: inline-block;
	width: 200px;
	color:#292929
}
ol#compare-items li a.product-name span {
	margin-right: 3px;
	font-size: 20px;
	font-weight: 400;
	color: #777;
	line-height: 15px;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 6px;
}

ol#compare-items li {
	margin: 0;
	padding: 0 0 10px;
	position: relative;
	border-bottom: 1px solid #ECECEC;
	margin-bottom: 10px;
	background: none;
}

ol#compare-items li:last-child {
	border-bottom: none;
	margin-bottom: 0;
}

.block-compare .button-compare {
    background: #f65f73;
    border-radius: 999px;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    border: none;
    padding: 10px 30px;
    line-height: normal;
    letter-spacing: 1px;
	border:none;
	margin-top:20px
}

/* Block: Recently Viewed */
.block-viewed .block-content {
	background-color: #fff;
	padding: 0px;
}
.block-viewed li {
	padding: 3px 0px !important;
	list-style: none;
}
.block-viewed li.last {
	padding: 0px;
	border-bottom: 0px #eaeaea solid;
	list-style: none;
}
.block-viewed .block-content li a {
	padding: 0 15px;
	color: #666;
}
.block-viewed .block-content li a:hover {
	font-size: 13px;
	color: #000;
}
.block-viewed li span {
	color: #777777;
	float: left;
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 15px;
	margin-bottom: 8px;
	margin-right: 3px;
}
.block-viewed li:before {
	content: '\f105';
	font-family: FontAwesome;
	font-size: 14px;
	padding-right: 6px;
	color: #444;
	vertical-align: top;
}
.block-compared li:before {
	content: '\f105';
	font-family: FontAwesome;
	font-size: 14px;
	padding-right: 6px;
	color: #444;
	vertical-align: top;
}
.sidebar ol, .sidebar ul {
	list-style: none;
	padding: 0;
	margin: 0;
	padding: 0
}
#recently-viewed-items .product-name {
	text-align: left;
	margin: 0;
	text-transform: none;
	width: 225px;
	display: inline-block
}
#recently-viewed-items .product-name a {
	color: #333;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
	font-family: 'Open Sans', sans-serif;
	padding: 0 0px;
}
#recently-viewed-items .product-name a:hover {
	color: #000;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
}
/* Block: Recently Compared */
.block-compared .block-content {
	background-color: #fff;
	padding: 5px 12px 12px;
}
.block-compared li {
	padding: 3px 0px !important;
	list-style: none;
}
.block-compared li.last {
	padding: 0px;
	border-bottom: 0px #eaeaea solid;
	list-style: none;
}
.block-compared .block-content li a {
	padding: 0 15px;
	color: #666;
}
.block-compared .block-content li a:hover {
	font-size: 13px;
	color: #000;
}
.block-compared li span {
	color: #777777;
	float: left;
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 15px;
	margin-bottom: 8px;
	margin-right: 3px;
}
.block-compared li:before {
	content: '\f105';
	font-family: FontAwesome;
	font-size: 14px;
	padding-right: 6px;
	color: #444;
	vertical-align: top;
}
#recently-compared-items .product-name {
	text-align: left;
	margin: 0;
	text-transform: none;
	width: 225px;
	display: inline-block
}
#recently-compared-items .product-name a {
	color: #333;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
	font-family: 'Open Sans', sans-serif;
	padding: 0 0px;
}
#recently-compared-items .product-name a:hover {
	color: #000;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
}
/* Block: Poll */
.block-poll {
	background-color: #FFFFFF;
	border: 1px #ddd solid;
}
.block-poll label {
	margin-bottom: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	color: #333;
	font-weight: normal;
}
.block-poll .block-subtitle {
	color: #222;
	font-weight: 600;
	font-size: 14px;
}
.block-poll input.radio {
	float: left;
	margin: 2px -18px 0 0;
}
.block-poll .label {
	display: block;
	margin-left: 18px;
	text-align: left;
	font-family: 'Open Sans', sans-serif;
	padding: 0.1em 0.3em 0.1em;
	line-height:1.2em
}
.block-poll li {
	padding: 3px 0px !important;
	background: none !important;
}
.block-poll .actions {
	margin: 5px 0 0 0px;
	padding: 0;
}
.block-poll .answer {
	font-weight: bold;
}
.block-poll .votes {
	float: right;
	margin-left: 10px;
}
/* Block: Tags */
.block-tags {
	background-color: #fff;
	padding: 0px;
}
.block-tags .block-content {
	overflow: hidden;
}
.block-tags ul, .block-tags li {
	display: inline;
	padding: 0 !important;
	background: none !important;
	border: none;
}
.block-tags .actions {
	border: 0 none;
	float: right !important;
	margin-top: 10px;
	padding: 0px;
	text-align: left;
}
/* Block: Subscribe */
.block-subscribe {
	background-color: #fff;
	padding: 0px;
}
.block-subscribe .input-text {
	width: 90%;
	background: #f7f7f7;
	border: 1px solid #f7f7f7;
	color: #AAAAAA;
	padding: 10px 7px;
	font-size: 13px;
}
.block-subscribe .input-text:focus {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 1px solid #DDDDDD;
	color: #333333;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	outline: medium none;
	padding: 10px 7px;
	vertical-align: middle;
}
.form-subscribe-header {
	font-size: 14px;
	margin-bottom: 5px;
	color: #222;
}
.static-contain {
    padding: 0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 45px;
    background-color: #fff;

}
.static-contain .required {
	color: #F00;
}

#contactForm textarea {border-radius:999px}

/* Block: Recently Viewed */
.block-company {
}
.block-company .block-title {
}
.block-company .block-content {
	padding-top: 5px !important;
}
.block-company ol {
	margin: 5px 0;
}
.block-company li {
	padding: 6px 0px !important;
	list-style: none;
}
.block-company li:first-child {
	border-top: none
}
.block-company li a {
	padding: 0 0px;
	text-decoration: none;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.block-company li a:hover {
	padding: 0 5px;
	color: #000;
}

.block-company li.last {
	padding: 5px 0px !important;
	border-bottom: 0px #eaeaea solid;
	list-style: none;
}
.block-company li span {
	float: left;
	margin-right: 5px;
	font-size: 16px;
	color: #aaa;
	line-height: 16px;
	font-family: 'Open Sans', sans-serif;
}
.block-company li strong {
	color: #97bc77;
	font-weight: 600;
	padding: 0 0px;
}
/* Block: Reorder */

.block-reorder {
}
.block-reorder li {
	padding: 5px 0;
}
.block-reorder input.checkbox {
	float: left;
	margin: 3px -20px 0 0;
}
.block-reorder .product-name {
	margin-left: 20px;
}
/* Block: Banner */

.block-banner .block-content {
	text-align: center;
}
.block-banner a img {
	transition: all 0.4s ease-out 0.2s;
}
.block-banner a img:hover {
	opacity: 0.5;
	-webkit-transition: all 0.4s ease-out 0.2s;
	-moz-transition: all 0.4s ease-out 0.2s;
	-o-transition: all 0.4s ease-out 0.2s;
	transition: all 0.4s ease-out 0.2s;
	background-color: #eee;
}
.category-description a.prev, .category-description a.next {
	margin: 125px 6px 0px 5px;
}
/* Pager */
.pager {
	margin: 0;
	text-align: center;
	padding: 0;
}
.pager .amount {
	float: left;
}
.pager .limiter label {
	float: left;
	font-size: 11px;
	padding: 6px 0;
	margin-right: 10px;
	display: inline;
	text-transform:uppercase
}
.pager .pages {
	margin: 0 0;
}
.pages .pagination {
	margin: auto;
}
.pager .pages li {
	display: inline-block;
}
.pager .pages li a {
	box-shadow: none;
	border: 1px solid #ddd;
	padding: 5px 10px;
	border-radius: 0px;
	color: #333;
	background: #fff
}
.pager .pages li a:hover {
	border: 1px solid #f65f73;
	color: #fff;
	background: #f65f73;
}
.pages .pagination > .active > a, .pages .pagination > .active > span, .pages .pagination > .active > a:hover, .pages .pagination > .active > span:hover, .pages .pagination > .active > a:focus, .pages .pagination > .active > span:focus {
	z-index: 2;
	color: #fff;
	cursor: default;
	background-color: #97bc77;
	border-color: #97bc77;
	border-radius: 0px;
	border-width: 1px;
}
/* Sorter */
.sorter {
	padding: 0;
	margin: 0;
}
.sorter .actions {
	float: left;
}
.sorter .view-mode {
	float: left;
	margin-top: 6px;
	margin-right: 20px;
	margin-left: 0px;
}
.sorter .sort-by {
	float: left;
	margin-top: 6px;
}
/* Toolbar */
.toolbar {
    clear: both;
    margin-top: 0px;
    padding: 20px 0px;
    display: block;
    width: 100%;
    height: 52px;
    padding-bottom: 0;
}

.toolbar.bottom {padding: 0px 0px;padding-top:20px;border:none;height: 58px;}

.toolbar .display-product-option .pages {float:left}

.toolbar .display-product-option .pages li a {
    box-shadow: none;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 0px;
    color: #333;
    background: #fff;
}

.toolbar .display-product-option .pages li {
    display: inline-block;
}

.toolbar .display-product-option .pages li.active > a{
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #97bc77;
    border-color: #97bc77;
    border-radius: 0px;
    border-width: 1px;
}

.toolbar .sorter {
	float: left;
}
.toolbar-bottom {
	margin-top: 0;
}
.toolbar .pager .pages ol {
	float: none;
}
.sort-by .button-asc {
	background-color: #fff;
	box-shadow: none;
	float: right;
	height: 30px;
	line-height: 30px;
	margin-left: 5px;
	text-align: center;
	width: 35px;
	border: 1px #ddd solid;
}
.sort-by .button-asc:hover {
	background: #f65f73;
	color: #fff;
	border-color: #f65f73
}
.top_arrow:before {
	content: '\f106';
	font-family: FontAwesome;
	font-size: 16px;
}
.sort-by .button-desc {
	background-color: #f8f8f8;
	box-shadow: none;
	float: right;
	height: 30px;
	line-height: 28px;
	margin-left: 5px;
	text-align: center;
	width: 30px;
}
.sort-by {
	float: right;
	font-size: 13px;
}
.sort-by ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: inline;
}
.sort-by a:link, .sort-by a:visited {
	color: #333;
	text-decoration: none;
}
.sort-by li {
	background: none repeat scroll 0 0 #fff;
	cursor: pointer;
	margin: 0 auto;
	outline: medium none;
	padding: 4px 15px 4px 15px;
	position: relative;
	width: 100px;
	float: left;
	color: #333;
	border: 1px solid #ddd;
}
.sort-by li li {
	width: 100px;
	margin-top: 0px;
	border-top: none;
	border-right: none;
	border-left: none;
}
.sort-by li li a:hover {
	color: #f65f73
}
.sort-by li:hover {
	background-color: #fff;
}
.sort-by li li:hover {
	background-color: #fff;
	color: #f65f73;
}
.sort-by ul ul {
	position: absolute;
	visibility: hidden;
	left: -1px;
	top: 27px;
	background: none repeat scroll 0 0 #F8F8F8;
	z-index: 10000;
	border-top: none;
	border: 1px #ddd solid;
	border-top:none
}
.sort-by ul ul ul {
	position: absolute;
	left: 100%;
	top: -2px;
	border: solid 1px transparent;
}
.sort-by li:hover > ul {
	visibility: visible;
}
.right-arrow {
	float: right;
}
.right-arrow:after {

	content: "\f107";
	font-family: 'FontAwesome';
	color: #666;
	font-size: 14px;
}
.limiter {
	float: right;
	font-size: 14px;
	margin-top: 0px;
}
.limiter ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: inline;
}
.limiter a:link, #limiter a:visited {
	text-decoration: none;
	background-color: #fff;
	border: 0px solid #ddd;
	border-radius: 0px;
	display: inline-block;
	padding: 0px;
}
.limiter li {
	background: none repeat scroll 0 0 #fff;
	cursor: pointer;
	margin: 0 auto;
	outline: medium none;
	padding: 4px 0px 4px 10px;
	position: relative;
	width: 50px;
	float: left;
	color: #333;
	text-align: left;
	border: 1px solid #ddd;
}
.limiter .right-arrow {
	float: right;
	margin-left: 6px;
}
.limiter li li {
	width: 50px;
	border-top: 0px #f5f5f5 solid;
}
.limiter li li a:hover {
	color: #f65f73;
}
.limiter li:hover {
	color: #fff;
}
.limiter ul ul {
	position: absolute;
	visibility: hidden;
	left: -1px;
	top: 27px;
	background: none repeat scroll 0 0 #fff;
	z-index: 10000;
	color: #000;
	border-top: none;
	border: 0px #f5f5f5 solid;
}
.limiter ul ul ul {
	position: absolute;
	left: 100%;
	top: -2px;
	border: solid 1px transparent;
}
.limiter li:hover > ul {
	visibility: visible;
}
/* Category Toolbar, Pager */
.toolbar .button-list {
	background: #fff url(../static/img/icon_list.png) no-repeat 10px 7px;
	text-transform: none;
	padding: 6px 8px 5px 25px;
	color: #333;
	border: 1px #ddd solid;
	border-radius: 0px;
}
.toolbar a.button-grid {
	background: #f8f8f8 url(../static/img/icon_grid_over.png) no-repeat 12px 9px;
	border: 1px #ddd solid;
	color: #333;
	padding: 6px 8px 7px 25px;
	border-radius: 0px;
}
.toolbar span.button-active.button-grid {
	background: url(../static/img/icon_grid_act.png) no-repeat 9px 7px;
	background-color: #97bc77;
	color: #fff;
	padding: 6px 8px 4px 22px;
	margin-right: 5px;
	border: 1px #97bc77 solid;
	border-radius: 0px;
}
.toolbar span.button-active.button-list {
	background: url(../static/img/icon_list_act.png) no-repeat 10px 7px;
	background-color: #97bc77;
	color: #fff;
	padding: 6px 11px 7px 22px;
	border: 1px #97bc77 solid;
	border-radius: 0px;
}
.toolbar .pager {
	text-align: left;
	margin: 0 10px 0 0;
	padding: 0;
	float: right;
	width: 45%;
}
.pager .amount, .sorter .amount {
	padding-left: 10px;
	margin-top: 4px;
	display: inline-block;
}

.toolbar-bottom .pager .limiter {
	position: static;
}
.pager .button {
	padding: 4px 5px;
	background-color: #fff;
}
.pager .button :hover {
	padding: 4px 5px;
	background-color: #fff;
}
.toolbar .actions {
	margin-right: 30px;
}
.pager .limiter label, .toolbar label {
	display: inline;
	float: left;
	margin: 0;
	margin-right: 10px;
	padding: 5px 0;
	font-size: 11px;
	font-weight: normal;
	text-transform:uppercase;
}
.toolbar .sorter .sort-by {
	margin-right: 30px;
}
.pager .amount {
	margin-right: 20px;
}
/* Category Page */
.category-title {
	border-bottom: 1px solid #ddd;
	margin: 0 0 10px;
}
.category-image {
	display: block;
	margin: 0 auto;
	position: relative;
	margin-top: 15px;
}
.category-products {
	padding-bottom: 5px;
    overflow: hidden;
    padding: 25px;
}
/* View Type: List */
.products-list .product-name {
	text-align: left;
	margin: 0px 0 10px
}
.products-list .product-name a {
	font-size: 16px;
	color: #292929;
	font-weight: 500;
	text-align: left;
	font-family:'Rubik', sans-serif;

}
.products-list .item.first {
	overflow: hidden;
	display:block;
	padding-bottom:25px;
}
.products-list .ratings {
	padding: 10px 0px;
	padding-top:0px
}
.products-list .old-price .price {
	font-size: 14px;

}
.products-list .item {
	padding: 20px 20px;
	overflow: hidden;
	margin: 0px !important;
	border-bottom:1px #ececec solid;
	padding-bottom:25px
}
.products-list .item a:hover {
	color: #f65f73;
}
.separator {
	color: #999
}
.products-list .item h3 {
	font-weight: normal;
	color: #353535;
	padding-bottom: 2px;
}
.products-list .item h3 a {
	font-weight: normal;
	font-size: 14px;
	color: #407CBF;
	text-decoration: none
}
.products-list .item h3 a:hover {
	font-weight: normal;
	font-size: 14px;
	color: #333;
	text-decoration: underline
}
ol.products-list {
	padding: 0;
	margin: 0;
	float: left;

}
li .list {
	border-bottom: none;
}
.products-list .product-image {
	float: left;
	width: 28%;
}
.products-list .product-image img {
	width: 100%;
}
.products-list .product-shop {
	float: right;
	width: 68%;
	text-align: left;
	margin-left: 25px;
}
.products-list .product-shop .desc {
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 15px;
	margin-top: 15px;
	color: #777
}
.products-list .product-shop p {
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
	color: #666;
	line-height: 1.5em;
}
.products-list .product-shop p.old-price {
	margin-right: 4px;
}
.products-list .product-shop .price-box {
	margin: 10px 0 30px 0px;
}
.products-list .desc .link-learn {
	font-size: 13px;
	font-weight: normal;
	margin-top: 5px;
	color:#97bc77
}
.products-list .desc .link-learn:hover {
	text-decoration: underline;
}
.products-list .add-to-links {
	padding-left: 2px;
}
.add-to-links span {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	margin-left: 8px;
	
}
.products-list .product-shop button.button {
	font-family: 'Rubik', sans-serif;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	padding: 12px 20px;
	text-decoration: none;
	display: inline-block;
	margin-right: 8px;
	border-radius: 0px;
	letter-spacing: 0.5px;
	border:none;
	vertical-align:top;
	background-color: #97bc77;
	border-radius:999px;
	
}
.products-list .product-shop .btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
}
.products-list .product-shop button.button span {
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.5px
}
.products-list .product-shop .btn-cart {
	color:#fff

}
.products-list .product-shop .btn-cart:hover {
	color: #fff;
	background:#131e2c
}
.products-list .product-shop .btn-cart span:hover {
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 0.5px;

}
.products-list .product-shop .btn-cart span {
	font-size: 12px;
	font-weight: normal;
	text-transform: uppercase;
}
.link-wishlist, .link-compare {
	color: #000;
	text-shadow: none;
	border: none;
	box-shadow: none;
}
.link-wishlist:hover, .link-compare:hover {
	background-color: #252932;
	cursor: pointer;
	bottom: 10%;
	color:#fff;

	border-color:#252932
}
.link-wishlist, .link-compare, .link-friend, .link-edit {
	padding: 0;
	background: none
}
.products-list .actions .add-to-links a.link-wishlist:before {
	content: "\f004";
	font-family: 'FontAwesome';
}
.products-list .actions .add-to-links a.link-compare:before {
	content: "\f012";
	font-family: 'FontAwesome';
}
.products-list .actions .add-to-links a.link-wishlist {
	font-family: 'Rubik', sans-serif;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	padding: 12px 20px;
	text-decoration: none;
	display: inline-block;
	margin-right: 8px;
	border-radius: 0px;
	letter-spacing: 0.5px;
	border:none;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff;
	vertical-align:top;
	background-color: #efecec;
	border-radius:999px
}
.products-list .actions .add-to-links a.link-wishlist span {
	font-size: 13px;
	padding: 0px;
	font-family: 'Rubik', sans-serif;
}
.products-list .actions .add-to-links a.link-compare {
	font-family: 'Rubik', sans-serif;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	padding: 12px 20px;
	text-decoration: none;
	display: inline-block;
	margin-right: 8px;
	border-radius: 0px;
	letter-spacing: 0.5px;
	border:none;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff;
	vertical-align:top;
	background-color: #efecec;
	border-radius:999px
}
.products-list .actions .add-to-links a.link-compare span {
	font-size: 13px;
	padding: 0px;
	font-family: 'Rubik', sans-serif;
}
.products-list .actions .add-to-links a.link-wishlist:hover {
		color: #fff;
		background:#97bc77
}
.products-list .actions .add-to-links a.link-compare:hover {
        color: #fff;
		background:#97bc77
}
ul.products-grid {
	padding: 0;
	margin: 0 -13px;
}
.products-grid .actions button.button.btn-cart {
	background: #fff;
	border: 2px #eee solid;
	margin: 0px;
	padding: 7px 10px;
	color: #333;
	line-height: normal;
	margin-left: 3px;
	margin-right: 3px
}
.products-grid .item:hover .actions button.button.btn-cart {
	background: #f65f73;
	color: #FFFFFF;
	padding: 7px 10px;
	border: 2px #eee solid;
	margin-left: 3px;
	margin-right: 3px
}
.products-grid .actions button.button.btn-cart span {
	font-family: 'Open Sans', sans-serif;
	font-size: 11px;
	padding: 0px;
	font-weight: bold;
	margin-left: 0;
	text-transform: uppercase
}
a.button.detail-bnt span {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	text-transform: uppercase;
	padding: 0 2px;
	font-weight: normal;
	margin-left: 6px;
	display: none
}
/* Product View */
.product-view .product-img-box .product-image {
	margin: 0 0 13px;
}
#cloud-zoom-big {
	border: 3px #ddd solid;
	z-index: 10000 !important;
}
.product-shop button.button.btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
}
/* Product Images */
.product-view .new-label {
	margin-left:5px;
	top:0

}
.product-view .sale-label {
	    left: 10px;
		right:auto;
		top:30px;
		margin-left:5px

}
.product-view {
	margin: auto;
	position: relative;
	z-index: 0;
	margin-top: 25px;
}
.product-view .flexslider-thumb {
	margin: 10px auto 0;
	padding: 0 50px;
	position: relative
}
.product-view-compact .flexslider-thumb {
	margin: 17px auto 0
}
.product-view .flexslider-thumb .flex-viewport {
	z-index: 10
}
.product-view .flexslider-thumb .flex-direction-nav {
	top: 40%;
	left: 0;
	width: 100%;
}
.product-view .flexslider-thumb .flex-prev {
	position: absolute;
	left: 0px;
	top: -5px;
	border: 1px #ddd solid;
	width: 30px;
	height: 30px;
	text-align: center;
	-webkit-transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff;
}
.product-view .flexslider-thumb .flex-prev:hover {
	-webkit-transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 -40px 0 0 #97bc77;
	border: 1px solid #97bc77;
	color: #fff!important
}
.product-view .flexslider-thumb .flex-next {
	position: absolute;
	right: 0px;
	top: -5px;
	border: 1px #ddd solid;
	width: 30px;
	height: 30px;
	text-align: center;
	-webkit-transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff;
}
.product-view .flexslider-thumb .flex-next:hover {
	-webkit-transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.4s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 -40px 0 0 #97bc77;
	border: 1px solid #97bc77;
	color: #fff
}
.product-view .flexslider-thumb li img {
	cursor: pointer;
	-moz-user-select: none;
	border:1px #ececec solid
}
.product-view .previews-list {
	padding: 0;
	list-style-type: none;
	text-align: center
}
.product-view .previews-list li {
	width: 70px;
	margin-left: 8px;
	padding: 0;
	float: none;
	display: inline-block;
	margin-right: 10px;
}
.product-view-compact .rating {
	padding: 18px 0 21px
}
.product-view-compact .btn-toolbar {
	margin: 0;
	padding: 7px 0 22px
}
.product-view-compact .price {
	display: block;
	padding: 6px 0 20px
}
.product-view .flexslider-thumb-vertical-outer {
	margin: 0 10px 15px 0;
	width: 70px;
	float: left;
	position: relative;
	z-index: 1
}
.product-view .flexslider-thumb-vertical .flex-viewport {
	height: 300px!important
}
.product-view .flexslider-thumb-vertical .slides {
	margin: -4px 0 0;
	padding: 0;
	list-style-type: none
}
.product-view .flexslider-thumb-vertical .slides li {
	margin: 0;
	padding: 0;
	overflow: hidden
}
.product-view .flexslider-thumb-vertical .slides li img {
	width: 100%;
	padding: 4px 0;
	cursor: pointer;
	-moz-user-select: none
}
.flex-direction-nav, .slider-controls {
	-webkit-transform: translate3d(0, 0, 0)
}
.product-essential {
padding:0px;
    position: relative;
    margin-top: 0px;
    background-color: #fff;
    margin-bottom: 40px;
}
.product-collateral .box-collateral {
	margin: 0 0 15px;
}
.product-essential .product-image.no-gallery {
	margin-left: auto;
}
.product-essential .product-image img {
	height: auto;
	width: 100%;
}
.product-essential .button-viewall {
	position: absolute;
	left: 5px;
	bottom: 5px;
	text-indent: -999em;
	z-index: 9999;
}

.product-view .product-img-box .product-image .product-flexslider {
    margin: 20px 15px 0 15px;
}

.product-view .product-img-box .product-image .owl-prev {
    left: -15px;
    right: auto;
    top: 26%;
	margin:0px
}

.product-view .product-img-box .product-image .owl-next a, .product-view .product-img-box .product-image .owl-prev a {
    border: none;
    background: none;
    color: #333e48;
}

.more-views .slider-items-products .owl-buttons .owl-next a:before, .more-views .slider-items-products .owl-buttons .owl-prev a:before {
    font-size: 24px;
}

.product-view .product-img-box .product-image .owl-next {
    right: -35px;
    top: 26%;
	margin:0px
}

.product-view .product-img-box .product-image .more-views-items {
    margin: 0px 5px;
	border:1px #e5e5e5 solid
}

.more-views {

}

.product-essential .more-images {
	width: 92px;
	float: left;
	overflow: hidden;
	margin-top: -20px;
	padding-top: 20px;
	padding-bottom: 20px;
}
.product-essential .more-images .normal-list {
	margin-top: -6px;
}
.product-essential .more-images ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.product-essential .elastislide-vertical {
	margin-top: -6px;
}
.product-essential .more-images img {
	padding: 6px 0;
	width: 100%;
}
/*  Product Shop */
.product-view .product-shop .add-to-box button.button.btn-cart span {
	background: none;
	padding: 0px 20px;
	font-size: 14px;
}
.product-view .product-options-bottom button.button.btn-cart span {
	background: none;
	margin-left: 5px;
}
.product-view .hot-banner img {
	width: 100%
}
.sidebar .hot-banner img {
	width: 100%;

}
.product-view .product-shop {
	margin-top: 0;
    padding-left: 30px;
}

ul.shipping-pro {
	padding: 0px 0 0 20px;
	font-size: 14px;
	margin-top: 5px;
	display: inline-block;
	width: 100%;
	font-weight: normal;

}
ul.shipping-pro li {
	padding: 3px 0px;
}

.brand {
    color: #878787;
    font-size: 11px;
    padding-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
	padding-bottom: 5px;
}

.product-additional {
	display: inline-block;
}
.product-additional .block-product-additional {
	margin: 0px;
	background: #fff;
	border: 1px #eaeaea solid;
	text-align: center;
	padding-bottom: 15px;
}
.product-additional .block-product-additional p {
	margin: 12px 0;
	line-height: 20px;
	text-align: center;
	padding: 0 10px;
	font-size: 13px;
}
.product-additional .block .block-title {
	border-bottom: 2px solid #333;
	color: #000000;
	font-size: 14px;
	margin: 0;
	padding: 13px;
	text-transform: uppercase;
	text-align: center;
	margin: 0px;
	background: #f7f7f7;
}
.product-additional .block-product-additional .block-content {
	padding: 18px 12px 10px;
	text-align: center;
	line-height: 20px;
}
.block .block-content {
	border-top: medium none;
	font-size: 13px;
	overflow: hidden;
	padding: 20px 15px 0px 15px;
	background: #fff
}
.block-progress .block-content {
	text-align: left;
	padding-top: 5px;
	padding-bottom: 8px;
}
.block-progress dt.complete {
	margin: 0;
}
.product-view .product-name {

}
.product-view .product-name h1 {
	font-size: 34px;
	margin: 0 0 2px;
	text-align: left;
	line-height: 1.1em;
	padding-bottom: 12px;
	letter-spacing:normal;
	font-weight: 500;
	font-family:'Rubik', sans-serif;
	text-transform:uppercase
}
.product-view .short-description {
    font-size: 13px;
    padding:18px 0px 20px;
    border-bottom: 1px #ddd dotted;
}
.product-view .short-description h2 {
	font-family:'Montserrat', sans-serif;
	font-size: 13px;
	color: #333;
	margin: 0px;
	margin-bottom: 15px;
	font-weight: 700;
	text-transform:uppercase
}
.product-view .short-description p {
	font-size: 13px;
	margin: 0px;
	color: #777
}

.product-view .product-shop .add-to-box {
	padding:0px;
	overflow: hidden;
	text-align: left;
	margin: 0px;

}
.product-view .product-shop .add-to-box .btn-cart {
	background: #f65f73;
	color: #fff;
	font-size: 18px;
	text-shadow: none;
	padding: 16px 30px;
	float: left;
	margin-top: 0px;
	font-weight: 500;
	margin-left: 10px;
	border:none;
	border-radius: 999px;
	font-family: 'Rubik', sans-serif;

}

.product-view .product-shop .add-to-box .btn-cart:before {padding:10px 10px 10px 0px;}

.product-view .product-shop .add-to-box .btn-cart:hover {
	color: #fff;
	background-color: #97bc77;
}
.product-view .product-shop .product-options-bottom .btn-cart {
	background: #333;
	color: #fff;
	font-size: 16px;
	text-shadow: none;
	padding: 10px 20px;
	float: left;
	margin-top: 0px;
	font-weight: normal;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	margin-left: 10px;
	border: none;
}
.product-view .product-shop .product-options-bottom .btn-cart:hover {
	background: #000;
	text-shadow: none;
	color: #fff;
}
.product-view .product-shop .add-to-box .icon-basket {
	margin-right: 5px;
}
.email-addto-box {
	display: inline-block;
	margin-top: 20px;
}
.product-view .email-friend a span {
font-size: 14px;
    font-weight: normal;
}
.product-view .promo-banner {

	margin-top: 20px
}
.product-view .product-shop ul.add-to-links {
    margin: 0;
    padding: 0;
    margin-top: 0px;
    display: inline-block;
}
.product-view .product-shop .add-to-links li {
    display: inline-block;
    padding: 0px 7px 0px 0px;
    list-style-type: none;
}
.product-view .add-to-links span {
    font-size: 14px;
    margin-left: 5px;
    font-weight: normal;

}
.product-view .product-shop .add-to-links li a:hover {
	color: #000;
}
.product-view .email-friend a:before {
    content: "\f0e0";
    font-family: FontAwesome;
    font-size: 14px;
    margin-right: 6px;
}
.product-view .email-friend {
    font-size: 12px;
    display: inline-block;
    margin-bottom: 0px;
    vertical-align: top;
    margin-left: 0px;
}
.product-view .email-friend a {
    background: #efecec;
    color: #333;
    line-height: normal;
    font-weight: normal;
    vertical-align: top;
    display: block;
    height: 38px;
    border: 0px #ececec solid;
    line-height: 36px;
    vertical-align: top;
    text-align: center;
    border-radius: 5px;
    padding: 0px 15px;
    transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.product-view .email-friend a:hover {
	color: #fff;
	background-color: #97bc77;
	border-color:#97bc77
}
.product-view .product-shop .add-to-links li .separator {
	display: none;
}
.product-view .product-shop .add-to-links .link-wishlist:before {
    content: "\f004";
    font-family: FontAwesome;
    font-size: 13px;
    margin-right: 3px;
}
.product-view .product-shop .add-to-links .link-wishlist {
    background: #efecec;
    color: #333;
    line-height: normal;
    font-weight: normal;
    vertical-align: top;
    display: block;
    height: 38px;
    border: none;
    line-height: 36px;
    vertical-align: top;
    text-align: center;
    border-radius: 5px;
    padding: 0px 15px;
    transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.product-view .product-shop .add-to-links .link-compare:before {
    content: "\f012";
    font-family: FontAwesome;
    font-size: 13px;
    margin-right: 3px;
}
.product-view .product-shop .add-to-links .link-compare {
    background: #efecec;
    color: #333;
    line-height: normal;
    font-weight: normal;
    vertical-align: top;
    display: block;
    height: 38px;
    border: 0px #ececec solid;
    line-height: 36px;
    vertical-align: top;
    text-align: center;
    border-radius: 5px;
    padding: 0px 15px;
    transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.product-view .product-shop .add-to-links .link-compare:hover, .product-view .product-shop .add-to-links .link-wishlist:hover {
	color: #fff;
	background-color: #97bc77;
	border-color:#97bc77
}
.product-view .product-shop ul.add-to-links a {
	padding: 0;
	margin: 0;
}
.product-view .product-shop .ratings {
    padding: 0px 0 18px 0;
    margin: auto;
    border-bottom: 1px #ddd dotted;
    position: relative;
    bottom: auto;
    border-radius: 0;
    left: 0;
    margin-left: 0px;
}
.product-view .product-shop .ratings .rating-links {
	margin-bottom: 0px;
	display: inline-block;
}
.product-view .product-shop .no-rating {
	border-bottom: 1px dotted #ddd;
	margin: auto;
	padding: 18px 0;
	font-size: 13px;
}
.availability.in-stock {
padding: 3px 0px;
}
.availability.in-stock span {
    color: #6dbe14;
    font-size: 13px;
    line-height: 16px;
    display: inline-block;
    border: 2px #6dbe14 solid;
    padding: 5px 12px;
    border-radius: 999px;
    font-weight: 700;
}
.product-view .product-shop .price-block {
	position: relative;
	    margin-bottom: 10px;
}
.availability.in-stock span:before {
content: "\f00c";
    font-family: FontAwesome;
    display: inline-block;
    margin-right: 5px;
    font-size: 11px;
    vertical-align: middle;
}
.product-view .product-shop .price-box {
    display: block;
    margin: 0px;
    padding: 15px 0px;
    position: relative;
    min-height: inherit;
}
.product-view .product-shop .grouped-items-table .price-box {
	border-bottom: 0px solid #ddd;
	margin: 0;
	padding: 0;
}
.product-view .product-shop .price-box .old-price {
	margin: 0px;
	margin-top: 0;
	display: inline-block;
}
.product-view .product-shop .special-price {
	display: inline-block;
	margin: 0;
	padding: 0;
	line-height: normal;
}
.product-view .product-shop .old-price .price {
	font-size: 30px;
	font-weight: normal
}
.product-view .product-shop .price {
	font-size: 32px;
	color: #333e48;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif;
}
.add-to-cart .qty, .qty-holder .qty {
    background-color: #FFFFFF;
    border: none;
    border-radius: 0px;
    font-size: 15px;
    font-weight: bold;
}
.add-to-cart .qty {
    height: 48px;
    text-align: center;
    width: 30px;
    vertical-align: top;
    color: #333e48;
}
.product-shop .button-up, .product-shop .button-down {
	height: 44px;
	width: 44px;
}
.add-to-cart .button-up, .add-to-cart .button-down {
	left: 94px;
	position: absolute;
}
.button-up:hover {
	background: #333 url(../static/img/icon_arrow_up.png) no-repeat 15px 15px;
}
.button-down:hover {
	background: #333 url(../static/img/icon_arrow_down.png) no-repeat 15px 15px;
}
.button-up, .button-down {
	height: 44px;
	text-indent: -999em;
	width: 35px;
	border: none;
}
.qty-input-text {
	float: left;
	margin-left: 10px;
}

.product-view .product-shop .add-to-box .custom {
    background: #fff;
    border: 1px #ddd solid;
    border-radius: 999px;
	padding:1px 2px
}

.custom button.items-count {
transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
    font-size: 12px;
    line-height: 0px;
    padding: 13px 12px 9px 16px;
    height: 48px;
    width: 48px;
}



.custom button.items-count {
    background-color: #fff;
    border: none;
    color: #999;
    border-radius: 999px;
}

.custom button.items-count:hover {
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 -45px 0 0 #97bc77;
	border: 1px solid #97bc77;
	color: #fff;
	background-color: #97bc77;
}
.product-view .product-shop .add-to-box .add-to-cart label {
	display: inline-block;
	float: left;
	margin-top: 6px;
	text-align: left;
	text-transform: capitalize;
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	font-weight: normal;
	margin-right: 15px;
}
.product-view .product-shop .product-options-bottom .add-to-cart label {
	display: block;
	float: none;
	margin-bottom: 5px;
	text-align: left;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif;
}
.button-up:hover, .button-up:focus {
	background-image: url(../static/img/icon_arrow_up.png) !important;
	background-position: 16px 16px;
}
.button-down:hover, .button-down:focus {
	background-image: url(../static/img/icon_arrow_down.png) !important;
	background-position: 16px 16px;
}
/*  Product Collatera */
.product-collateral {
    position: relative;
    padding: 0px;
    
}
.product-view .box-additional {
	clear: both;
}
.tabs {
	border-bottom: 1px #ececec solid;

}
#product-tabs {
	margin: 0;
	padding: 0;
	position: relative;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}
#product-tabs > dt {
	text-transform: none;
	font-size: 14px;
	padding: 8px 0px;
	cursor: pointer;
	color: #102343;
	text-transform: none;
	font-family: 'Open Sans', sans-serif;
}
#product-tabs > dt.open {
	position: relative;
	background-color: #fff;
	z-index: 10;
	color: #2CA2E3;
}
#product-tabs > dd {
	display: none;
	right: 0;
	top: 0;
	background-color: #fff;
	margin: 0;
	padding: 6px 0;
	line-height: 1.8;
	border-bottom-width: 0 !important;
	font-size: 14px;
}
#product-tabs > dd h2 {
	display: none;
}
.product-tabs {
	line-height: normal;
	margin: 0;
	outline: none;
	padding: 0;
	position: relative;
	z-index: 1;
	margin-top: 0px;
	text-align: left;
	width: 100%;
	border-bottom:none

}

.product-tabs li {float:none; display:inline-block; margin-left:2px; margin-right:2px}


.product-tabs li a {
	border-bottom: none;
	color: #aaa;
	margin-right: 0px;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	font-family:'Rubik', sans-serif;
	padding: 0 20px;
}
.product-tabs li.active a {
	background: none repeat scroll 0 0 #97bc77;
	color: #fff;
	font-family:'Rubik', sans-serif;
	font-weight: 500;
	padding: 18px 15px 14px 15px;
	

}



.nav-tabs.product-tabs > li > a {
	margin-right: 25px;
	line-height: 1.42857143;
	border-radius: 0px;
	border: none;
	padding: 18px 0px 14px 0px;
	background: #fff;


}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	border: none;
	color: #333;
	border-bottom:3px #f65f73 solid


}
.nav-tabs > li > a:hover {
	border: none;
	background: #fff;
	color: #333;
}
.tab-content {
	overflow: hidden;
	font-size: 14px;
	background-color: #fff;
    padding: 20px 0;
	 margin-bottom: 30px;
	 border-top:1px #eaeaea solid
}
.tab-content > .active {
	margin-top: 5px;
	font-size: 13px;
}
.form-add-tags input.input-text, select, textarea {
	border: 1px solid #ddd;
	margin-top: 0;
	padding: 10px 20px;
	width: 32%;
	border-radius: 999px;
}
.form-add-tags button.button {
    display: inline-block;
    height: 44px;
    line-height: 46px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0 35px;
    border-radius: 999px;
	background:#97bc77;
	border:none;
	width:auto;
	font-family: 'Rubik', sans-serif;
	text-transform:uppercase
}
button.button.btn-add:before {
	content: "\f067";
	font-family: FontAwesome;
	font-size: 14px;
	padding-right: 8px;
}

.tab-content .box-tags p.note {color:#999; font-style:italic; font-size:12px}

.woocommerce-Reviews {margin-top:0px}

.woocommerce-Reviews-title {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 20px 0;
    font-family: 'Rubik', sans-serif;
}

.comment-content h3 {
    color: #333e48;
    display: block;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 21px;
    margin: auto;
    position: relative;
    border-bottom: 1px #e5e5e5 solid;
    padding-bottom: 15px;
    margin-bottom: 30px;
	font-family: 'Rubik', sans-serif;
}

.comments-form-wrapper {
    margin-top: 50px;
}

.comment-form label {
    display: block;
    margin-bottom: 4px;
}

.comment-form input[type="text"], .comment-form input[type="email"] {
    width: 100%;
    margin-bottom: 15px;
}
.comment-form input {
    padding: 8px 10px;
}

.comment-form textarea {
    width: 100%;
    height: 90px;
	border-radius:999px
}

.comment-form .bnt-comment {background: #97bc77;
    color: #fff;
    font-size: 14px;
    text-shadow: none;
    padding: 12px 0px;
    margin-top: 0px;
    font-weight: 500;
    border-radius: 999px;
    display: block;
    width: 100%;
    font-family: 'Rubik', sans-serif;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.5px;
	margin-top:15px
	
}

.commentlist {
    list-style: none;
    padding: 0;
}

.commentlist .comment {
    list-style: none;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
}

.commentlist .avatar {
    width: 70px;
    height: 70px;
    float: left;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.commentlist .comment-text {
    overflow: hidden;
    padding-left: 20px;
}

.single-product .star-rating {
    display: inline-block;
    margin-bottom: 0;
}

.star-rating:before, .star-rating span:before {
    font-family: 'FontAwesome';
    content: "\f005\f005\f005\f005\f005";
    font-size: 12px;
    color: #e2e2e2;
    margin-left: 1px;
    line-height: 100%;
}

.commentlist .comment-text .meta {
    margin-bottom: 10px;
}

.comment-respond .comment-reply-title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #000000;
    padding-bottom: 15px;
    margin: 50px 0 30px 0;
    border-bottom: 1px solid #e6e6e6;
}

.comment-respond .comment-form >p {
    margin-bottom: 20px;
}

.comment-respond .comment-form label {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: #222222;
	display:block
}

.comment-respond .comment-form textarea {
    padding: 8px 10px;
    outline: none;
    color: #999;
    border: 1px solid #ddd;
	width:60%;
	border-radius:5px
}

.comment-respond .comment-form input {
    padding: 8px 10px;
    outline: none;
    color: #999;
    border: 1px solid #ddd;
	width:60%;
	border-radius:5px
}



.comment-respond .stars {margin-bottom:20px}

.comment-respond .stars a:first-child {
    padding-left: 0;
}
.comment-respond .stars a {
    font-size: 0;
    color: #ccc;
    display: inline-block;
    border-right: 1px solid #e6e6e6;
    padding: 0 10px;
    line-height: 12px;
}

.comment-respond .stars a.star-1:before {
    content: "\f005";
}

.comment-respond .stars a.star-2:before {
    content: "\f005\f005";
}

.comment-respond .stars a.star-3:before {
    content: "\f005\f005\f005";
}

.comment-respond .stars a.star-4:before {
    content: "\f005\f005\f005\f005";
}

.comment-respond .stars a.star-5:before {
    content: "\f005\f005\f005\f005\f005";
}

.woocommerce-Reviews .comment-respond .stars a:hover {
    color: #fed700;
}

.comment-respond .form-submit input[type=submit] {
    display: inline-block;
    height: 44px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0 35px;
    border-radius: 999px;
	background:#97bc77;
	border:none;
	width:auto;
	font-family: 'Rubik', sans-serif;
	text-transform:uppercase
}

.comment-respond .stars a:before {
    font-family: 'FontAwesome';
    font-size: 12px;
    display: inline-block;
    letter-spacing: 2px;
}

#customer-reviews {
	margin-top: 15px;
}
#customer-reviews .detail {
	font-size: 14px;
	line-height: 18px;
}
#customer-reviews h3 {
	font-size: 14px;
	font-weight: 900;
	margin-bottom: 15px;
	letter-spacing: 1px;
	text-transform: uppercase;
}
#customer-reviews h4 {
	display: inline;
	font-size: 14px;
}
#customer-reviews h5 {
	display: inline;
	font-size: 14px;
}
.box-reviews dl {
	margin-bottom: 0;
}
.box-reviews dt {
	font-size: 18px;
	padding: 20px 0 10px;
}
#customer-reviews .page-title {
	border: none;
}
#customer-reviews .page-title h2 {
	color: #000;
	font-size: 20px;
	font-weight: normal;
	margin-top: 0;
	padding: 2px 0px;
	position: relative;
	display: block;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px
}
.box-reviews1 {
	margin-top: 8px;
	margin-bottom: 20px;
}
.box-reviews2 {
	margin-top: 8px;
	margin-bottom: 35px;
	overflow: hidden;
}
.review-title {
	margin-top: 0;
	line-height: 1.4;
	padding-bottom: 15px;
	margin-bottom: 0;
	font-size: 13px;
	font-weight: 400;
}
.review-title span {
	font-size: 14px;
	font-weight: 400;
	margin-top: 5px;
}
.box-reviews > .rating-box {
	position: absolute;
	top: 30px;
	right: 30px;
}
.box-reviews dd {
	margin-left: 0;
	padding-bottom: 20px;
}
.box-reviews .author {
	font-style: italic;
	font-size: 14px;
	font-weight: bold;
	margin-right: 5px;
}
.box-reviews .date {
	color: #bdbdbd;
	font-size: 14px;
	font-style: italic;
}
.box-reviews .ratings-table {
	float: left;
	margin: 5px 15px 15px 0;
	width: 12%;
}
.box-reviews .review {
	border-left: 1px solid #DFDFDF;
	float: left;
	padding-left: 2.5em;

	width: 84%;
	margin-bottom: 10px;
}
.box-reviews .review .review-txt.last {
	border-bottom: none;
}
.box-reviews .review .review-txt {
	border-bottom: 1px solid #DFDFDF;
	padding-bottom: 12px;
	margin-bottom: 10px;
}
.box-reviews ul li h6 {
	font-size: 14px;
	margin-bottom: 3px;
	margin-top: 0px;
	padding: 0;
}
.box-reviews ul li h6 a {
	color: #333;
	font-weight: 500;
	letter-spacing: 0.5px;
}
.box-reviews2 ul li {
	margin-bottom: 0px;
	clear: both;
}
.box-reviews .actions {
	padding-top: 1.5em;
	text-align: right;
	float: right;
}
#customer-reviews .form-list li label {
	color: #333;
	font-size: 14px;
}
#customer-reviews .buttons-set {
	margin-top: 10px;
}

.woocommerce-Reviews .ratings {margin-bottom:0px}




/*detail page view link*/
a.button.view-all:before {
	content: "\f06e";
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 8px;
}
a.button.view-all {
	display: inline-block;
	border: 0;
	background: #fff;
	font-size: 11px;
	text-align: center;
	white-space: nowrap;
	color: #333;
	font-weight: bold;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	vertical-align: top;
	border: 1px solid #ddd;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
}
.button.view-all:hover {
	background: #e0403d;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px solid #e0403d;
	color: #fff;
	text-decoration: none;
}
.box-reviews .form-add h3 {
	margin-bottom: 5px;
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}
.box-reviews .form-add h4 {
	font-size: 15px;
}
.box-reviews textarea {
	width: 95%;
	margin-top: 4px;
	height: 8em;
	background: #f7f7f7;
}
.box-reviews .form-list input.input-text {
	width: 80%;
	padding: 10px;
	margin-bottom: 4px;
	line-height: 20px;
	background: #f7f7f7;
	border: 1px solid #f7f7f7;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0.1) inset;
}
.box-reviews .form-list input.input-text:focus {
	width: 80%;
	padding: 10px;
	margin-bottom: 4px;
	line-height: 20px;
	background: #fff;
	border: 1px solid #ddd;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0.1) inset;
}
.box-reviews .form-list select {
	padding: 12px 15px 12px 165px;
	margin: 0;
	width: 100%;
	line-height: 20px;
	height: 48px;
}
.review1 {
	display: inline-block;
	width: 45%;
	float: left;
}
.review2 {
	display: inline-block;
	width: 55%;
}
#product-review-table {
	margin: 10px 0;
	border: medium none;
	width: 100%;
}
#product-review-table th, #product-review-table td {
	text-align: left;
	font-family: 'Open Sans', sans-serif;
	font-size: 11px;
	font-weight: bold;
	line-height: 20px;
	padding: 8px;
}
.data-table tbody th, .data-table tbody td {
	border-right: 0px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	padding: 15px 20px;
}
#customer-reviews ul {
	margin: auto;
	padding: 0px;
}
#customer-reviews li {

	margin-bottom: 0px;
	list-style: none;
}
#customer-reviews .form-list li label {
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
}
label {
	font-weight: normal;
}
.box-reviews .form-list input.input-text {
	width: 80%;
	padding: 10px;
	margin-bottom: 4px;
	line-height: 20px;
	background: #fff;
	border: 1px solid #ececec;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0.1) inset;
}
.box-reviews textarea {
	border: 1px #ececec solid;
	padding: 10px;
	outline: none;
	color: #aaa;
	background:#fff
}
.nobr {
	white-space: nowrap;
}
button.button.submit:before {
	content: "\f04b";
	font-family: FontAwesome;
	font-size: 11px;
	padding-right: 8px;
}
.button:hover {
	border: 0px solid #333;
	color: #FFF;
}
.upsell-pro .product-flexslider {
	margin: 0px -10px;
}
.upsell-pro {
	margin-bottom: 20px;
	margin-top: 25px;
}
#product-tabs > dd h2.product-name {
	display: block;
}
#product-tabs .crosssell .flex-direction-nav a {
	width: 50px;
	height: 40px;
	top: 112px;
}
#product-tabs .crosssell .flex-direction-nav .flex-prev {
	right: auto;
	left: -10px;
}
#product-tabs .crosssell .flex-direction-nav .flex-next {
	right: -10px;
}
.box-up-sell .flex-direction-nav a, .box-cross-sell .flex-direction-nav a {
	width: 54px;
	height: 44px;
	top: 0;
	margin-top: -76px;
	margin-right: -1px;
}
.product-view .box-tags .form-add input.input-text {
	width: 350px;
	background-color: #FFFFFF;
	border: 1px solid #CCCCCC;
	border-radius: 0px;
	box-shadow: 0 1px 5px #eee inset;
	margin-right: 10px;
	padding: 8px;
}
.up-sell-box {
	border: 1px #ddd solid;
	border-top: none;
}
.up-sell-box p.desc {
	margin: 0;
	padding: 10px 10px 0 10px
}
#brand-slider .flex-direction-nav a {
	width: 55px;
	height: 42px;
	top: 0;
	margin-right: -1px;
}
.featured-products .flex-direction-nav a {
	width: 54px;
	height: 43px;
	top: 0;
	margin-top: -43px;
	margin-right: -1px;
}
#featured-products-list-slider {
	border: 1px #ddd solid;
	border-top: none;
	width: 840px;
	height: 485px;
}
.box-up-sell .flex-direction-nav .flex-prev, .box-cross-sell .flex-direction-nav .flex-prev, #brand-slider .flex-direction-nav .flex-prev, .featured-products .flex-direction-nav .flex-prev {
	right: 53px;
}
.main .box-up-sell h2.subtitle .line, .main .box-cross-sell h2.subtitle .line, .brand-slider-wrap h2.subtitle .line, .featured-products h2.subtitle .line {
	right: 130px;
}
.featured-products {
	position: relative;
}
.product-view .box-tags .form-add label {
	line-height: 42px;
	font-size: 16px;
}
.product-view .box-tags .form-add .button {
	height: 35px;
}
#addTagForm {
	margin-bottom: 5px;
	margin-top: 10px
}
.product-view .box-up-sell {
	margin-top: 10px;
	float: left;
}
.product-view .box-up-sell .subtitle {
	margin-right: 0;
}
.mini-products-list .price-box * {
	display: inline;
}
.mini-products-list .price-box .price {
	margin-right: 10px;
}
/* products page */
.category-products ul.products-grid li.item {
    text-align: center;
    padding-bottom: 0;
    padding-top: 30px;
}
.category-products ul.products-grid li.item.last {
	text-align: center;
}
.category-products ul.products-grid li.item img {
	width: 100%;
}
.category-products ul.products-grid li.item a.thumb {
	position: relative;
	display: block;
	cursor: pointer;
}
.category-products ul.products-grid li.item a.thumb:hover {
	border-color: #999;
}
.category-products ul.products-grid li.item a.thumb span.quick-view {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto 0;
	height: 35px;
	overflow: hidden;
}
.category-products ul.products-grid li.item a.thumb span.quick-view span {
	display: block;
	position: absolute;
	height: 100%;
	width: 100%;
	font-size: 9px;
	text-transform: uppercase;
	font-weight: 700;
	color: #0a0a0a;
	padding: 8px 0 5px;
	background: rgba(255, 255, 255, 0.9);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}
.category-products ul.products-grid li.item a.thumb span.quick-view span i {
	display: inline-block;
	width: 10px;
	height: 10px;
	vertical-align: middle;
	margin-top: -1px;
	background: url('../static/img/quick-view.png') no-repeat center;
	padding-right: 12px;
}
/* === FLIP 3D === */
.category-products ul.products-grid li.item .product-wrapper {
	position: relative;
	margin-top: 20px;
}
.category-products ul.products-grid li.item a.thumb.flip {
	transform-style: preserve-3d;
	transition: transform 0.5s linear;
}
.category-products ul.products-grid li.item .product-wrapper .thumb-wrapper:hover a.thumb.flip {
	transform: rotateY(180deg);
}
.category-products ul.products-grid li.item .product-wrapper a.thumb.flip .face {
	display: block;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	position: relative;
	z-index: 1;
}
.category-products ul.products-grid li.item .product-wrapper a.thumb.flip .face.back {
	display: block;
	transform: rotateY(180deg);
	position: absolute;
	top: 3px;
	left: 0px;
	right: 0px;
	z-index: 10;
}
.product-view .product-next-prev .product-next {
	font-size: 20px;
	line-height: 22px;
	display: inline-block;
	width: 26px;
	height: 24px;
	color: #333;
	text-align: center;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	position: absolute;
	right: 0px;
	position: absolute;
	z-index: 10;
	top: 0px;
	border: 1px #e5e5e5 solid;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff
}
.product-view .product-next-prev .product-next:hover {
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 -40px 0 0 #97bc77;
	border: 1px solid #97bc77;
}
.product-view .product-next-prev .product-next:before {
	content: "\f105";
	font-family: FontAwesome;
}
.product-view .product-next-prev .product-prev {
	font-size: 20px;
	line-height: 22px;
	display: inline-block;
	width: 26px;
	height: 24px;
	color: #333;
	text-align: center;
	position: absolute;
	right: 35px;
	z-index: 10;
	top: 0px;
	border: 1px #e5e5e5 solid;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff
}
.product-view .product-next-prev .product-prev:hover {
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 -40px 0 0 #97bc77;
	border: 1px solid #97bc77;
}
.product-view .product-next-prev .product-prev:before {
	content: "\f104";
	font-family: FontAwesome;
}

/* Related Products */
.related-pro {
	position: relative;
    padding: 0px 0px;
    background-color: #fff;


}

.related-pro .new_title {
    text-align: left;

	
}

.related-pro .new_title h2 {
    color: #292929;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0px;
    padding: 0px 0px 10px 0px;
    position: relative;
    display: inline-block;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 0px;
}

.related-pro .slider-items-products .owl-buttons .owl-prev {margin-top: -45px;}
.related-pro .slider-items-products .owl-buttons .owl-next {margin-top: -45px;}

.related-pro .owl-item .item {
    float: left;
    background: #FFF;
    margin-top: 0px;

}

.related-pro .owl-item .item {
	margin:0 15px;
	padding:15px 0px 24px 0px

}

.related-pro .product-flexslider {margin:0px -15px}



.related-pro .owl-theme .owl-controls {margin:0px}



/* Cart page */

.shopping-cart-tbl {
	padding: 0px 0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 5px;
    background-color: #fff;
    margin-bottom: 30px;}

.cart {
	padding: 10px 0;
	margin-top: 25px;
}
#shopping-cart-table {
	border: none;
	float: left;
	width: 100%;
	background-color: #FFFFFF;
	text-align: left;
	margin-top:5px;
}
.cart-table .btn-continue {
	float: left;
}
button.button span {
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing:0.5px
}
button.button.btn-continue:before {
	content: "\f061";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
.data-table td {
	line-height: 20px;
	padding: 15px 20px;
	vertical-align: middle;
}
#shopping-cart-table tfoot tr td.last {
	border-top: none;
	border-top:1px #eaeaea solid;
	background:#fff;
	border-bottom:none
}
button.button.btn-update:before {
	content: "\f021";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
button.button#empty_cart_button:before {
	content: "\f00d";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
.cart-table .btn-update, .cart-table .btn-empty {
	float: right;
	margin-left: 8px;
}
#shopping-cart-table .product-name {
	margin: 0px;
	padding-bottom: 0px;
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
}
#shopping-cart-table .product-name a {
	text-transform: none;
}
#shopping-cart-table.data-table .price {
	font-weight: 600;
}
#shopping-cart-table input.qty {
    background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid #ddd;
    font-size: 14px;
    font-weight: normal;
    margin: 0 !important;
    outline: medium none;
    padding: 8px 10px;
    width: 40px;
    text-align: center;
	border-radius: 999px;
}
#shopping-cart-table .edit-bnt {
	float: left;
	margin-top: 0px;
}
.edit-bnt:before {
	content: "\f040";
	font-family: FontAwesome;
	font-size: 12px;
	padding: 9px 10px;
}
a.edit-bnt:hover {
	color: #339900;
}
.edit-bnt span {
	display: none;
}
#shopping-cart-table a.remove-item {
	background-color: #fff;
	background-image: none;
	color: #333;
	cursor: pointer;
	padding: 8px 13px;
	cursor: pointer;
	text-decoration: none;
	float: left;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #ddd solid;
	border-radius: 999px;

}
#shopping-cart-table a.remove-item:hover {
	background-color: #f65f73;
	color: #fff;
	border: 1px #f65f73 solid
}
#shopping-cart-table a.remove-item:before {
	content: "\f014";
	font-family: FontAwesome;
	font-size: 16px;
}
#shopping-cart-table a.remove-item span {
	display: none;
}
#discount-coupon-form label {
	font-weight: normal;
}
.totals h3 {
	border-bottom: 1px solid #ddd;
	color: #000;
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	margin-bottom: 5px;
	padding: 10px 0;
	text-transform: uppercase;

}
.cart-collaterals .col2-set .col-2 {
	width: 320px;
}
.totals .inner {
	padding:15px 0px;
	background-color: #fff;
	margin-top: 0px;
}
.cart-collaterals h3 {
	font-size: 14px;
	background-color: #fff;
	color: #333;
	margin-bottom: 14px;
	border-bottom:1px #eaeaea solid;
	padding: 12px 0px;
	font-family:'Rubik', sans-serif;
	text-transform: uppercase;
	font-weight: 500;
	margin: auto;
	letter-spacing:0.5px
}
.shipping-form {
	padding:15px 0px;
}
#shipping-zip-form p {
	margin: 2px 0 4px;
}
.form-list {
	margin: 0;
	padding: 0;
	list-style: none;
}
.form-list li {
	margin: 0 0 10px;
	position: relative;
}
.form-list label {
	position: relative;
	z-index: 0;
	font-weight: normal;
	color: #333;
	display: inline;
	font-size: 13px;
}
#shipping-zip-form .form-list label {
	display: block;
}
.form-list label.required em {
	font-style: normal;
	color: #ff0000;
}
#login-form em {
	font-style: normal;
	color: #ff0000;
}
.required em {
	color: #ff0000;
}
.required {
	color: #ff0000;
}
.form-list .input-box {
	display: block;
	clear: both;
	margin-bottom: 0;
}
.form-list select {
	margin: 5px 0 2px;
	width: 100%;
	outline: none;
}
.shipping {
	margin-top: 25px;

}

.discount {
	margin-top: 25px;
	min-height: 360px;
}
.totals {
	margin-top: 25px;
	min-height: 360px;
}
#discount-coupon-form {
	padding:15px 0px;
}
.cart-collaterals {
    padding: 0px 0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 10px;
    background-color: #fff;


}
.shipping .form-list select {
	width: 100%;
	background-color: #fff;
	border:1px #ddd solid;
	padding: 10px 15px;
}
.form-list input.input-text {
	background: #fff;
	border:1px #ddd solid;
	padding:10px 15px;
	width: 100%;
	margin-top: 5px;
	outline: none;
	color: #333;
	border-radius:999px
}
.shipping .form-list input.input-text {
	width: 100%;
}
button.button.get-quote:before {
	content: "\f15c";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
input.input-text, select, textarea {
	background-color: #fff;
	border: none;
	padding: 10px;
	outline: none;
	color: #333;
	border:1px #ddd solid;
	border-radius:999px
}

textarea {border-radius:0px}

input#coupon_code {
	margin: 8px 0;
	width: 100%;
}
button.button.coupon:before {
	content: "\f02b";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
#shopping-cart-totals-table {
	float: right;
	width: 100%;
	padding-bottom: 8px;
	font-size:16px
}
.cart .totals table th, .cart .totals table td {
	padding: 5px;
	border: none !important;
	background: none !important;
	border: medium none !important;
	box-shadow: none !important;
	color: #333 !important;
}
.checkout {
	color: #666666;
	padding-top: 5px;
	text-align: center;
	list-style: none;
	padding: 0;
	margin: 0;
}
button.button.btn-proceed-checkout {
	background: #f65f73;
	padding: 15px 0px;
	color: #fff;
	width: 100%;
	border: none;
}
button.button.btn-proceed-checkout:hover {
	background: #f65f73;
	color: #fff;
	padding: 15px 0px;
	border: none;
}
button.button.btn-proceed-checkout:before {
	content: "\f00c";
	font-family: FontAwesome;
	font-size: 20px;
	padding-right: 5px;
}
button.button.btn-proceed-checkout span {
	font-size: 18px;
	font-weight: normal;
}
.checkout a {
	text-decoration: underline;
	color: #f65f73;
}
#shopping-cart-totals-table .price {
	font-size: 18px;
	color: #333;
}
#shopping-cart-totals-table strong span.price {
	font-weight: 600;
}
.a-right {
	text-align: right !important;
}
.crosssel h2 {
	border-bottom: 1px solid #e5e5e5;
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 0;
	padding-bottom: 7px;
	text-align: left;
	text-transform: uppercase;
	width: 100%
}
/* Cart page End */
#wishlist-table {
	border: none;
	text-align: left;
}
#wishlist-table .product-name {
	margin: 0;
	text-align: left;
	font-size: 14px;
	line-height: normal;
	font-family:'Rubik', sans-serif;
	font-weight:500;
	text-transform:none
}
#wishlist-table .product-name a {
	text-transform:none;
}
#wishlist-table input.qty {
	background: none repeat scroll 0 0 #FFFFFF;
	padding:12px 8px;
	text-align: center;
	border: 1px #ddd solid;
	width: 48px;
}
#wishlist-table .description {
	margin: 10px 0;
}
#wishlist-table img {
	width: auto;
}
#wishlist-table button.button.btn-cart {
	padding: 0px;
	color: #222;
	background-color: inherit;
	border: none;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	margin: 0 0 0px !important;
	border:1px #ddd solid!important;
	height:48px;
	width:48px
	
}
#wishlist-table button.button.btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
	font-size: 16px;
	    background: none;
    border:none;
    color: #333;
    padding: 6px 10px;
}
#wishlist-table button.button.btn-cart:hover {
	color: #333;
}
#wishlist-table button.button.btn-cart span {
	display: none;
}

.edit-wishlist {display:none}

#wishlist-table a.remove-item {
	padding: 0px 11px;
	color: #333;
	background-color: inherit;
	border-radius: 999px;
	border: 0px #333 solid;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	text-decoration: none;
	border:1px #ddd solid;
	display:block;
	height:48px;
	width:48px;
	line-height:48px;
	text-align:center
}
#wishlist-table a.remove-item:before {
	content: "\f014";
	font-family: FontAwesome;
	font-size:16px
}


.btn-edit {padding: 5px 11px; border:1px #ddd solid; display:block; text-align:center}

small {
	font-size: 85%;
	font-weight: normal;
	text-transform: lowercase;
}
a:hover small {
	color: #fff;
}
.my-wishlist .buttons-set2 {
	padding:20px;
	overflow:hidden;
	padding-bottom:0px
}
.my-wishlist .buttons-set2 .btn-update {
	float: right;
	margin-right: 2px;
}

.my-wishlist .buttons-set2 .btn-share {
	float: right;
	margin-left: 8px;
}
button.button.btn-share:before {
	content: "\f004";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
.my-wishlist .buttons-set2 .btn-add {
	float: right;
	margin-left: 8px;
}
.my-wishlist .back-link {
	float: left;
}
.state_bar {
	padding: 0;
	margin-top: 20px;
}
.checkout-progress {
	padding-top: 0px;
	margin: 0px 0 0px;
	overflow: hidden;
	padding: 0;
	list-style: none;
}
.checkout-progress li {
	list-style-type: none;
	width: 19%;
	text-align: center;
	color: #333;
	margin-right: 0px;
	padding: 10px 7px;
	text-transform: uppercase;
	    font-family: 'Oswald', sans-serif;
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
	letter-spacing: 1.5px;
	margin-right: 10px;
	border-radius: 999px;
	border: 2px #fff solid;
	background:#fff
}
.checkout-progress li.last {
	margin-right: 0px;
}
.checkout-progress li.active {
	color: #fff;
	background-color: #f65f73;
	border: 2px #f65f73 solid;
}
.multiple_addresses {
    padding: 5px 0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 30px;
    background-color: #fff;
    margin-top: 30px;
}



.page-title_multi h2 {
	font-size: 22px;
	float: left;
	font-family:'Rubik', sans-serif;
	color: #000;
	font-weight: 500;
	text-transform: uppercase;
	margin-top: 5px;
}
.multiple_addresses .title-buttons {
	text-align: right;
	padding-top:5px

}



button.button.new-address:before {
	content: "\f015";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
.addresses {
	margin-bottom: 15px;
	float: left;
	width: 100%;
}
#multiship-addresses-table {
	margin-top: 5px;
	border: none;
	overflow: hidden;
}
#multiship-addresses-table h4 {
	font-size: 14px;
	margin: 0;
	line-height: 18px;
	font-weight: normal;
}
.multiple-checkout select {
	width: 100%;
	min-width: 315px;
}
#multiship-addresses-table .btn-remove {
	background-color: inherit;
	background-image: none;
	color: #333;
	cursor: pointer;
	padding: 10px 12px;
	border-radius: 0px;
	cursor: pointer;
	text-decoration: none;
	border: 1px solid #fff;
	float: left;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	line-height: 12px;
	border:1px #ddd solid
}
.multiple_addresses .addresses select {
	border: 1px #ddd solid;
	padding:7px 10px
}
#multiship-addresses-table .btn-remove:before {
	content: "\f014";
	font-family: FontAwesome;
}
#multiship-addresses-table .btn-remove:hover {
	color: #fff;
	background-color: #f65f73;
	border: 1px #f65f73 solid;
}
#multiship-addresses-table .btn-remove span {
	display: none;
}
#multiship-addresses-table input.input-text {
	background-color: #FFF;
	padding: 6px 8px;
	border: 1px solid #ddd;
	text-align: center;
}
.state_bar li {
	width: 19%;
	display: inline-block !important;
}
.multiple_addresses .addresses {
	margin: 15px 0;
}
.multiple_addresses .buttons-set {
	margin: auto;
}
.multiple_addresses .multiple-checkout {
	padding-bottom: 15px;
}
.multiple_addresses .buttons-set {
	margin: 10px 20px;
}
.back-link {
	float: left;
}
.multiple-checkout .buttons-set .button {
	float: right;
}
.account-login {
    padding:0px;
    position: relative;
    background-color: #fff;
	margin-top:40px

}
.account-login .page-title h1, .account-login .page-title h2 {
	border-bottom: none;
}
.account-login .col2-set .col-1 {
	float: left;
	padding-bottom: 0;
	padding:0px 40px 0px 0px;
	text-align: left;
	width: 50%;
	min-height:300px;
	border-right: 1px #eaeaea solid;
}
.account-login .required {
	color: #F00
}
.account-login .col2-set .col-1 strong {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
    display: inline-block;
    padding-bottom: 6px;
    width: 100%;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.account-login .col2-set .col-2 strong {
	font-size: 18px;
	text-transform: uppercase;
	margin-bottom: 8px;
	display: inline-block;
	padding-bottom: 6px;
	width: 100%;
	font-family:'Rubik', sans-serif;
	font-weight:500;
	letter-spacing:0.5px
}
.account-login .col2-set .col-2 {
	float: right;
	padding-bottom: 0;
	padding:0px 0px 0px 40px;
	text-align: left;
	width: 50%;

}


button.button.create-account { background:#97bc77; color:#fff; margin-top: 20px;}
button.button.create-account:hover {background: #131e2c;}

button.button.create-account:before {
	content: "\f007";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
button.button.login:before {
	content: '\f13e';
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px;
}
.account-login .form-list input.input-text {
	background: #fff;
	border: 1px solid #ddd;
	padding: 10px 20px;
	width: 100%;
	margin-top: 5px;
	outline: none;
	color: #666;
}

.account-login .forgot-word {padding-left:10px}

.group-select ul {
	padding-left: 0px;
}
/* 404 Page */
.content-wrapper {
	margin: auto;
	text-align: center;
	padding-top: 50px;
	padding-bottom: 80px;
}
.page-not-found h2 {
	color: #da2c2a;
	font-size: 12em;
	font-weight: bold;
	line-height: 1;
	margin-bottom: 15px;
	font-family: 'Shadows Into Light';
}
.page-not-found h3 {
	color: #333;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 4em;
	margin-top:40px

}
.page-not-found h3 img {
	margin-right: 8px;
	vertical-align: text-bottom;
}
.page-not-found p {
	color: #666;
	font-size: 1.33em;
	line-height: 1.5em;
	margin-bottom: 0.75em;
}
.page-not-found .btn-home {
    display: inline-block;
    padding: 15px 30px 12px 30px;
    background: #6a9c23;
    border-radius: 999px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #fff;
	font-family: 'Rubik', sans-serif;
	text-transform:uppercase;
	font-size:14px;
	font-weight:500;
	letter-spacing:0.5px
	
}
.page-not-found .btn-home:hover {
	background-color: #000;
	color: #fff;
}
.page-not-found .btn-home span {

}
#toTop {
	color: #fff;
	text-align: center;
	bottom: 60px;
	display: none;
	height: 40px;
	line-height: 40px;
	overflow: hidden;
	position: fixed;
	right: 10px;
	text-decoration: none;
	width: 45px;
	background: url(../static/img/to-top-arrow.png) no-repeat center;
	background-color: #f65f73;
	text-transform: uppercase;
	z-index: 100;
	border-radius: 0px;
}
#toTop:hover {
	color: #fff;
	background-color: #2ecc71;
}
/* Footer */

/* BRAND SLIDER */

.logo-brand {padding:0px 0;
  position: relative;
  margin-top: 0px;
  padding-bottom: 20px;

  }
  
.logo-brand .product-flexslider {border-left: 1px solid #e9e9e9; margin-right:-1px}

.brand-logo {
	margin: 0px 0px 50px 0px;
	padding: 5px 0px;
	
	
	
}
.logo-brand .owl-carousel .owl-item {
    float: left;
    border: 1px solid #e9e9e9;
    border-left: none;
    padding: 2px;
	
}



.logo-brand .owl-pagination {bottom:-70px; top:auto; padding:0px; left:50%; right:auto; background:none; margin-left:-35px}

.logo-brand .owl-theme .owl-controls .owl-page span {border:none; height:10px; width:10px; margin-left:5px}
.logo-brand .owl-theme .owl-controls .owl-page span:hover {background: #a8ca01;}
.logo-brand .owl-theme .owl-controls.clickable .owl-page:hover span {background: #a8ca01;}
.logo-brand .owl-theme .owl-controls .owl-page.active span {
width: 30px;
    background: #a8ca01;
    border-radius: 10px;
}

#brand-logo-slider {
	margin: 0px 0px!important
}
.brand-logo .slider-items-products .item {
	text-align: center;
	margin:0 9px 0px 10px;
	
	
}

.logo-item {line-height: 85px; margin-top: 15px; margin-bottom:15px;}

.logo-brand .new_title {
  text-align: left;
  margin-left: -20px;
  margin-right: -10px;
  border-bottom: 1px #e5e5e5 solid;
  margin-bottom:10px}

.logo-brand .new_title h2 {
	color: #fff;
	font-size:16px;
	font-weight: bold;
	margin-bottom: 0px;
	padding: 22px 25px 22px 25px;
	position: relative;
	display: inline-block;
	font-family:'Montserrat', sans-serif;
	letter-spacing: 0.5px;
	text-transform:uppercase;
	background:#97bc77;
	margin-top:0px}

.logo-brand .new_title h2:after {
  content: "";
  height: 1px;
  width: 1px;
  border-style: solid;
  border-width: 5px;
  position: absolute;
  bottom: -10px;
  left: 0;
  border-color: #6a9c32 #6a9c32 transparent transparent; }


/* newsletter */

.services-col {background:#f65f73}
.newsletter-col {background:#97bc77}

.newsletter {
	margin: 0 0 20px 0;
	padding: 5px 0 0;
	position: relative;
	width: 820px;
	margin: auto;
	margin: auto;
}
.newsletter-wrap h4 {
	margin-bottom: 23px;
	display:block;
	padding: 0px;
	font-family:'Oswald', sans-serif;
	font-size:38px;
	letter-spacing:0px;
	font-weight:400;
	text-transform:uppercase
	

}

.newsletter-wrap h5 {
	margin:0px;
	margin-bottom: 10px;
	display:block;
	padding: 0px;
	font-family:'Oswald', sans-serif;
	font-size:28px;
	letter-spacing:0px;
	font-weight:300;
	color:#fff;
	text-transform:uppercase;
	letter-spacing:1px
	

}

.newsletter-wrap input[type="text"] {
	background: #FFFFFF;
	width: 450px;
	height: 18px;
	display: inline-block;
	color: #a7a7a7;
	line-height: 22px;
	background-color: #fff;
	border: none;
	padding-left: 10px;
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	border: none;
}
.newsletter-wrap button.subscribe {
	background:none;
	border: 0 none;
	filter: none;
	overflow: hidden;
	padding: 18px 28px;
	cursor: pointer;
	font-weight: 500;
	border:none;
	background:#f65f73;
	border-radius:999px;
	font-family:'Rubik', sans-serif
}
.newsletter-wrap button.subscribe:hover {
	filter: none;
	overflow: hidden;
	border:none;
	box-shadow:none

}
.newsletter-wrap button.subscribe span {
	color: #fff;
	font-size: 16px;
}
.newsletter-wrap input[type="text"] {
	background: #FFFFFF;
	height: auto;
	display: inline-block;
	color: #a7a7a7;
	line-height: 22px;
	background-color: #fff;
	border: none;
	font-size: 14px!important;
	font-family: 'Open Sans', sans-serif;
	border: none;
	padding: 8px 10px;
	width: 425px!important;
}
footer {
	padding: 0px 0 0;
	overflow: hidden;
	color: #fff;
	background: #252932;

}

footer .container {padding:0px}

.footer-top {
    clear: both;
    overflow: hidden;
    padding:15px 0;
    border-top: 1px rgba(255,255,255,0.1) solid;
}


.coppyright {
    color: #888;
    display: block;
    text-align: center;
    font-size: 13px;
    padding-top: 15px;
}

footer ul {
	margin: 0px;
	padding: 0px;
}
footer ul li {
	list-style-type: none;
}
footer ul li:last-child {
	border-bottom: none;
	width: auto
}
footer a {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 14px
}
.com-add {
	border-bottom: 1px solid #f7f7f7;
	margin-bottom: 10px;
	padding-bottom: 6px;
}

.footer-banner {margin-top:10px; margin-left:15px; margin-bottom:40px; clear:both; float:left}

footer .footer-inner {
	margin: auto;
	overflow: hidden;


}
footer .footer-inner h3 {
	color: #3D4C4F;
	font-size: 15px;
	text-transform: uppercase;
	margin: 0 0 15px;
	padding: 0 0 10px;
	font-family: 'Open Sans', sans-serif;
}
.footer-column-1 {
	margin-right: 30px;
	margin-bottom: 15px;
	line-height: 18px;
	min-height: 220px;
	padding-right: 30px;
}
.footer-column {
	margin-bottom:25px;

}
footer .co-info {
    margin-right: 0px!important;
     padding: 30px 0px;
    text-align: left;
    margin-top: 0px;


}
footer .newsletter-row {
	overflow: hidden;
	width: 100%;
	padding: 55px 0px;
	background:url(../static/img/shipping-v2.jpg) no-repeat top left;
	background-size: cover

}

footer .newsletter-row .col1 {text-align:center}
footer .footer-column a {
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}

.footer-column-last {
	margin-right: 0px;
	margin-bottom: 15px;
	padding-left: 0px;
}
.footer-column-last li {
	padding: 0px 0 5px;
}
.footer-bottom .inner {
	margin: auto;
	padding: 20px 0 15px;
	height: 60px;
}
.footer-bottom .inner a {
	color: #aaa
}
.footer-bottom .inner .bottom_links a {
	margin-left: 15px;
}
.footer-bottom .inner a:hover {
	color: #fff
}
footer p {
	font-size: 14px;
	color: #222;
	padding-top: 5px;
	padding-bottom: 5px;
}
footer .footer-column a {
	display: block;
	color: #b7bcc8
}
footer a {
	line-height: normal;
}
footer a:hover {
	color: #ffb637;
}
footer p {
	line-height: 20px;
}
footer h4 {
	padding: 0 0 18px;
	font-size: 16px;
	margin: 0;
	color: #ffb637;
	font-weight: 550;
	font-family:'Rubik', sans-serif;
	text-transform: uppercase;
	letter-spacing: 0.5px;
    padding-top:30px;
}
footer .input-text:focus {
	background: #fff;
	border-color: #464646;
}
footer a:hover {
	text-decoration: none;
}
footer .coppyright {
	color: #b7bcc8;
	float: left
}
.footer-bottom {
	padding: 0px;
	overflow: hidden;
	width: 100%;
	margin: auto;
	padding: 25px 0;
	background-color: #1b1e24;
	text-align:center
}
.footer-bottom a {
	color: #999;
}
.footer-bottom .company-links ul {
	padding: 0px;
}
.footer-bottom .company-links li {
	display: inline-block;
	margin-left: 20px;
	list-style: none;
	float: right;
	border-bottom: none
}
.add-icon:before {
	content: "\f041";
	font-family: FontAwesome;
	font-size: 18px;
	color: #b7bcc8;
	height: 45px;
	width: 45px;
	line-height: 42px;
	display: inline-block;
	float: left;
	font-style: normal;
	text-align: center;
	margin-right: 10px;
	border-radius: 30px;
	border-radius: 999px;
	margin-top: 0px;
	border: 2px #b7bcc8 dotted;
}
.email-footer {
	overflow: hidden;
	margin-top: 12px;
	font-size: 14px;
}
.email-footer a {
	font-size: 14px;
	line-height: 22px;
	color: #aaa
}
.phone-footer {
	overflow: hidden;
	font-size: 14px;
	line-height: 35px;
	color: #b7bcc8;
	margin-bottom: 18px;
	margin-top: 12px;
}
footer address {
	display: block;
	margin: auto;
	font-style: normal;
	line-height: 1.5em;
	color: #b7bcc8;
	margin-top: 20px;
	text-align: left;
	margin-bottom: 20px;
	font-size: 14px;
	padding:0px
}

.icon-location-arrow:before {color:#b7bcc8}
.icon-mobile-phone:before{color:#b7bcc8}
.icon-envelope:before{color:#b7bcc8}

footer address em {
	display: inline-block!important;
	vertical-align: middle!important;
	margin-right: 8px!important
}
footer address em:before {
	margin: 0px !important
}
footer address span {
	display: inline-block!important
}
footer address div {
	display: block;
	text-align: left;
	overflow: hidden;
	padding: 15px 0px;
  border-bottom: 1px #b7bcc8 dotted;

}

footer address div:last-child{ border:none}

.email-icon:before {
	content: "\f0e0";
	font-family: FontAwesome;
	font-size: 18px;
	color: #b7bcc8;
	height: 45px;
	width: 45px;
	margin-right: 5px;
	line-height: 42px;
	display: inline-block;
	float: left;
	font-style: normal;
	text-align: center;
	margin-right: 10px;
	border: 2px #b7bcc8 dotted;
	border-radius: 25px
}
.phone-icon:before {
	content: "\f095";
	font-family: FontAwesome;
	font-size: 18px;
	color: #b7bcc8;
	height: 45px;
	width: 45px;
	margin-right: 5px;
	line-height: 42px;
	display: inline-block;
	float: left;
	font-style: normal;
	text-align: center;
	margin-right: 10px;
	border:2px #b7bcc8 dotted;
	border-radius: 25px
}
.footer-bottom .bottom_links li {
	margin-left: 20px
}
.custom-footer-links li {
	margin: 7px 0
}
.footer-col-right {
	width: 58%;
	float: left
}
.contact-info {
	text-align: center;
}
ul.contact-info span {
	font-size: 13px !important;
	font-weight: 900 !important;
	color: #666;
	float: left
}
ul.contact-info li {
	margin-bottom: 8px;
	padding-bottom: 8px;
	display: inline-block;
}
ul.contact-info li.last {
	float: left;
	margin-bottom: 0px;
	border-bottom: 0px #2D3D4C solid
}
#container_newsletter {
	width: 100%;
	padding: 7px 0 7px 0;
	background: #f7f7f7;
	margin: 36px 0 22px 0
}
#text_container_news {
	width: 205px;
	height: 34px;
	float: left;
	margin-left: 30px;
	text-transform: uppercase;
	font-family: arial;
	font-size: 10px;
	color: #647177;
	padding-top: 7px
}
#text_container_news span {
	color: #959a9c;
	font-size: 9px
}
#container_form_news {
	position: relative;
	z-index: 0
}
#container_form_news2 input[type="text"] {
	background: #FFFFFF;
	width: 65%;
	display: inline;
	color: #a7a7a7;
	line-height: 20px;
	background-color: #FFFFFF;
	border: 1px solid #e9e7e7;
	padding-left: 10px;
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
	margin-right: 0px;
	padding:20px 20px;
	border-radius:999px
}
#container_form_news2 input[type="submit"] {
	cursor: pointer;
	border: 0;
	background: #FFFFFF;
	width: 20px;
	height: 12px;
	display: block;
	position: relative;
	color: #a7a7a7;
	font-size: 10px;
	line-height: 20px;
	top: -18px;
	right: -160px;
	
}
.footer-box {
	width: 1240px;
	margin: auto;
}
footer ul span {
	display: inline-block;
	font-size: 20px;
	font-weight: 300;
	line-height: 15px;
	padding-right: 3px;
}
address {
	display: block;
	margin: auto;
	font-style: normal;
	line-height: 21px;
	color: #999;
	padding-top: 10px;
	margin-top: 10px;
	text-align: left;
	padding-bottom: 15px;
	margin-bottom: 15px
}
.footer-logo {
	text-align: left;
	margin: 10px 0;
}
.social-section {
	padding: 0px;
	overflow: hidden;
	width: 100%;
	margin: auto;
	background: #191919
}
.social-section .inner {
	margin: auto;
	overflow: hidden;
	margin-bottom: 20px;
	padding-top: 20px;
}
/* Social Icons*/
.social .fb a:before {
	content: "\f09a";
	font-family: FontAwesome;
}
.social .fb a {
	background: #3C5B9B;
	font-size: 18px;
	border-radius: 3px;
	line-height: 35px;
	display: inline-block;
	width: 35px;
	height: 35px;
	color: #fff;
	text-align: center;
	padding: 0;
}
.social .fb a:hover {
	background: #3C5B9B;
}
.social .tw a:before {
	content: "\f099";
	font-family: FontAwesome;
}
.social .tw a {
	background: #359BED;
	font-size: 18px;
	border-radius: 3px;
	line-height: 35px;
	display: inline-block;
	width: 35px;
	height: 35px;
	color: #fff;
	text-align: center;
	padding: 0;
}
.social .tw a:hover {
	background: #359BED;
}
.social .googleplus a:before {
	content: "\f0d5";
	font-family: FontAwesome;
}
.social .googleplus a {
	background: #E33729;
	font-size: 18px;
	border-radius: 3px;
	line-height: 35px;
	display: inline-block;
	width: 35px;
	height: 35px;
	color: #fff;
	text-align: center;
	padding: 0;
}
.social .googleplus a:hover {
	background: #E33729;
}
.social .rss a:before {
	content: "\f09e";
	font-family: FontAwesome;
}
.social .rss a {
	content: "\f09e";
	font-family: FontAwesome;
	background: #FD9F13;
	font-size: 18px;
	border-radius: 3px;
	line-height: 35px;
	display: inline-block;
	width: 35px;
	height: 35px;
	color: #fff;
	text-align: center;
	padding: 0;
}
.social .rss a:hover {
	background: #FD9F13;
}
.social .pintrest a:before {
	content: "\f0d3";
	font-family: FontAwesome;
}
.social .pintrest a {
	content: "\f0d3";
	font-family: FontAwesome;
	background: #cb2027;
	font-size: 18px;
	border-radius: 3px;
	line-height: 35px;
	display: inline-block;

	width: 35px;
	height: 35px;
	color: #fff;
	text-align: center;
	padding: 0;
}
.social .pintrest a:hover {
	background: #cb2027;
}
.social .linkedin a:before {
	content: "\f0e1";
	font-family: FontAwesome;
}
.social .linkedin a {
	content: "\f0e1";
	font-family: FontAwesome;
	background: #027ba5;
	font-size: 18px;
	border-radius: 3px;
	line-height: 35px;
	display: inline-block;
	width: 35px;
	height: 35px;
	color: #fff;
	text-align: center;
	padding: 0;
}
.social .linkedin a:hover {
	background: #027ba5;
}
.social .youtube a:before {
	content: "\f167";
	font-family: FontAwesome;
}
.social .youtube a {
	background: #F03434;
	font-size: 18px;
	border-radius: 3px;
	line-height: 35px;
	display: inline-block;
	width: 35px;
	height: 35px;
	color: #fff;
	text-align: center;
	padding: 0;
}
.social .youtube a:hover {
	background: #F03434;
}
.social {
	float: left;
	margin-top: 4px;
}
.social ul {
	float: left;
	margin: 0px;
	list-style: none;
	padding: 0px;
}
.social ul li {
	margin: 0px 5px 0px 0px;
	display: inline-block;
}
.social a {
	transition: background 400ms ease-in-out;
}
.payment-accept {
	color: #333333;
	font-size: 14px;
	margin: auto;
	overflow: hidden;
	float: right
}
.payment-accept img {
	margin-right: 10px;

}
.payment-accept img:hover {
	opacity: 1
}
.glyphicon-remove1:before {
	content: "\e014"
}
.glyphicon-remove1 {
	right: 8px;
}
button.button {
	display: inline-block;
	background-color: #efecec;
	font-size: 13px;
	text-align: center;
	white-space: nowrap;
	font-weight: 500;
	border:none;
	border-radius: 999px;
	letter-spacing: 0.5px;
	padding: 12px 20px 10px 20px;
	font-family: 'Rubik', sans-serif;

}
button.button:hover {
    background-color: #97bc77;
	color: #fff;
}
button.button span span {
	border: 0;
	padding: 0;
	background: none;
}
.button, .btn, .button-inverse, .button-vote, .sidebar .btn, .dropdown .dropdown-menu .button, .buttons-set .back-link a, .scrolltop, .button-tabs li a {
	text-transform: uppercase;
}
.button:hover, .button:focus, .btn:hover, .btn:focus, .button-inverse, .button-vote:hover, .sidebar .button:hover, .sidebar .button:focus, .sidebar .btn:hover, .sidebar .btn:focus, .form-list .bootstrap-select.btn-group .btn:hover .caret, .form-list .bootstrap-select.btn-group .btn:focus .caret, .buttons-set .back-link a:hover, .buttons-set .back-link a:focus, .scrolltop, .button-tabs li a:hover, .button-tabs li a:focus {
	color: #FFF;
}
.products-grid-home .link-wishlist:before {
	content: "\f004";
}
.products-grid-home .link-compare:before {
	content: "\f012";
}
.products-grid-home .link-wishlist {
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 14px;
	font-weight: normal;
	margin: 0;
	padding: 8px 0px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #fff solid;
	width: 40px;
	margin-right: 7px
}
.products-grid-home .box-inner .actions .link-wishlist span, .products-grid-home .box-inner .actions .add-to-links a.link-compare span {
	display: none;
}
.products-grid-home .link-compare {
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 14px;
	font-weight: normal;
	margin: 0;
	padding: 8px 0px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #fff solid;
	width: 40px
}
.mm-toggle-wrap {
	display: none;
}
.product-shop .social {
	margin-top: 20px;
}
.product-shop .social a {
	border: 1px #ddd solid;
	color: #888;
	width: 40px;
	height: 40px;
	line-height: 38px;
	background:#fff
}
.product-shop .social a:hover {
	color: #fff;
}
.product-shop .social ul {
	padding: 0px;
	margin: 0px
}
.product-shop .social ul li {
	display: inline-block;
	margin: 0 7px 0 0;
}
.product-shop .social ul li:last-child {
	border: none;
}
.sendfriend-product-send .login_page h2 {
	font-size: 14px;
}
.sendfriend-product-send .login_page .form-list .field {
	float: left;
	width: 47%;
	margin-right: 10px;
}
.sendfriend-product-send .login_page textarea {
	width: 95%;
}
.product-options-bottom .sharing-links {
	display: none;
}
.add-to-box .add-to-cart, .product-options-bottom .add-to-cart {
	margin: 0;
}
.product-view .product-shop .grouped-items-table .price {
	font-size: 18px;
}
#product-options-wrapper .options-list .price {
	font-size: 12px;
}
.radio, .checkbox {
	display: inline-block;
}
.rhs4-banner-block {

	display: none;
}
.rhs4-block {
	display: none;
}
.noitem {
	padding: 10px;
	text-align: center;
	font-size: 14px;
}
.noitem:before {
	content: '\f07a';
	font-family: FontAwesome;
	font-size: 40px;
	color: #ccc;
	width: 100%;
	display: block;
}
.validation-advice {
	color: #FF0000;
}
.form-list .validation-advice {
	color: #FF0000;
	position: relative;
}
.mgkosc-login-input-box .validation-advice {
	color: #FF0000;
	position: relative;
}
.footer-top .newsletter .validation-advice {
	left: 106px;
}
/*============ Global Messages  ============*/
.success {
	color: #3d6611;
	font-weight: bold;
}
.error {
	color: #df280a;
	font-weight: bold;
}
.notice {
	color: #000000;
}
.messages, .messages ul {
	list-style: none !important;
	/*margin: 0 !important;*/
	padding: 0 !important;
}
.messages {
	width: 100%;
	overflow: hidden;
	text-align: left;
}
.messages li {
	margin: 5px 0 10px !important;
}
.messages li li {
	margin: 0 0 3px !important;
}
.error-msg, .success-msg, .note-msg, .notice-msg {
	border-style: solid !important;
	border-width: 0px !important;
	border-radius: 0px;
	background-position: 10px 9px !important;
	background-repeat: no-repeat !important; /*min-height:20px !important;*/
	padding: 8px 8px 8px 8px !important;
	font-size: 11px !important;
	font-weight: 900 !important;
	text-align: center;
}
.error-msg {
	border-color: #f16048;
	background-color: #faebe7;
	color: #df280a;
}
.success-msg {
	border-color: #446423;
	background-color: #eff5ea;
	color: #3d6611;
}
.note-msg, .notice-msg {
	border-color: #fcd344;
	background-color: #fafaec;
	color: #3d6611;
}
/**Global Messages**/
.review-product-list .email-addto-box {
	float: left;
}
.checkout-cart-index .shipping .sp-methods {
	margin-left: -12px;
	margin-top: 10px;
}
.checkout-cart-index .shipping .sp-methods dd ul li {
	margin-bottom: 10px;
}
#my-reviews-table h2 {
	font-size: 16px;
	margin: 0;
}
.review-customer-view .product-img-box {
	float: left;
	margin-right: 10px;
}
.review-customer-view .product-details {
	float: left;
}
.review-customer-view .buttons-set {
	clear: both;
}
.review-customer-view .label {
	display: block;
}
.review-customer-view .product-details h3 {
	font-size: 14px;
	margin: 0;
}
.simple .footer-bottom {
	border: none;
}
.dashboard h2 {
	font-size: 16px;
}
.dashboard h4 {
	font-size: 14px;
}
.my-account .group-select h2 {
	font-size: 16px;
}
.login_page .pager .limiter {
	float: right;
	margin-left: 20px;
}
.login_page .pager .limiter label {
	float: none;
}
.login_page .pager .pages {
	float: left;
	margin-left: 20px;
	margin-top: 8px;
}
.login_page .pager .amount {
	margin-top: 12px;
}
.pagination li.current span {
	background-color: #F23534;
	border: medium none;
	color: #FFFFFF;
	cursor: default;
	z-index: 2;
	padding: 5px 10px;
	border-radius: 0;
	box-shadow: none;
}
.login_page .pager {
	background: none repeat scroll 0 0 #F5F5F5;
	clear: both;
	color: #666666;
	display: inline-block;
	margin-top: 0;
	padding: 15px;
	width: 100%;
}
#my-orders-table {
	margin-bottom: 5px;
}
.order-info {
	border: 1px solid #DDDDDD;
	margin: 5px 0 8px;
	padding: 4px 8px;
}
.order-info dt, .order-info dd, .order-info ul, .order-info li {
	display: inline;
	margin: 0;
	padding: 0;
}
.order-info .current {
	font-weight: bold;
}
.order-info li {
	margin: 0 3px;
}
#my-orders-table h3 {
	font-size: 16px;
	margin-top: 0px;
}
#multiship-addresses-table h3 {
	font-size: 12px;
	line-height: 18px;
	margin: 0;
}
.group-select h2 {
	font-size: 16px;
}
.multiple-checkout h3 {
	font-size: 16px;
}
.checkout-agreements {
	border: 1px solid #EAEAEA;
	margin-top: 20px;
	padding: 10px;
}
/*.header-container .logout {
	float: right;
	margin-top: 20px;
	font-family: DIN, Helvetica, Arial, sans-serif
}
.header-container .logout a {
	color: #333;
	background: #fff;
	border: 1px #ddd solid;
	padding: 0px 8px;
	display: block;
	border-radius: 0px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 32px;
}*/
.toolbar .pager .pages {
	float: left;
}
.one-page-checkout h3 {
	font-size: 16px;
	margin: 0px;
}
#review-buttons-container .btn-checkout {
	float: right;
}
#checkout-review-submit .checkout-agreements {
	margin-bottom: 10px !important;
}
.block-layered-nav .btn-remove1 {
	display: none;
}
.button-set .bnt-comment {
	margin-bottom: 10px !important;
}
.commentlist li .comment-wrapper {
	padding: 0 20px 17px 0px !important;
}
.tabcontent {
	margin-top: 15px;
}
.label {
	color: #333;
	display: inline-block;
	text-align: left;
	font-size: 12px;
	white-space: normal;
}
.sale-offer-box {
	display: none;
}
.offer-section {
	overflow: hidden;
	width: 100%;
	margin-bottom: 25px;
}
.offer-section .container {
	width: 100%;
}
.offer-section .col-lg-4, .offer-section .col-sm-4, .offer-section .col-xs-12 {
	padding: 0px
}
.offer-section .col {
	float: none;
	padding: 0px 0px;
}
.offer-section .container img {
	width: 100%;
}
.offer-section > div a {
	display: block;
	overflow: hidden;
}
.offer-section > div a img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
}
.offer-section > div a:hover img {
	-webkit-transform: scale(1.11);
	transform: scale(1.11);
	-moz-transform: scale(1.11);
}
.top-banner-section {
	overflow: hidden;
	width: 100%;
	margin-bottom: 25px;
}
.top-banner-section {
	overflow: hidden;
	width: 100%;
	margin-bottom: 45px
}
.top-banner-section .container img {
	width: 100%;
}
.top-banner-section > div a {
	display: block;
	overflow: hidden;
}
.top-banner-section > div a img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
.top-banner-section > div a:hover img {
	-webkit-transform: scale(1.11);
	transform: scale(1.11);
	-moz-transform: scale(1.11);
}
.top-banner-section .col {
	float: none;
	padding: 0px 0px;
}
.add-banner1 {
	padding: 20% 0px!important;
	color: #fff;
	text-align: center;
	font-size: 18px;
}
.add-banner1 strong {
	font-size: 30px;
	display: block;
	margin-bottom: 5px;
}

.offer {
	color: #fff;
	text-align: center;
	padding: 20% 0px!important;
	font-size: 18px;
}
.offer strong {
	font-size: 30px;
	display: block;
	margin-bottom: 5px;
}
.header-banner {
	overflow: hidden;
	font-family: 'Rubik', sans-serif;
	background:url(../static/img/h48.jpg) no-repeat top left;
}
.header-banner .cross-img {
	position: absolute;
	z-index: 10;
	right: 15px;
	top: 7px
}

#slideshow {height: 20px; overflow: hidden;}

.header-banner .assetBlock {
	text-align: center;
	margin: auto;
	padding: 14px 0px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	color: #222;
	letter-spacing:1px

}
.header-banner p {
	margin: 0px
}
.header-banner .assetBlock a {
	color: #f65f73
}
.header-banner .assetBlock span {
	color: #f65f73
}
.cms-index-index .sale-offer-box {
	display: block;
}
.sale-offer-box {
	margin: auto;
	display: none;
	position: relative;
	width: 1200px;
}
.sale-offer-left {
	left: -120px;
	position: absolute;
	margin-top: 30%;
}
.sale-offer-right {
	position: absolute;
	margin-top: 30%;
	right: -120px;
}
/*  offer-slider   **/

.parallax {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.parallax-2 {
	padding-top: 25px;
	padding-bottom: 0px;

}

/*  offer-slider   **/

/*  custom-slider  */
.custom-slider {
	margin-top:30px

}

.custom-slider h4 {
	text-shadow: none;
	color: #fff;
	font-size: 26px;
	font-family:'Oswald', sans-serif;
	font-weight: 400;
	font-style:normal;
	text-transform:uppercase
}
.custom-slider h3 { margin-top:0px}
.custom-slider h3 a {
	text-shadow: none;
	color: #fff;
	font-size: 40px;
	font-family:'Oswald', sans-serif;
	font-weight: 500;
	font-style:normal;
	text-transform:uppercase;

}
.custom-slider .carousel-caption {
	text-shadow: none;
	padding: 0px 15px!important;
    font-size: 16px;
	font-weight:600
}
#carousel-example-generic {
	background: #fff!important;
}
#carousel-example-generic .carousel-control.left {
	background: none
}
#carousel-example-generic .carousel-control.right {
	background: none
}
#carousel-example-generic .carousel-inner {
	margin: 0px
}
#carousel-example-generic .carousel-caption {
	width: 100%;
	left: 0;
	right: 0;
	padding: 0;
	top: 20%
}
.custom-slider .carousel-indicators {
	width: auto;
	margin-left: -20px
}
.custom-slider .carousel-control {
	display: none
}
.custom-slider img {
	border-radius: 0px
}
.custom-slider .link {
    padding: 14px 35px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;
    margin-top: 20px;
    background: #f65f73;
    letter-spacing: 1px;
    font-family: 'Oswald', sans-serif;
    border-radius: 999px;
	display:inline-block
}

/*  category-slider  */

/*  custom-slider  */
.category-description {
	overflow: hidden;
	margin:0px;

}
.category-description .container {
	width: 100%;
	padding: 0px
}
.category-description a {
	display: block
}
.category-image {
	display: block;
	margin: 30px auto 25px;
	position: relative;
}
.category-products {
	padding-bottom: 5px;
	overflow: hidden;
	padding: 0;
	clear: both;
}
.category-description a.prev, .category-description a.next {
	margin: 125px 6px 0px 5px;
}
.cat-img-title.cat-box {
    margin: 0;
    text-align: left;
    left: 50px;
    top: 5px;
    margin: 11% 0px;
    position: absolute;
    width: 48.5%;
    border-left: 5px #97bc77 solid;
    padding-left: 30px;
    padding-bottom: 30px;
	padding-top:20px
}
#category-desc-slider {
	margin: 0px 0px 0px;
}
.small-tag {
	color:#97bc77;
	display: inline-block;
	padding: 8px 0px;
	text-transform: uppercase;
	font-size: 28px;
	letter-spacing: 1px;
	font-weight: normal;
	font-family:'Oswald', sans-serif
}
.cat-img-title .cat-heading {
	font-size: 46px;
	text-transform: uppercase;
	color: #333e48;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom: 2px;
	font-family:'Oswald', sans-serif
}

.cat-img-title .cat-heading span {font-weight:normal}

.cat-img-title p {
	clear: left;
	font-size: 22px;
	line-height: 1.6em;
	margin-bottom: 1px;
	padding: 0px;
	font-weight: normal;
	color: #333e48;
	margin-top: 6px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}
.cat-img-title.cat-bg p {
	color: #333e48;

}
.category-description .slider-items-products .owl-buttons .owl-prev {
    left: 20px;
    position: absolute;
    top: 49%;
    background: none;
    margin: 0;
}
.category-description .slider-items-products .owl-buttons .owl-next {
	right: 11px;
	position: absolute;
	top: 49%;
	background: none;
	padding: 0px;
	 margin: 0;
}
.category-description .slider-items-products .owl-buttons a {
	z-index: 1;
	position: absolute;
	left: 10px;
	top: 0px;
	padding: 5px 3px 10px 0px;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	font-size: 0px;
	height: 30px;
	width: 30px;
	text-align: center;
	border-radius: 0px;
	background: #333e48;
	border:none;
	margin-top: -15px;
	
}
.category-description .slider-items-products .owl-buttons a:hover {
	background: #97bc77;
	opacity: 1;
	border:none;
	color: #fff;
}
.category-description .slider-items-products .owl-buttons a {opacity:0}
.category-description .slider-items-products:hover .owl-buttons a {opacity:1}

.category-description .slider-items-products .owl-buttons .owl-prev a:before {
    font-size: 24px;
    padding: 0px 8px;
    line-height: 19px;
    font-weight: 400;
}
.category-description .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 24px;
	padding: 0px 12px;
	line-height: 19px;
	 font-weight: 400;
	 color:#fff
}
.category-description .owl-item .item {
	margin: auto;
	background: #fff;

}
.category-description .owl-item .item img {
	max-width: 100%;
}
.header-banner.mobile-show .our-features-box {
	display: none;
}
#nav-home p {
	display: none;
}
#mgkquickview {
	display: none;
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 1200px;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12px;
}
#mgkquickview1 {
	display: none;
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 1200px;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12px;
}
#mgkquickview2 {
	display: none;
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 1200px;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12px;
}
#mgkquickview3 {
	display: none;
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 1200px;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 12px;
}
.product-shop .social {
	float: left;
	margin-bottom: 10px;
}
.product-shop .social ul li {
	display: inline-block;
	margin: 0 7px 0 0;
}
.sendfriend-product-send .login_page h2 {
	font-size: 14px;
}
.sendfriend-product-send .login_page .form-list .field {
	float: left;
	width: 47%;
	margin-right: 10px;
}
.sendfriend-product-send .login_page textarea {
	width: 95%;
}
.product-options-bottom .sharing-links {
	display: none;
}
.product-view .product-shop .grouped-items-table .price {
	font-size: 18px;
}
#product-options-wrapper .options-list .price {
	font-size: 12px;
}
.radio, .checkbox {
	display: inline-block;
}
.noitem {
	padding: 10px;
	text-align: center;
	font-size: 11px;
	line-height: 19px;
	letter-spacing: 1px;
}
.noitem:before {
	content: '\f07a';
	font-family: FontAwesome;
	font-size: 40px;
	color: #ccc;
	width: 100%;
	display: block;
	margin-bottom: 30px;
	display: block!important
}
.validation-advice {
	color: #FF0000;
	margin: 3px 0px;
}
.newsletter-wrap .validation-advice {
	position: absolute;
}
.form-list .validation-advice {
	color: #FF0000;
	position: relative;
}
.mgkosc-login-input-box .validation-advice {
	color: #FF0000;
	position: relative;
}
.footer-top .newsletter .validation-advice {
	left: 106px;
}
/*============ Global Messages  ============*/
.success {
	color: #3d6611;
	font-weight: bold;
}
.error {
	color: #df280a;
	font-weight: bold;
}
.notice {
	color: #000000;
}
.messages, .messages ul {
	list-style: none !important;
	/*margin: 0 !important;*/
	padding: 0 !important;
}
.messages {
	width: 100%;
	overflow: hidden;
	text-align: left;
}
.messages li {
	margin: 5px 0 10px !important;
}
.messages li li {
	margin: 0 0 3px !important;
}
.error-msg, .success-msg, .note-msg, .notice-msg {
	border-style: solid !important;
	border-width: 1px !important;
	background-position: 10px 9px !important;
	background-repeat: no-repeat !important; /*min-height:20px !important;*/
	padding: 8px 8px 8px 8px !important;
	font-size: 11px !important;
	font-weight: 900 !important;
}
.error-msg {
	border-color: #f16048;
	background-color: #faebe7;
	color: #df280a;
}
.success-msg {
	border-color: #446423;
	background-color: #eff5ea;
	color: #3d6611;
}
.note-msg, .notice-msg {
	border-color: #fcd344;
	background-color: #fafaec;
	color: #3d6611;
}
/**Global Messages**/
.review-product-list .email-addto-box {
	float: left;
}
.checkout-cart-index .shipping .sp-methods {
	margin-left: 0px;
	margin-top: 10px;
}
.checkout-cart-index .shipping .sp-methods ul {
	margin: 0px;
	padding: 0px;
}
.checkout-cart-index .shipping .sp-methods dd ul li {
	margin-bottom: 10px;
	display: block;
}
#my-reviews-table h2 {
	font-size: 16px;
	margin: 0;
}
.review-customer-view .product-img-box {
	float: left;
	margin-right: 10px;
}
.review-customer-view .product-details {
	float: left;
}
.review-customer-view .buttons-set {
	clear: both;
}
.review-customer-view .label {
	display: block;
}
.review-customer-view .product-details h3 {
	font-size: 14px;
	margin: 0;
}
.dashboard h2 {
	font-size: 16px;
}
.dashboard h4 {
	font-size: 14px;
	font-weight: 500;
	font-family:'Rubik', sans-serif
}

.title-buttons {padding:0px 0px}

.title-buttons strong {
	font-size: 14px;
	font-weight: 500;
	font-family:'Rubik', sans-serif;
	text-transform:uppercase;
	    color: #97bc77;
}


.my-account .group-select h2 {
	font-size: 16px;
}
.login_page .pager .limiter {
	float: right;
	margin-left: 20px;
}
.login_page .pager .limiter label {
	float: none;
}
.login_page .pager .pages {
	float: left;
	margin-left: 20px;
	margin-top: 8px;
}
.login_page .pager .amount {
	margin-top: 12px;
}
#my-orders-table {
	margin-bottom: 5px;
}
.order-info {
	border: 1px solid #DDDDDD;
	margin: 5px 0 8px;
	padding: 4px 8px;
}
.order-info dt, .order-info dd, .order-info ul, .order-info li {
	display: inline;
	margin: 0;
	padding: 0;
}
.order-info .current {
	font-weight: bold;
}
.order-info li {
	margin: 0 3px;
}
#my-orders-table h3 {
	font-size: 16px;
	margin-top: 0px;
}
#multiship-addresses-table h3 {
	font-size: 13px;
	line-height: 18px;
	margin: 0;
}
.group-select h2 {
	font-size: 16px;
}
.multiple-checkout h3 {
	font-size: 16px;
}
.checkout-agreements {
	border: 1px solid #EAEAEA;
	margin-top: 20px;
	padding: 10px;
}
.one-page-checkout h3 {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 8px;
}
#review-buttons-container .btn-checkout {
	float: right;
}
#checkout-review-submit .checkout-agreements {
	margin-bottom: 10px !important;
}
.block-layered-nav .btn-remove1 {
	display: none;
}
.button-set .bnt-comment {
	margin-bottom: 10px !important;
}
.commentlist li .comment-wrapper {
	padding: 0 20px 17px 0px !important;
}
.label {
	color: #333;
	display: inline-block;
	text-align: left;
	font-size: 12px;
	white-space: normal;
}
/*************Newsletter POPUP Start***************/
/* Newsletter POPUP Start */

#fade {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	filter: alpha(opacity=70);
	-moz-opacity: 0.8;
	-khtml-opacity: 0.8;
	opacity: 0.8;
	z-index: 1000;
	display: none;
}
.popup1 {
	width: 100%;
	margin: 0 auto;
	display: none;
	z-index: 101;
}
.popup1 .newsletter {
	width: auto;
	background: #fff;
	padding: 40px;
	border-radius: 20px;
	overflow:hidden
}

.quick-view-box{
	border-radius: 10px;
	left: 50%;
	margin: auto;
	min-height: 150px;
	position: fixed;
	top: 20%;
	width: 720px;
	z-index: 10000;
	margin-left:-360px;

}
.quick-view-box .custom button.items-count {padding: 13px 12px 9px 16px;
    height: 42px;
    width: 42px;}
	
.quick-view-box .product-view .product-shop .add-to-box .btn-cart {padding: 12px 20px; font-size: 16px;}	
.quick-view-box .product-view .product-img-box .product-image .owl-prev {top:5%}
.quick-view-box .product-view .product-img-box .product-image .owl-next {top:5%}
.quick-view-box .add-to-cart .qty {
    height: 42px;
    text-align: center;
    width: 30px;
    vertical-align: top;
    color: #333e48;
}	
	
.quick-view-box .product-essential {border:none; box-shadow:none; border-radius:10px; padding: 25px;}
.quick-view-box .short-description {display:none}
.quick-view-box .shipping-pro {display:none}
.quick-view-box .container{width:715px; margin-bottom:0px}
.quick-view-box  .product-view .product-name h1 {font-size:26px;}
.quick-view-box  .product-view .product-shop .price  {font-size:24px}
.quick-view-box .product-view .short-description h2 {font-size:12px; margin-bottom:5px}
.quick-view-box .product-view .email-addto-box {display:none}
.quick-view-box .product-view .product-shop .add-to-box button.button.btn-cart span {
    background: none;
    padding: 0px 10px;
    font-size: 12px;
}
.quick-view-box .product-view .previews-list li {margin-left:3px; margin-right:3px}
.newsletter-sign-box {
	border-radius: 20px;
	left: 50%;
	margin: auto;
	min-height: 150px;
	position: fixed;
	top: 20%;
	width: 650px;
	z-index: 10000;
	margin-left:-325px;

}
.popup1 .x {
	position: absolute;
	right: -15px;
	top: -15px;
	z-index: 10000;
}
.popup1 .x:hover {
	cursor: pointer;
}
.newsletter-sign-box h3 {
	color: #000000;
	font-family: 'Oswald', sans-serif;
	font-size:30px;
	font-weight: 400;
	margin-bottom: 10px;
	text-align: center;
	text-transform: uppercase;
	margin-top:0px;
	padding-bottom:0px;
	letter-spacing:1px
}
.newsletter-sign-box h4 {
	font-size: 13px;
	font-weight: normal;
	margin-bottom: 25px;
	text-align: center;
	color: #777;
	text-transform: none;
	line-height:1.5em;
	margin-top:0px
}

.newsletter_img {width:40%; float:left}
.newsletter_img img {max-width:100%}
.email-form {width:55%; float:right}

.newsletter-sign-box .input-box {
	margin-bottom: 15px;
}
.newsletter-sign-box .input-box input {
	display:block;
	margin-right: 0px;
	padding:10px 20px;
	width:100%;
	border: 1px solid #ddd;
	text-align:center;
	font-size:14px;
	margin-bottom:10px
}
.newsletter-sign-box .subscribe {
    background: #97bc77;
    color: #fff;
    font-size: 14px;
    text-shadow: none;
    padding: 12px 0px;
    margin-top: 0px;
    font-weight: 500;
    border-radius: 999px;
    display: block;
    width: 100%;
    font-family: 'Rubik', sans-serif;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.5px;
}
.newsletter-sign-box .subscribe:hover {
	background:#131e2c;

}
.newsletter-sign-box button.button span {
	color: #FFFFFF;
	font-size: 14px;
	font-weight: 500;
}
.newsletter-sign-box .subscribe-bottom {
    color: #666;
    font-size: 11px;
    line-height: 3px !important;
    margin-top: 20px;
    display: block;
    overflow: hidden;
    text-align: center;
    margin-bottom: 0;
}
.newsletter-sign-box .subscribe-bottom input {
	margin: 0px 8px 0 0;
	vertical-align: middle;
}

.popup1 .line {
	background: #ddd;
	height: 5px;
	width: 100px;
	margin: auto;
	margin-bottom: 20px
}
/* Newsletter POPUP END***************/


.cms-index-index .messages {
	width: 1170px;
	margin: auto;
}
.one-page-checkout ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
select#shipping-address-select {
	width: 570px;
}
#checkout-review-submit ol.checkout-agreements {

	list-style: none;
}
.sp-methods ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.sendfriend-product-send .input-box textarea {
	width: 100%;
}
.button.coupon, .button.get-quote, .button.btn-continue, .button.btn-update, .button.continue, .button.login, button.btn-share, .button.create-account {
}
.button.button-clear {
	background-color: #fff;
	color: #333 !important;
}
.button.button-clear:hover {
	background-color: #f65f73;
	color: #fff !important;
}
.multiple_addresses .button.btn-update {
	color: #333;
}
.multiple_addresses .button.btn-update:hover {
	background-color: #f65f73;
	color: #fff;
}
.data-table thead th {
	background-color: #97bc77;
	color: #fff;


}
.table-striped>tbody>tr:nth-child(odd) {
	background-color: #fff;
}
.data-table thead th:first-child {
	background-color: #97bc77;
	color: #fff;
}
.data-table thead th:last-child {
	background-color: #97bc77;
	color: #fff;
}
.login_page {
	margin-top: 20px;
}

.products-list .product-shop .out-of-stock {
	background: none repeat scroll 0 0 #fff;
	border: medium none;
	color: #fff;
	padding: 6px 12px;
	line-height: normal;
	margin-right: 8px;
	border-radius: 0px;
	display: inline-block;
	border: 2px solid #ddd;
}
.products-list .product-shop .out-of-stock span {
	font-family: 'Roboto', sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 0.5px;
	padding: 0 0 0 10px;
	text-transform: uppercase;
	color: #ff4c4c;
}
.product-shop .out-of-stock:before {
	content: "\f07a";
	font-family: 'FontAwesome';
	color: #ff4c4c;
}
.products-grid .actions .out-of-stock {
	background: none repeat scroll 0 0 #fff;
	border: 2px solid #eee;
	color: #333;
	line-height: normal;
	margin: 0;
	padding: 6px 10px;
}
.products-grid .actions .out-of-stock span {
	font-size: 11px;
	font-weight: bold;
	text-transform: uppercase;
}
.product-view .availability.out-of-stock {
	padding: 3px 10px;
	position: absolute;
	right: 0;
	top: 18px;
}
.fl-links {
	float: right;
	background: none;
	/* border-left:1px #e5e5e5 solid; */
}
.fl-links .clicker {
	padding: 0px 0px 0px 25px;
	line-height: 100px;
	height: 90px;
	display: block;
}
.fl-links > .no-js:hover > .clicker {
	padding: 0px 0px 0px 25px;
	line-height: 120px;
	height: 120px;
	color: #131e2c;
}
.fl-links .clicker:before {
	content: '\f0c9';
	font-family: FontAwesome;
	font-size: 20px;
	padding-left: 4px;
	padding-top: 2px;
	margin-right: 6px;
	margin-top: -2px;
	height: 40px;
	cursor: pointer;
	color:#fff;
	
	
}
.fl-links .links {
	overflow: hidden;
	padding: 0px;
	margin: 0px;
	width: 100%;
	font-family:'Rubik', sans-serif
}
.fl-links .links li {
	font-size: 12px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-bottom: 1px solid #eaeaea;
	letter-spacing: 0.5px;
	padding-left: 0px
}
.fl-links .links li:last-child {
	border: none
}

.fl-links .no-js .fl-nav-links {
	background: #202020;
	background-repeat: repeat;
	background-position: 0 0;
	padding: 15px;
	margin: 0;
	overflow: hidden;
	font-size: 12px;
	line-height: 25px;
	text-transform: uppercase;
	z-index: 2000;
	border-top: 0;
	width: 250px;
	position: absolute;
	opacity: 0;
	right: 0px;
	margin-top: -2px;
	transform: scale(0) rotate(0deg);
	transition: all 0.3s ease-in-out 0s;
	visibility: hidden;
}
.fl-links .no-js:hover .fl-nav-links {
	background: #fff;
	background-repeat: repeat;
	background-position: 0 0;
	padding: 15px 20px;
	margin: 0;
	width: 270px;
	overflow: hidden;
	font-size: 12px;
	line-height: 25px;
	text-transform: uppercase;
	z-index: 2000;
	border-top: 0;
	display: block;
	position: absolute;
	right: 0px;
	opacity: 1;
	visibility: visible;
	transition: all 0.3s ease-in-out 0s;
	-webkit-transform: scale(1.0) rotate(0deg);
	transform: scale(1.0) rotate(0deg);
	box-shadow: 0 10px 20px -5px;
	border-radius:0px 0px 10px 10px
}
.fl-links .no-js:hover .fl-nav-links .links a {
	color: #383838;
	letter-spacing: 0.5px
}
.fl-links .no-js:hover .fl-nav-links .links a:hover {
	color: #383838;
	letter-spacing: 0.5px
}
.top-offer-banner {
	margin: 65px 0px;
	font-family: 'Montserrat', sans-serif;
	margin-top:0px;
	padding-top:25px
}

.offer-inner {padding-left:5px; padding-right:5px}

.offer-inner .left {
	width: 58.5%;
	display: inline-block;
}
.offer-inner .right {
	width: 40%;
	float: right;
	position: relative;
	overflow: hidden;
}
.offer-inner .left .col-1 {
	width: 100%;
	float: left;
	margin-bottom: 18px;
	overflow: hidden;
}
.offer-inner .left .col-1 .inner-text {
	right: auto;
	left: 50px
}
.offer-inner .left .col-1 .inner-text h3 {
	font-size: 18px;
}
.top-offer-banner .col img {
	width: 100%
}
.offer-inner .left .col.mid {
	width: 55.5%;
	float: left;
	margin-right: 0px;
	position: relative;
	overflow: hidden;
}
.offer-inner .left .col.last {
	width: 42%;
	float: right;
	margin-right: 0px;
	position: relative;
	overflow: hidden;
}
.offer-inner .inner-text {
	top: 15px;
	position: absolute;
	right: 7%;
	white-space: normal;
	z-index: 3;
	text-align: left;
	color: #fff;
	cursor: pointer;
}
.offer-inner .inner-text h4 {
	font-size: 30px;
	line-height: 100%;
	margin: 7px 0;
	font-weight: 300;
	text-transform: uppercase;
}
.offer-inner .inner-text h3 {
	font-size: 16px;
	line-height: 100%;
	margin: 10px 0;
	font-weight: 700;
	text-transform: uppercase;
	color: #222;
}
.offer-inner .right .inner-text {
	top: 4%;
	text-align: left;
	right: auto;
	margin: auto;
	width: 100%;
	padding-left:20px
}
.offer-inner .right .inner-text h4 {
	font-size: 18px;
	font-weight: bold;
	color: #333
}
.offer-inner .right .inner-text h3 {
	font-size: 48px;
	font-weight: bold;
	color: #fff;
}
.offer-inner .right .inner-text .line-b {
	height: 1px;
	background-color: #fff;
	margin: 25px auto;
	width: 15%;
}
.offer-inner .inner-text a.shop-now {
	text-transform: uppercase;
	color: #fff;
}
.offer-inner .inner-text a.shop-now1 {
	text-transform: uppercase;
	color: #fff;
	border-radius: 0px;
	padding: 6px 10px;
	display: inline-block;
	background-color: #2ecc71;
}
.offer-inner .inner-text a.shop-now1:hover {
	text-transform: uppercase;
	color: #333;
	background-color: #fff;
}
.offer-inner img {
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
	width: 100%
}
.offer-inner img:hover {
	-webkit-transform: scale(1.25);
	-moz-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}
.offer-inner .left .col.mid:hover img {
	-webkit-transform: scale(1.25);
	-moz-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}
.offer-inner .left .col.last:hover img {
	-webkit-transform: scale(1.25);
	-moz-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}
.offer-inner .right:hover img {
	-webkit-transform: scale(1.25);
	-moz-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}
/*#background {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 50%;
  height: 100%;
  background-color: #e0e0e0;
}*/



.page-heading {
    padding: 60px 0 60px 0;
     border-bottom: none;
    text-align: center;
    background-repeat: no-repeat;
    background-image: url(../static/img/ban_2.jpg);

}

.page-heading .page-title h2 {
	color: #ffb637;
}

.cms-page-view p {
	font-size: 13px;
	line-height: 1.6em;
}
.form_background {
	    padding:0px;
    position: relative;
    margin-top: 0px;
    padding-bottom: 45px;
    background-color: #fff;
    margin-top: 30px;
}





.cms-engaging-home-page .home_content:after {
  background: url("../static/img/bg_shadow_about.png") no-repeat scroll center bottom transparent;
  content: "";
  height: 93px;
  position: absolute;
  width: 100%;
  z-index: 9;
}


/*	Testimonials */

.testimonials-section {padding:25px 25% 0px 25%; text-align:center; margin-bottom:0px;   text-align:center}
.avatar  {margin-bottom:10px; text-align:center; overflow:hidden; position: relative;  width:182px; display:inline-block}
.avatar:before {
    background: url(../static/img/testimonial-icon.png) center center no-repeat;
    bottom: 14px;
    content: '';
    display: block;
    height: 37px;
    left: 30px;
    position: absolute;
    width: 37px;
    z-index: 9;
}
.avatar img  {border-radius: 65px; box-shadow: 0px 10px 30px 0px rgba(188,188,188,0.7); margin-bottom:25px; width:auto; display:inline-block; width:152px }
.testimonials {color:#292929; font-family:'Merriweather', serif; text-align: center; font-style: italic; letter-spacing:0.5px; font-size:22px; margin-bottom:15px; font-weight:300; line-height:1.6em}
.clients_author {font-size:14px; font-family:'Rubik', sans-serif; font-weight:500; color:#97bc77}
.clients_author span{font-size:14px;   font-family: 'Rubik', sans-serif; font-weight:normal; color:#777; display:inline-block; margin-left:7px}
.testimonials-section.slider-items-products .owl-buttons { display:none}
.testimonials-section.slider-items-products:hover .owl-buttons { display:block}
.testimonials-section.slider-items-products .owl-buttons .owl-prev { position: absolute; left: -100px; top: 0%; margin-top:50px;}
.testimonials-section.slider-items-products .owl-buttons .owl-prev a {opacity: 1; height: 60px; line-height: 50px; width: 60px; background:none; border-right: 1px double #999;}
.testimonials-section.slider-items-products .owl-buttons .owl-prev a:before {font-size:40px; font-weight:100; line-height:60px}
.testimonials-section.slider-items-products .owl-buttons .owl-next { position: absolute; right: -100px; top: 0%; margin-top:50px;}
.testimonials-section.slider-items-products .owl-buttons .owl-next a {opacity: 1; height: 60px; line-height: 50px; width: 60px; background:none; border-left: 1px double #999;}
.testimonials-section.slider-items-products .owl-buttons .owl-next a:before {font-size:40px; font-weight:100; line-height:60px}


#preloader { position: fixed; left: 0; top: 0; z-index: 9999; width: 100%; height: 100%; overflow: visible; background: #fff url('') no-repeat center center; }

