.highlighted{
  background: #84c22533;
  border: none;
}

.imageClicked{
  width: 64px;
  height: 64px;
  border: 1px solid #ffb637;
  cursor: pointer;
}

.imageSize{
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.imageNotClicked{
  width: 64px;
  height: 64px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
}
.forHover{
  cursor: pointer;
  margin-bottom: 5px;
  transition: box-shadow 1s;
}
.forHover:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}


.hovering{
  transition: transform 1s, filter .5s ease-out; 
  transform: scale(1);

}

.hovering:hover{
  transform: scale(2.0);
}

.hideThis{
  visibility: hidden;
}

@media (max-width: 576px){
  .hideThis{
    display: none;
  }
  .imageSize{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
} 