.container1{
  padding-left: 15px;
  padding-right:15px;
  margin-right: auto;
  margin-left: auto;
}
.container3{
  padding:0px;
  margin-right: auto;
  margin-left: auto;
}
.carousel-inner{
  margin: 0px !important;
}
@media (min-width:768px) {
  .container1 {
    width: 750px
  }
  .container2{
    width: 750px
  }
  .container3{
    width: 750px
  }
}
@media (min-width:992px) {
  .container1 {
    width: 970px
  }
  .container2 {
    width: 970px
  }
  .container3 {
    width: 970px
  }
}
@media (min-width:1250px) {
  .container1 {
    width: 100%
  }
  .container2 {
    width: 1240px;
  }
  .container3{
    width: 100%;
  }
}

.basket-web{
  display: block;
  cursor: pointer;
}
.basket-mobile{
  display: none;
}

.container2{
  padding-left: 15px;
  padding-right:15px;
  margin-right: auto;
  margin-left: auto;
}
.header-landing-home-title{
  letter-spacing:-2px;
  z-index: 2;
  white-space:nowrap;
  position:absolute;
  line-height:70px;
  font-size:50px;
  font-weight:400;
  left:0px;
  top:200px;
  opacity:1;
  color:#fff;
  background-color:#0000;
  width:100%;
  text-align:center
}
.header-landing-home-subtitle{
  letter-spacing:-3px;
  z-index: 3;
  white-space:nowrap;
  position:absolute;
  line-height:20px;
  font-size:80px;
  font-weight:600;
  left:0px;
  top:300px;
  opacity:1;
  color:#fff;
  background-color:#0000;
  width:100%;
  text-align:center;
  text-transform:"uppercase"
}
.header-landing-home-subtitle2{
  letter-spacing:1px;
  z-index: 3;
  white-space:nowrap;
  position:absolute;
  line-height:20px;
  font-size:30px;
  font-weight:400;
  left:0px;
  top:380px;
  opacity:1;
  color:#fff;
  background-color:#0000;
  width:100%;
  text-align:center;
  text-transform:"uppercase"
}
.header-landing-home-caption{
  letter-spacing:-3px;
  z-index: 3;
  white-space:nowrap;
  position:absolute;
  line-height:20px;
  font-size:24px;
  font-weight:600;
  left:0px;
  top:400px;
  opacity:1;
  color:#fff;
  background-color:#0000;
  width:100%;
  text-align:center; 
  margin-top: 30px;
}
.header-landing-home-caption-button{
  letter-spacing:-3px;
  z-index: 3;
  white-space:nowrap;
  position:absolute;
  line-height:20px;
  font-size:24px;
  font-weight:600;
  left:0px;
  top:400px;
  opacity:1;
  color:#fff;
  background-color:#0000;
  width:100%;
  text-align:center; 
  background-color: white;
  border: 1px solid black;
}

.shopnow-button{
  border:1px solid white;
  background-color: white;
  font-family: Oswald, "sans serif";
  padding:12px;
  border-radius: 20px;
  letter-spacing: 1px;
  font-size: 20px;
  color:green;
  margin-top: 40px;
}

.carousel-control-next, .carousel-control-prev{
  position: absolute;
    top: -32%;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease;
}

.formBasicCheckbox{
width: 100%;
}
.form-check-label{
  margin-left: 20px;
  width: 100%;
}
.dialogloginbutton{
  display: inline-block;
  padding: 6px 15px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  font-family: Oswald, "sans serif";
  border-radius: 4px;
  background-color: #5B9D4F;
  color: #fff;
  font-weight: 800;
  letter-spacing: 2px;
  width: 100%;
}
.dialogforgetpassword{
  display: inline-block;
  padding: 6px 15px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: end ;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  font-family: Oswald, "sans serif";
  letter-spacing: 1.5px;
  border-radius: 4px;
  color: black;
  font-weight: 800;
  letter-spacing: 0.3px;
  width: 100%; 
}
.dialogresetpassword{
  color: blue;
}
.top-heading-icons{
  padding:0px 0px 0px 25px;
  line-height:95px;
  height:90px;
  display:flexl
}
.arrow-web-icon{
  display: none;
}
.arrow-mobile-icon{
  display: block;
}
.items-select-dropdown{
  width:100%;
  background-color: blanchedalmond;
  color: wheat;
}
.kgquantuty{
  border: 1px solid black;
  padding:8px;
  border-radius: 8px;
}
.quantity{
  border: 1px solid #efefef;
  border-radius: 5px;
  margin:10px;
  cursor: pointer;
}
.quantity:hover{
  /* border: 1px solid #cdcdcd; */
  border-radius: 5px;
  margin:10px;
  cursor: pointer;
  box-shadow: 0.5px 2px #cdcdcd;
}
.quantityicon{
  padding: 10px;
  border-radius: 5px;
  color:grey;
}
.selectedquantityicon{
  background:#9cc63d;
  padding: 10px;
  border-radius: 5px;
  color:white;
}
.quantity-title-block{
  padding: 5px;
}
.quantity-title{
  font-size: 14px;
  font-weight: 600; 
}
.quantity-subtitle{
  font-size: 10px;
  font-weight: 400; 
}
.quantity-price{
  padding: 10px;

}
.quantity-textfeils{
  width: 40px;
  height: 40px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #cdcdcd;
  text-align: center;
}
.quantity-input-block{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}
.quantity-textfeils-name{
  margin-left: 10px;
  border: 1px solid #cdcdcd;
  border-right: 0px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  padding: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #efefef;
}
.quantitycart{
  margin-right: 10px;
  padding:10px;
  border: 1px solid #ffc107;
  height: 40px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #ffc107;
  color: white;
  cursor: pointer;
}
.quantitycart:hover{
  background-color: #ffd970;
  border: 1px solid #ffd970;
}
.carosuel-div-two{
  display: flex;
  flex-direction: row;
  height: 200px;
  background-color: #e83e8c;
}
.pro-img-first{
  height: 100px;
  margin-top: 30px;
  border-radius: 100px;
}
.mobile-carousel{
  display: none;
}
.shopnow-button-homepage{
  text-align: center;
  color:'green';
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  z-index: 100;
}
.mycart{
  margin-top: 20px;
}

@media (min-width: 300px) and (max-width: 600px) {
  .header-landing-home-title{
    letter-spacing:-1px;
    z-index: 2;
    white-space:nowrap;
    position:absolute;
    line-height:70px;
    font-size:18px;
    font-weight:400;
    left:0px;
    top:10px;
    opacity:1;
    color:#fff;
    background-color:#0000;
    width:100%;
    text-align:center;
  }
  .header-landing-home-subtitle{
    letter-spacing:-3px;
    z-index: 3;
    white-space:nowrap;
    position:absolute;
    line-height:23px;
    font-size:24px;
    font-weight:600;
    left:0px;
    top:60px;
    opacity:1;
    color:#fff;
    background-color:#0000;
    width:100%;
    text-align:center;
    text-transform:"uppercase";
    letter-spacing: 0.3px;
  }
  .header-landing-home-subtitle2{
    letter-spacing:2px;
    z-index: 3;
    white-space:nowrap;
    position:absolute;
    line-height:20px;
    font-size:10px;
    font-weight:400;
    left:0px;
    top:90px;
    opacity:1;
    color:#fff;
    background-color:#0000;
    width:100%;
    text-align:center;
    text-transform:"uppercase"
  }
  .header-landing-home-caption{
    letter-spacing:-3px;
    z-index: 3;
    white-space:nowrap;
    position:absolute;
    line-height:20px;
    font-size:15px;
    font-weight:600;
    left:0px;
    top:100px;
    opacity:1;
    color:#fff;
    background-color:#0000;
    width:100%;
    text-align:center;
    letter-spacing: 0.3px;
    
  }
  .top-heading-icons{
    padding:0px 10px 0px 25px;
    line-height:135px;
    height:90px;
    display:flex;
    margin-right: 10px;

  }
  .drawermainlisttextactive{
    display: block;
    overflow: hidden;
    white-space: normal;
    color: #f65f73;
    text-decoration: none;
    padding-left: 15px;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
  }
  .drawermainlisttext{
    display: block;
    overflow: hidden;
    white-space: normal;
    color: #80b435;
    text-decoration: none;
    padding-left: 15px;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
  }
  .drawersecondlisttext{
    display: block;
    overflow: hidden;
    white-space: normal;
    color: black;
    text-decoration: none;
    padding-left: 15px;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
  }
  .quantity-title-block{
    padding: 5px;
  }
  .quantity-title{
    font-size: 12px;
    font-weight: 600; 
  }
  .quantity-subtitle{
    font-size: 8px;
    font-weight: 400; 
  }
  .quantity-price{
    padding-top: 12px;
    font-size: 10px;
  }
  .quantityicon{
    padding: 8px;
    border-radius: 5px;
    color:grey;
  }
  .selectedquantityicon{
    background:#9cc63d;
    padding: 8px;
    border-radius: 5px;
    color:white;
  }  
  .quantity-input-block{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
  }
  .quantity-textfeils{
    width: 100%;
    height: 35px;
    margin-right: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
 
  }
  .quantity-textfeils-name{
    margin-left: 10px;
    border-right: 0px;
    height: 35px;
    text-align: center;
    padding-top: 10px;
    padding: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #efefef;
    width: 50%;
    font-size: 10px;
  }
  .quantitycart{
    margin-right: 10px;
    padding:10px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 10px;
  }
  .quantitycart:hover{
    background-color: #ffd970;
    border: 1px solid #ffd970;
  }
  .text-success{
    font-size: 30px;
  }
  .knowabout{
    font-size: 22px;
  }
  .chariot-image-homepage{
    display: none;
  }
  .mobile-carousel{
    display: block;
  }
  .web-carousel{
    display: none;
  }
  .shopnow-button{
    border:1px solid white;
    background-color: white;
    font-family: Oswald, "sans serif";
    padding:8px;
    border-radius: 20px;
    letter-spacing: 1px;
    font-size: 12px;
    color:green;
  }
  .arrow-web-icon{
    display: block;
  }
  .arrow-mobile-icon{
    display: none;
  }
  .basket-web{
    display: none;
  }
  .basket-mobile{
    display: block;
  }
}
