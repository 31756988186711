.orderDetails{
  text-align: end;
  margin-right: 20px;
  color:#0066c0;
  cursor: pointer;
}


.orderDetails:hover{
  color:#ff7936;
  text-decoration: underline;
}