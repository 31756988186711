.homeContainer{
     width: 100%;
    margin:auto;
    padding: 0 2.5% 0 2.5%;
}

.cart_container{
    margin-top: 5%;
    overflow: scroll;
}

.order_row{
    cursor: pointer;
}

.cart__image{
    height: 134px;
    width: 200px;
    padding: 10px;
}

.cart__text{
    font-weight: 300;
    font-size: 18px;
}

th,tr {
    border-bottom: 1px solid #efefef;
}

.order_row:hover{
    background-color: rgba(226, 213, 213, 0.918);
}

@media only screen and (max-width: 768px) {
    .sidebar {
        border:1;
    }
  }



.carousel_suggestions{
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 2%;
}



table {
    border-collapse: collapse;
    border-radius: 5px;
    border-style: hidden;
     box-shadow: 0 0 0 1px #cdcdcd; 
     margin-bottom: 20px;

}
.name{
    width:2%;
}
.address{
    width: 10%;
}
.phone{
    width: 3%;
}
.online{
    width: 1%;
}
.total{
    width: 1%;
}
th {
   border-right:1px solid #cdcdcd;
   text-align: center;
   padding: 10px;
   background-color: #97bc77;
   color: #fff;
}
td {
    border: 1px solid #cdcdcd;
    padding-left:20px;
    padding: 5px;
}
.search-table-outter { 

 }

@media only screen and (max-width: 600px) {
    .search-table-outter { overflow-x: scroll; }
  }

