/************************
*************************
    Mobile Menu v1.0
    (c) 2015 ThemesMart
 ************************
************************/
html {
	overflow-x: hidden;
}
#page {
	position: relative;
	left: 0;
	
}

.icon-align-justify:before {
    content: '\f0c9';
    font-family: FontAwesome;
    font-size: 24px;
    padding-left: 4px;
    padding-top: 2px;
    margin-right: 6px;
    height: 40px;
    cursor: pointer;
    color: #80b435;
	font-style: normal;
}

.mmPushBody {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}
#mobile-menu {
	position: absolute;
	top: 0;
	background: #fff;
	z-index: 99999;
	-webkit-box-shadow: 0 1px 8px #555555;
	-moz-box-shadow: 0 1px 8px #555555;
	-o-box-shadow: 0 1px 8px #555555;
	box-shadow: 0 1px 8px #555555;
	display: none;
	font-family:'Montserrat', sans-serif;
	font-size: 13px;
	
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	-o-box-sizing: content-box;
	box-sizing: content-box;
	line-height: 1.42857;
	text-transform: uppercase;
}
.mobile-menu {
	list-style-type: none;
	margin: 0;
	width: 100%;
	float: left;
	margin-left: 0;
	padding-left: 0;
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.mobile-menu ul {
	margin-left: 0;
	padding-left: 0;
}
.mobile-menu ul .expand {
	/*background: #eeeeee;*/
}
.mobile-menu .mm-search {
	background-color: #fff;
	color: #333;
	padding: 10px 10px 10px 15px;
	box-sizing: border-box;
	border-bottom:1px #e5e5e5 solid;
	margin-bottom:5px
}
.mobile-menu .input-group {
	position: relative;
	display: table;
	border-collapse: separate;
	background:#fff;

}
.mobile-menu .input-group .form-control:last-child {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}
.mobile-menu .input-group .form-control {
	display: table-cell;
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	margin-bottom: 0px;
	height: 30px;
	box-shadow:none;
	font-family: 'Open Sans', sans-serif;
}
.mobile-menu .input-group-btn {
	position: relative;
	font-size: 0;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
	display: table-cell;
}
.mobile-menu .input-group-btn:first-child > .btn {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}
.mobile-menu .input-group-btn > .btn {
	position: relative;

}
.mobile-menu .btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	background-image: none;
	white-space: nowrap;
	font-size: 15px;
	line-height: 1.42857;
	border-radius: 0px;
}
.mobile-menu .form-control {
	height: 18px;
	padding: 0px 0;
	font-size: 15px;
	color: #555555;
	background-color: #FFF;
	border-radius: 0px;
	border: 0;
}
.mobile-menu .btn-default {
	height: 30px;
	border: 0;
	padding: 4px 10px;
	background-color: white;
}
.mobile-menu .btn-default:hover {
	color: #333;
}
.mm-search #search {
	width: 230px;
}
.icon-search:before {
  content: "\f002";
  font-size: 14px;
}

.mobile-menu .btn-default:focus {
	background-color: white;
}
.mobile-menu .btn-default:active {
	background-color: white;
}
.mobile-menu .home {
	color: white;
	overflow: hidden;
}
.mobile-menu .home i {
	padding-right: 5px;
}
.mobile-menu .home a.active {
	color: #fff !important;
}
.mobile-menu .expand {
	right: 10px;
	position: absolute;
	padding: 0 10px;
	color: #383838;
	margin-left: 50px;
}
.mobile-menu .icon-plus:before {
  font-size: 12px;
   content: "\f067";
   font-family:FontAwesome
}

.mobile-menu .icon-minus:before {
    content: "\f068";
	font-family:FontAwesome;
	 font-size: 12px;
}

.mobile-menu .expand:hover {
	cursor: pointer;
}
.mobile-menu li {
	display: block;
	width: 100%;
	float: left;
	margin-left: 0;
	padding-left: 0;
	position: relative;
}
.mobile-menu li .home a {
	color: #f65f73;

}
.mobile-menu li a {
  display: block;
  overflow: hidden;
  white-space: normal;
  color: #80b435;
  text-decoration: none;
  padding: 10px;
  padding-left: 15px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: 'Rubik', sans-serif;
  font-weight:500
}
.mobile-menu li a.active {
	color: #333;
}
.mobile-menu li li {

}
.mobile-menu li li a {
	font-family:'Open Sans', sans-serif;
	font-weight:normal;
	font-size:13px;
	text-transform:none;
	padding: 4px 10px;
	padding-left: 25px;
	color: #777;
}

.mobile-menu li li li a {
	padding-left: 35px;
}


#mobile-menu .top-links {
    display:block;
    width: 100%;
	font-family: 'Rubik', sans-serif;
	border-top:1px #eaeaea solid;
	overflow: hidden;
	padding-top:8px
}

#mobile-menu .top-links ul.links {
    padding: 0;
}

#mobile-menu .top-links .links li {
    /* border-bottom: 1px solid #444; */
    list-style: outside none none;
    padding: 5px 15px;
    font-size: 13px;
}

#mobile-menu .top-links ul.links a {
    color: #333;
    text-transform: none;

}

.mobile-menu .social a {
	float: left;
	padding: 0;
	margin: 10px 0 10px 15px;
}
.mobile-menu .social i {
	font-size: 22px;
	color: #333;
}
.mobile-menu input.simple:focus {
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	transition: none;
}
.mobile-menu [class^="icon-"]:before {
	margin-right: 0;
	margin-left: 0;
}
.mobile-menu [class*=" icon-"]:before {
	margin-right: 0;
	margin-left: 0;
}
.mm-toggle-wrap {
/*	color: white;
	background: #333;*/
	line-height: 1;

}
.mm-toggle {
	cursor: pointer;
	font-size: 20px;
	display: none;
	padding: 8px 10px;
	width: auto;
	float: left;
/*	background: #0088cc;*/
	height: 40px;
	vertical-align:middle!important
}

.mm-toggle .icon-align-justify:before {vertical-align:middle!important; color:#fff}

 @media (max-width: 767px) {
.mm-toggle {
	display: block; 
}
.mm-toggle h2 {
font-size:14px;
font-weight:900;
}
.mm-toggle .mm-label {
margin-left: 6px;
  font-size: 18px;
  font-weight: 600;
}
}
 @media (max-width: 767px) {
 .mm-toggle .mm-label {
margin-left: 6px;
  font-size: 14px;
  font-weight: 700;
  font-family:'Montserrat', sans-serif;
  text-transform:uppercase;
  display:none
}
}

