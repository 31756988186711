#mySidenav a {
    position: fixed;
    left:-16px;
	margin-top:320px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
	z-index:130;
}

#mySidenav a:hover {
    left: 0;
}
#about {
    top: 0px;
    
}
@media screen and (max-width: 400px) {
#mySidenav a {
    position: fixed;
    left: -20px;
	margin-top:350px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
}
}