
.returncart{
  color:#f65f73;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
}
.returnToCartMobile{
  color:#f65f73;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
  display: none;
}

@media(max-width:425px) {
  .returncart{
    color:#f65f73;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 10px;
    display: none;
  }
  .returnToCartMobile{
    color:#f65f73;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 10px;
    display: block;
  }  
}
