.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Additional-links-footer{
  background-color: #050b30;
}

.heading{
  color : whitesmoke;
  margin-top: 5%;
}

.Additional-links-footer li{
  background-color: #050b30;
  color: whitesmoke;
  margin-top: 5%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.Additional-links-footer a{
  background-color: #050b30;
  color: lightslategray;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.dryFruitsBackground{

  background-image: url('./static/img/DryFruitsBackground2.jpg');
}

.darkTheme{
  background-color: #050b30;
}

.greyTheme{
  background-color: #3f4249;
}

.font-btn{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: medium;
}

.font-nav{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: larger;
}
.locateMeButton{

  background-color:silver;
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border: none;
  color: #fff;
  background-color: #fc8019;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.12);
  right: 0;
  height: 50px;
  line-height: 50px;
  width: 140px;
  padding: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pac-icon{
  display : none;
}

.pac-item{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.pac-item:hover{
  background-color: rgb(158, 155, 155);
}

.pac-item-query{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.modal-input {
  background-color: transparent;
  line-height: normal;
  border: none;
  outline: none;
  height: 70px;
  width: 100%;
  font-size: 17px;
  margin: 0;
  padding: 0 20px;
  padding-top: 22px;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

a:hover{
  text-decoration: none;
}
