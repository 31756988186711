.imageSize{
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}


@media (max-width: 576px){
  .imageSize{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
} 